import { ThemeZindexInterface } from './theme-interface';

export const BaSeZindexs: ThemeZindexInterface = {
  default: 0,
  units: 1,
  tens: 10,
  hundreds: 100,
  thousands: 1000,
  max: 9999,
};
