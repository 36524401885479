import * as React from 'react';
import { BaSeTheme } from '../theme';

export interface BreakpointContextInterface {
  onMobileSmall?: boolean;
  onMobile?: boolean;
  onMobileLarge?: boolean;
  onTablet?: boolean;
  onDesktop?: boolean;
  onDesktopLarge?: boolean;
  isInPortraitMode?: boolean;
  isInLandscapeMode?: boolean;
  inMobileSmall?: boolean;
  inMobile?: boolean;
  inMobileLarge?: boolean;
  inTablet?: boolean;
  inDesktop?: boolean;
  inDesktopLarge?: boolean;
}

const queryMatchDefault: BreakpointContextInterface = {
  onMobileSmall: false,
  onMobile: false,
  onMobileLarge: false,
  onTablet: false,
  onDesktop: false,
  onDesktopLarge: false,
  isInPortraitMode: false,
  isInLandscapeMode: false,
  inMobileSmall: false,
  inMobile: false,
  inMobileLarge: false,
  inTablet: false,
  inDesktop: false,
  inDesktopLarge: false,
};

export const BaSeBreakpointContext = React.createContext(queryMatchDefault);

export const BaSeBreakpointProvider: React.FC = ({ children }) => {

  const [queryMatch, setQueryMatch] = React.useState<BreakpointContextInterface>({});

  React.useEffect(() => {
    const queries = {
      onMobileSmall: BaSeTheme.breakpoints.mobileSmall.mediaQuery,
      onMobile: BaSeTheme.breakpoints.mobile.mediaQuery,
      onMobileLarge: BaSeTheme.breakpoints.mobileLarge.mediaQuery,
      onTablet: BaSeTheme.breakpoints.tablet.mediaQuery,
      onDesktop: BaSeTheme.breakpoints.desktop.mediaQuery,
      onDesktopLarge: BaSeTheme.breakpoints.desktopLarge.mediaQuery,
      isInPortraitMode: '(orientation: portrait)',
      isInLandscapeMode: '(orientation: landscape)',
    };
    const mediaQueryLists = {};
    const keys = Object.keys(queries);

    const recursiveQueries = {
      inMobileSmall: BaSeTheme.breakpoints.mobileSmall.mediaQuery,
      inMobile: BaSeTheme.breakpoints.mobile.mediaQuery,
      inMobileLarge: BaSeTheme.breakpoints.mobileLarge.mediaQuery,
      inTablet: BaSeTheme.breakpoints.tablet.mediaQuery,
      inDesktop: BaSeTheme.breakpoints.desktop.mediaQuery,
      inDesktopLarge: BaSeTheme.breakpoints.desktopLarge.mediaQuery,
    };
    const recursiveKeys = Object.keys(recursiveQueries);

    let isAttached = false;

    const handleRecursiveQueries = (options: any) => {
      let index = 0;
      let checked = true;
      const queryReturn = {};
      const updatedMatches = recursiveKeys.reduce((acc, media, matchIndex) => {
        acc[matchIndex] = media;
        return acc;
      }, {});
      // tslint:disable-next-line: forin
      for (const query in options) {
        queryReturn[updatedMatches[index++]] = !!checked;
        if (options[query]) {
          checked = false;
        }
      }
      return { ...options, ...queryReturn };
    };

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc, media) => {
        acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches);
        return acc;
      }, {});
      setQueryMatch(handleRecursiveQueries(updatedMatches));
    };

    if (window && window.matchMedia) {
      const matches = {};
      keys.forEach(media => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media] = window.matchMedia(queries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });
      setQueryMatch(handleRecursiveQueries(matches));
      isAttached = true;
      keys.forEach(media => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media].addListener(handleQueryListener);
        }
      });
    }

    return () => {
      if (isAttached) {
        keys.forEach(media => {
          if (typeof queries[media] === 'string') {
            mediaQueryLists[media].removeListener(handleQueryListener);
          }
        });
      }
    };
  }, []);

  return (
    <BaSeBreakpointContext.Provider value={queryMatch}>
      {children}
    </BaSeBreakpointContext.Provider>
  );
};

export const BaSeBreakpointConsumer = BaSeBreakpointContext.Consumer;
