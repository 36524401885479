import * as React from 'react';
import { BaSeShapeButton } from '../button/shape-button/shape-button';
import { isNumeric } from '../../utils/string-utils';
import {
  getButtonType,
  StyledLabel,
  StyledCounterInput,
  StyledApoio,
  CounterContainer,
  CounterInnerContainer,
} from './counter-styled';
import { mapCounterSize } from './map-counter-style';
import { getNextHash, idGenerator } from '../../utils/id-generator';

const idSequence = idGenerator();

interface BaSeCounterProps {
  value: number;
  size: 'small' | 'medium' | 'big';
  label: string;
  complement: string;
  type: 'primary' | 'secondary';
  minValue: number;
  maxValue: number;
  isDisabled: boolean;
  hasError: boolean;
  width: number;
  onChange: any;
  onInvalidNumber: any;
}

export const BaSeCounter: React.FC<BaSeCounterProps> = ({
  value = 0,
  size = 'medium',
  label = 'Label',
  complement = '',
  type = 'primary',
  minValue = 0,
  maxValue = 5,
  isDisabled = false,
  hasError = false,
  width = 0,
  onChange = () => {},
  onInvalidNumber = () => {},
}) => {
  const id = getNextHash(idSequence);
  const onChangeInput = (newValue: any) => {
    const cleanedValue = newValue.target.value;
    if (isNumeric(cleanedValue)) {
      const castedValue = Number(cleanedValue);
      castedValue <= maxValue && castedValue >= minValue
        ? onChange(castedValue)
        : onInvalidNumber(castedValue);
    }
  };

  const increaseValue = () => onChange(Number(value) + 1);
  const decreaseValue = () => onChange(Number(value) - 1);
  const isLeftButtonDisabled = Number(value) === Number(minValue) || isDisabled;
  const isRightButtonDisabled =
    Number(value) === Number(maxValue) || isDisabled;
  const height = mapCounterSize[size]?.padding ?? mapCounterSize.medium.padding;
  const leftButtonType = getButtonType(type, isLeftButtonDisabled, hasError);
  const rightButtonType = getButtonType(type, isRightButtonDisabled, hasError);

  return (
    <CounterContainer>
      <StyledLabel
        fontSize={size === 'small' ? '13px' : '16px'}
        lineHeight={size === 'small' ? '16px' : '23px'}
        htmlFor={id}
      >
        {label}
      </StyledLabel>
      <CounterInnerContainer>
        <BaSeShapeButton
          shape="square"
          nameIcon="minus"
          sideButton="left"
          isDisabled={!hasError && isLeftButtonDisabled}
          type={leftButtonType.type}
          color={leftButtonType.color}
          onClick={() => !isLeftButtonDisabled && decreaseValue()}
          size={size}
          buttonType="button"
        />
        <StyledCounterInput
          id={id}
          value={value}
          disabled={isDisabled}
          hasError={hasError}
          width={width}
          height={height}
          onChange={onChangeInput}
        />
        <BaSeShapeButton
          shape="square"
          nameIcon="plus"
          sideButton="right"
          isDisabled={!hasError && isRightButtonDisabled}
          type={rightButtonType.type}
          color={rightButtonType.color}
          onClick={() => !isRightButtonDisabled && increaseValue()}
          size={size}
          buttonType="button"
        />
      </CounterInnerContainer>
      <StyledApoio>{complement}</StyledApoio>
    </CounterContainer>
  );
};
