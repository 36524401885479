import * as React from 'react';
import { BaSeTooltip } from '../../alerts/tooltip/tooltip';
import { BaSePopover } from '../../alerts/popover/popover';
import { AlertDirection, TooltipColor } from '../../alerts/alerts-props';
import { Coordinates } from './popup-button-map';
import { useOutsideEvent } from '../../../hooks/outside-event';
import {
  HelperButtonContainer,
  HelperButtonIconContainer,
} from './popup-button-styled';
import { BaSeShapeButton } from '../../button/shape-button/shape-button';

export interface HelperIconProps {
  name: string;
  color?: string;
  size?: number | string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface HelperButtonInterface {
  direction?: AlertDirection;
  type?: 'popover' | 'tooltip';
  tooltipColor?: TooltipColor;
  alertSize?: number;
  message?: string;
  title?: string;
  description?: string;
  iconName?: string;
  iconColor?: string;
  iconSize?: number;
  iconProps?: HelperIconProps;
}

const defaultIconName = 'question-circle';
const defaultIconSize = 13;
const defaultIconColor = 'default';

export const BaSePopupButton: React.FC<HelperButtonInterface> = ({
  direction = 'top',
  type = 'tooltip',
  tooltipColor = 'filled',
  alertSize = type === 'tooltip' ? 106 : 180,
  message = '',
  title = '',
  iconProps = {
    name: defaultIconName,
    color: defaultIconColor,
    size: defaultIconSize,
    onClick: () => {},
  },
}) => {
  const [coordinates, setCoordinates] = React.useState(
    Coordinates(alertSize, null, iconProps?.size ?? defaultIconSize)[direction],
  );

  const handleRef = (e: any) => {
    setCoordinates(
      Coordinates(
        alertSize,
        e?.offsetHeight,
        iconProps?.size ?? defaultIconSize,
      )[direction],
    );
  };

  const [open, setOpen] = React.useState(false);
  const helperButtonRef = React.useRef(null);
  useOutsideEvent(helperButtonRef, (e) => setOpen(e));

  return (
    <HelperButtonContainer ref={helperButtonRef}>
      <HelperButtonIconContainer
        size={iconProps.size ?? defaultIconSize}
        onClick={() => setOpen((prev) => !prev)}
      >
        <BaSeShapeButton
          buttonType="button"
          type="tertiary"
          size="medium"
          nameIcon={iconProps.name ?? defaultIconName}
          color={iconProps.color ?? defaultIconColor}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            iconProps?.onClick?.(e)
          }
        />
      </HelperButtonIconContainer>
      {type === 'tooltip' ? (
        <BaSeTooltip
          width={alertSize}
          open={open}
          direction={direction}
          message={message}
          color={tooltipColor}
          coordinates={...coordinates}
          refOutput={handleRef}
        />
      ) : (
        <BaSePopover
          width={alertSize}
          open={open}
          direction={direction}
          message={message}
          coordinates={...coordinates}
          refOutput={handleRef}
          title={title}
          handleClose={() => setOpen(false)}
        />
      )}
    </HelperButtonContainer>
  );
};
