import * as React from 'react';
import { BaSeInput } from '../../input/text/input';
import { BaSeTheme } from '../../../theme';
import { BaSeList, ListType } from '../list-icon/list-icon';
import {
  ContainerInputList,
  WrapperInputList,
} from './input-list-styled';

interface InputListType {
  width?: string | number;
  sizeInput?: 'medium' | 'small' | 'big';
  nameButton: string;
  valueButton: string;
  value: string;
  label?: string;
  subLabel?: string;
  buttonAction: (value: string) => void;
  isDisabled?: boolean;
  placeholder?: string;
  typeButton: 'base-icon' | 'base-button' | 'base-shape-button';
  listItems: ListType;
}

export const BaSeInputList: React.FC<InputListType> = ({
  width = '100%',
  listItems = {
    valuesList: [],
    direction: 'column',
    listActions: [{
        size: 'medium',
        sizeIcon: 'medium',
        ActionisDisabled: false,
        nameIcon: 'trash-alt',
        color: BaSeTheme.colors.institucionais.azulSebrae36,
        listAction: () => { },
    }],
  },
  isDisabled = false,
  nameButton = 'plus',
  valueButton = 'Adicionar',
  sizeInput = 'medium',
  typeButton = 'base-button',
  placeholder = '',
  value = '',
  label = '',
  subLabel = '',
  buttonAction = () => { },
}) => {
  const [newValue, setNewValue] = React.useState(value as string);
  const inputAction = () => {
    buttonAction(newValue);
    setNewValue('');
  };
  return (
    <ContainerInputList width={width}>
      <WrapperInputList>
        <BaSeInput
          value={newValue}
          onChange={(changeValue) => setNewValue(changeValue)}
          onFocus={() => { }}
          onBlur={() => { }}
          label={label}
          size={sizeInput}
          subLabel={subLabel}
          typeInput="text"
          inputMode="text"
          complement=""
          width="100%"
          showHelpButton={false}
          isDisabled={isDisabled}
          isReadOnly={false}
          placeholder={placeholder}
          iconButton={{
            typeButton,
            name: nameButton,
            action: inputAction,
            type: 'primary',
            value: valueButton,
            color: BaSeTheme.colors.institucionais.azulSebrae36,
          }}
        />
      </WrapperInputList>
      <BaSeList
        {...listItems}
      />
    </ContainerInputList>
  );
};
