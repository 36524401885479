import styled from 'styled-components';
import { BaSeTheme } from '../../theme';

interface LinkStyledProps {
  textColor: string;
}

export const LinkStyled = styled.a<LinkStyledProps>`
  font-family: ${BaSeTheme.typography.fontFamily};
  font-size: ${BaSeTheme.typography.fontSizeBase};
  background: transparent;
  border: 1px solid transparent;
  text-decoration: none;
  font-weight: normal;
  line-height: 1.2em;
  padding: 0;
  color: ${props => props.textColor};

  &:hover,
  &:focus {
    cursor: pointer;
    outline: none;
    text-decoration: underline;
  }
  &:active {
    border-color: ${props => props.textColor};
    text-decoration: none;
  }
`;
