import styled from 'styled-components';

interface ShapeStyleProps {
  disabled: boolean;
  type: string;
  border: string;
  borderRadius: string;
  backgroundColor: string;
  backgroundOnHover: string;
  boxShadowOnFocus: string;
  padding: 'none' | number;
  sideButton?: 'left' | 'right' | 'none';
}

export const ShapeStyle = styled.button<ShapeStyleProps>`
  border-radius: ${(props) =>
    props.sideButton === 'none'
      ? props.borderRadius
      : props.sideButton === 'left'
      ? '4px 0px 0px 4px'
      : '0px 4px 4px 0px'};
  display: flex;
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => props.border};
  align-items: center;
  justify-content: center;
  padding: ${(props) => props.padding}px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.backgroundOnHover};
  }

  &:focus {
    border-radius: ${(props) =>
      props.sideButton === 'none'
        ? props.borderRadius
        : props.sideButton === 'left'
        ? '4px 0px 0px 4px'
        : '0px 4px 4px 0px'};
    background-color: ${(props) => props.backgroundOnHover};
    box-shadow: ${(props) => props.boxShadowOnFocus};
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
