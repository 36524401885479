import * as React from 'react';
import { BaSeTheme } from '../../theme';
import { BaSeShapeButton } from '../button/shape-button/shape-button';

interface AvatarOrIconAndNameAndEmailProps {
  avatar: string;
  icon: string;
  name: string;
  email?: string;
  onClose?: () => void;
}

export const AvatarOrIconAndNameAndEmail: React.FC<AvatarOrIconAndNameAndEmailProps> = React.memo(
  (props: AvatarOrIconAndNameAndEmailProps) => {
    const [hasAvatar, setHasAvatar] = React.useState(true);
    return (
      <>
        {hasAvatar ? (
          <img
            loading="lazy"
            className="BaSe--account-profile-avatar"
            src={props.avatar}
            alt="Imagem da pessoa autenticada no AMEI"
            onError={() => setHasAvatar(false)}
          />
        ) : (
          <span
            className="BaSe--account-profile-name-abbr-icon"
            aria-hidden="true"
          >
            {props.icon}
          </span>
        )}
        {props.email ? (
          <span className="BaSe--account-profile-name-with-close">
            <span className="BaSe--account-profile-name">
              <span>{props.name}</span>
              <div className="BaSe--account-profile-close-button">
                <BaSeShapeButton
                  buttonType="button"
                  nameIcon="close"
                  shape="circle"
                  size="small"
                  sizeIcon="small"
                  type="tertiary"
                  color={BaSeTheme.colors.institucionais.cinzaSebrae75}
                  onClick={() => props?.onClose?.()}
                />
              </div>
            </span>
            <br />
            <small className="BaSe--account-profile-email">{props.email}</small>
          </span>
        ) : (
          <span>
            <span className="BaSe--account-profile-name">{props.name}</span>
          </span>
        )}
      </>
    );
  },
);
