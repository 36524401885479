export const MappedInputMasks = {
  cnpj: '99.999.999/9999-99',
  cpf: '999.999.999-99',
  cep: '99.999-999',
  phone: '9999-99999',
  phoneDDD: '(99) 9999-99999',
  oldPhone: '9999-9999',
  oldPhoneDDD: '(99) 9999-9999',
  date: '99/99/9999',
};

export const currencyMask = (curr: string | undefined) => {
  let val = curr;
  if (val) {
    let minus = '';
    if (val[0] === '-') {
      minus = val[0];
    }
    val = val.replace(/\D/g, '');
    if (!val) {
      return '';
    }
    if (val.length < 5) {
      try {
        val = parseFloat(parseInt(val, 10) / 100 + '').toFixed(2) + '';
        const [integer, float] = val.split('.');
        const newInteger = integer.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
        val = newInteger + ',' + float;
      } catch {
        val = '';
      }
    } else {
      const splitter = val.length - 2;
      let integer = val.slice(0, splitter);
      const float = val.slice(splitter, splitter + 2);
      integer = integer.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
      val = integer + ',' + float;
    }
    return minus ? minus + val : val;
  } else {
    return '';
  }
};
