import styled from 'styled-components';

export type PositionType =
  | 'center'
  | 'top-right'
  | 'right'
  | 'bottom-right'
  | 'bottom'
  | 'bottom-left'
  | 'left'
  | 'top-left'
  | 'top';

const handlePosition = (position: PositionType): string => {
  if (position === 'center') {
    return 'place-items: center';
  } else {
    const css = {
      'align-self': 'center;',
      'justify-self': 'center;',
    };
    if (position.includes('top')) {
      css['align-self'] = 'flex-start;';
    }
    if (position.includes('bottom')) {
      css['align-self'] = 'flex-end;';
    }
    if (position.includes('left')) {
      css['justify-self'] = 'flex-start;';
    }
    if (position.includes('right')) {
      css['justify-self'] = 'flex-end;';
    }
    return `align-self: ${css['align-self']} ;justify-self: ${css['justify-self']}`;
  }
};

export const CenterStyled = styled.div`
  display: grid;
  place-items: center;
`;

export const PositionAlignedStyled = styled.div<{ position: PositionType }>`
  display: grid;
  ${props => handlePosition(props.position)}
`;
