import * as React from 'react';
import { BaSeTheme } from '../../theme';
import { BaSeShapeButton } from '../button/shape-button/shape-button';
import { BaSeIcon } from '../image/icon';
import { BaSeSmall1 } from '../typography/small/small1';
import { BaSeSmall2 } from '../typography/small/small2';
import { TagContainerStyled, TagStyledProps } from './tag-styled';

export interface BaSeTagProps extends TagStyledProps {
  label: string;
  iconColor?: string;
  labelColor?: string;
}

export const BaSeTag: React.FC<
  Omit<BaSeTagProps, 'bigSize' | 'mediumSize'>
> = ({
  icon,
  label,
  size = 'medium',

  iconBackgroundColor = BaSeTheme.colors.defaultColors.transparent,
  labelBackgroundColor = BaSeTheme.colors.defaultColors.transparent,

  iconColor = BaSeTheme.colors.institucionais.azulSebrae,
  labelColor = BaSeTheme.colors.institucionais.azulSebrae,
  borderColor = BaSeTheme.colors.defaultColors.transparent,

  onClick,
  onClose,
}) => {
  const containerProps: React.HTMLAttributes<HTMLSpanElement> &
    TagStyledProps = {
    bigSize: 32,
    mediumSize: 24,
    smallSize: 16,
    className: 'BaSe--tag',
    borderColor,
    iconBackgroundColor,
    labelBackgroundColor,
    icon,
    size,
    onClose,
  };

  if (onClick) {
    containerProps.role = 'button';
    containerProps.tabIndex = 0;
    containerProps.onKeyUp = (e) => e.key === 'Enter' && onClick(e);
    containerProps.onClick = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => onClick(e);
  }

  const Text = size === 'big' ? BaSeSmall1 : BaSeSmall2;

  return (
    <TagContainerStyled {...containerProps}>
      {icon && (
        <span className="BaSe--tag-icon icon">
          <BaSeIcon
            size={size === 'small' ? 12 : 16}
            name={icon}
            description={`Ícone da etiqueta com o rótulo: "${label}"`}
            color={iconColor}
          />
        </span>
      )}
      {label && (
        <span className="BaSe--tag-text text">
          <Text isBold={true} color={labelColor}>
            {label}
          </Text>
        </span>
      )}
      {onClose && (
        <span className="BaSe--tag-close close">
          <BaSeShapeButton
            buttonType="button"
            nameIcon="close"
            shape="circle"
            size="small"
            sizeIcon="small"
            type="tertiary"
            color={labelColor}
            onClick={(e) => {
              if (onClick) {
                e.stopPropagation();
              }
              onClose(e);
            }}
          />
        </span>
      )}
    </TagContainerStyled>
  );
};
