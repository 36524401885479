import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { BaSeTheme } from '../../theme';

const loadingRotatation = keyframes`
  to {
    transform: rotate(2turn);
  }
`;

const loadingSize = keyframes`
  from, 60% {
    stroke-dasharray: 100% 180%;
  }
`;

const GenericAnimated = styled.span`
  stroke: ${BaSeTheme.colors.defaultColors.white};
  stroke-linecap: round;
  stroke-dasharray: 180% 100%;
  animation: ${loadingRotatation} 3s cubic-bezier(0.5, -0.4, 0.5, 1.4) infinite,
    ${loadingSize} 1.5s cubic-bezier(0.5, -0.8, 0.5, 1.8) infinite alternate;
`;

export interface UseSpinnerProps {
  xlinkHref: string;
}

export const UseSpinnerAnimated: React.FC<UseSpinnerProps> = (
  props: UseSpinnerProps,
) => <GenericAnimated as="use" {...props} />;

interface GenericGradientProps {
  uuid: string;
  index: string;
  rotation: string;
}

const GenericGradient = styled.span<GenericGradientProps>`
  ${({ uuid, index, rotation }) => css`
    transform: rotate(${rotation});
    mask: url('#base-loading-spinner-${uuid}-mask');
    stroke: url('#base-loading-spinner-${uuid}-gradient-${index}');
  `}
`;

export const GeometrySpinnerGradient: React.FC<GenericGradientProps> = ({
  children,
  index,
  uuid,
  rotation,
}) => (
  <GenericGradient as="g" index={index} uuid={uuid} rotation={rotation}>
    {children}
  </GenericGradient>
);

export interface SvgSpinnerProps {
  diameter: number;
}

export const SvgSpinner = styled.svg<SvgSpinnerProps>`
  ${({ diameter }) => css`
    width: ${diameter}px;
    height: ${diameter}px;
  `}
  fill: none;
  stroke: transparent;
  stroke-width: 12%;
  background: transparent;
  transform: rotate(110deg);
`;

export interface LoadingItemsProps {
  shadow?: string;
  backgroundColor?: string;
  roundedCorner?: boolean;
}

export const LoadingContainer = styled.div<LoadingItemsProps>`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  padding: 32px;
  box-sizing: border-box;
  ${({ roundedCorner, shadow, backgroundColor }) => css`
    border-radius: ${roundedCorner ? 12 : 0}px;
    box-shadow: ${shadow ?? 'none'};
    background-color: ${backgroundColor ??
    BaSeTheme.colors.defaultColors.transparent};
  `}
`;

export const LoadingItems = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;
