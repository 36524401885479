import styled, { keyframes } from 'styled-components';
import { BaSeTheme } from '../../theme';

export interface RotateTransitionProps {
  rotation: number;
  delay: string;
}

const rotate = ({ rotation }: RotateTransitionProps) => keyframes`
  40% {
    transform: scale(.8);
  }
  100% {
    transform: rotate(${rotation}deg) scale(1);
  }
`;

export const RotateTransitionStyled = styled.span<RotateTransitionProps>`
  display: inline-block;
  animation: ${(props) => rotate(props)} ${BaSeTheme.transitions.durationSlow}
    ${({ delay }) => delay} ${BaSeTheme.transitions.elasticTimingFunction}
    forwards;
`;
