import styled, { css } from 'styled-components';
import { mapSizeCheckbox } from './map-checkbox-style';
import { BaSeTheme } from '../../../theme';

interface DisabledCheckbox {
  disabled: boolean;
}

interface CheckedCheckbox {
  checked: boolean;
}
interface StyledCheckboxProps extends DisabledCheckbox, CheckedCheckbox {
  focus: boolean;
  hover: boolean;
}

interface StyledImageWrapperProps extends CheckedCheckbox {
  indeterminate: boolean;
}

interface StyledLabelProps extends DisabledCheckbox {
  size: 'medium' | 'small';
}

export const CheckboxContainer = styled.div<DisabledCheckbox>`
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${BaSeTheme.zIndexs.units + 1};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const StyledImageWrapper = styled.div<StyledImageWrapperProps>`
  z-index: ${BaSeTheme.zIndexs.units};
  display: ${(props) =>
    props.checked && !props.indeterminate ? 'block' : 'none'};
`;

export const disabledCheckedStyles = css`
  display: inline-block;
  width: 14px;
  height: 14px;
  background: ${BaSeTheme.colors.institucionais.cinzaSebrae75};
  border-radius: 4px;
  border: 2px solid ${BaSeTheme.colors.institucionais.cinzaSebrae75};
`;

export const disabledUncheckedStyles = css`
  display: inline-block;
  width: 14px;
  height: 14px;
  background: ${BaSeTheme.colors.institucionais.cinzaSebrae97};
  border-radius: 4px;
  border: 2px solid ${BaSeTheme.colors.defaultColors.lightGray};
`;

const hoverCheckedStyles = css`
  background: ${BaSeTheme.colors.institucionais.azulSebrae30};
  border: 2px solid ${BaSeTheme.colors.institucionais.azulSebrae30};
`;

const hoverUncheckedStyles = css`
  border: 2px solid ${BaSeTheme.colors.institucionais.azulSebrae30};
`;

const checkedStyles = css`
  display: inline-block;
  width: 14px;
  height: 14px;
  background: ${BaSeTheme.colors.institucionais.azulSebrae36};
  border-radius: 4px;
  border: 2px solid ${BaSeTheme.colors.institucionais.azulSebrae36};
  position: relative;
`;

const uncheckedStyles = css`
  display: inline-block;
  width: 14px;
  height: 14px;
  background: ${BaSeTheme.colors.defaultColors.white};
  border-radius: 4px;
  border: 2px solid ${BaSeTheme.colors.institucionais.cinzaSebrae75};
`;

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  ${(props) =>
    props.disabled
      ? props.checked
        ? disabledCheckedStyles
        : disabledUncheckedStyles
      : props.checked
      ? checkedStyles
      : uncheckedStyles}

  ${(props) =>
    props.focus &&
    css`
      outline: none;
      box-shadow: 0px 0px 0px 3px
        ${BaSeTheme.colors.institucionais.azulSebrae75};
    `}

  ${(props) =>
    !props.disabled &&
    props.hover &&
    (props.checked ? hoverCheckedStyles : hoverUncheckedStyles)}

  &:hover {
    ${(props) =>
      !props.disabled &&
      (props.checked ? hoverCheckedStyles : hoverUncheckedStyles)}
  }
`;

export const StyledLabel = styled.label<StyledLabelProps>`
  margin-left: 5px;
  font-size: ${({ size }) => mapSizeCheckbox[size] ?? mapSizeCheckbox.medium}px;
  ${({ disabled }) => css`
    color: ${disabled
      ? BaSeTheme.colors.institucionais.cinzaSebrae75
      : BaSeTheme.colors.institucionais.cinzaSebrae30};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `}
`;

export const WrapperCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  height: 24;
  align-items: center;
`;

export const IndeterminateLine = styled.div`
  height: 1px;
  width: 10px;
  background-color: ${BaSeTheme.colors.defaultColors.white};
  margin-left: 2px;
  z-index: 10px; // Precisa ser corrigido pelo dev que implementou o componente
  border-radius: 4;
  position: absolute;
  top: 50%;
`;
