import * as React from 'react';
import {
  StyledTogglerInput,
  StyledTogglerLabel,
  StyledTogglerSpan,
  StyledTogglerIconContainer,
} from './toggler-styled';
import { BaSeIcon } from '../image/icon';
import { BaSeTheme } from '../../theme';

interface TogglerType {
  typeToggler: 'outlined' | 'contained';
  isDisabled: boolean;
  value: boolean;
  onChange: any;
}

export const BaSeToggler: React.FC<TogglerType> = ({
  typeToggler = 'outlined',
  isDisabled = false,
  value = false,
  onChange = () => { },
}) => {
  return (
    <StyledTogglerLabel value={value} isDisabled={isDisabled} type={typeToggler}>
      <StyledTogglerInput aria-checked={value} role="checkbox" checked={value} onChange={onChange} type="checkbox" disabled={isDisabled} />
      <StyledTogglerSpan type={typeToggler} isDisabled={isDisabled} value={value} />
      {value && <StyledTogglerIconContainer>
        <BaSeIcon
          color={typeToggler === 'outlined'
            ? BaSeTheme.colors.institucionais.azulSebrae36
            : BaSeTheme.colors.defaultColors.white
          }
          description="Checkbox marcado"
          name="check"
          size={20}
          onClick={() => { }}
        />
      </StyledTogglerIconContainer>}
    </StyledTogglerLabel>
  );
};
