import * as React from 'react';
import { BaSeModal } from '../components/dialog/modal';
import { BaSeNotification } from '../components/dialog/notification';
import { ThemeColorValue } from '../theme/theme-interface';

let idCounter = 1;
function idGenerator(): number {
  return idCounter++;
}

function addInPool<State>(
  setPoolState: React.Dispatch<React.SetStateAction<State[]>>,
  item: State,
): void {
  setPoolState((pool) => [...pool, item]);
}

function removeFromPool<State extends { id: number }>(
  setPoolState: React.Dispatch<React.SetStateAction<State[]>>,
  itemToRemove: State,
): void {
  setPoolState((pool) => pool.filter((item) => item.id !== itemToRemove.id));
}

export interface BaSeDiologModalActionsInterface {
  action: string;
  color?: ThemeColorValue;
  type?: 'primary' | 'secondary' | 'tertiary';
}

type FunctionElement = () => JSX.Element;

export interface BaSeDialogModalInterface {
  id: number;
  title: string | FunctionElement;
  size: 'small' | 'medium' | 'large';
  actions: (string | BaSeDiologModalActionsInterface)[];
  dismissable?: boolean;
  onClose: (option: string) => void;
  onCancel: () => void;
  content?: (onClose?: (option: string) => void) => JSX.Element;
  titleColor?: ThemeColorValue;
  alignActions?: 'center' | 'start' | 'end';
}

export type BaSeDialogNotificationColorType =
  | 'sucesso'
  | 'erro'
  | 'atencao'
  | 'informacao';

export interface BaSeDialogNotificationInterface {
  id: number;
  color: BaSeDialogNotificationColorType;
  message: string;
  icon?: string | false;
  supportMessage?: string;
  secondsToClose?: number;
  action?: string;
  onClose: (forced: boolean) => void;
  onAction: () => void;
}

export type BaSeDialogModalConfig = Pick<
  BaSeDialogModalInterface,
  | 'title'
  | 'content'
  | 'actions'
  | 'dismissable'
  | 'size'
  | 'titleColor'
  | 'alignActions'
> & {
  onOpen?: () => void;
  onChooseAction?: (option: string) => void;
  onDismiss?: () => void;
  afterClose?: () => void;
};

export type BaSeDialogNotificationConfig = Pick<
  BaSeDialogNotificationInterface,
  'color' | 'message' | 'supportMessage' | 'secondsToClose' | 'action' | 'icon'
> & {
  onOpen?: () => void;
  onActionClose?: () => void;
  onForceClose?: () => void;
  onAutoClose?: () => void;
  afterClose?: () => void;
};

export interface BaSeDialogContextInterface {
  showModal: (config: BaSeDialogModalConfig) => void;
  showNotification: (config: BaSeDialogNotificationConfig) => void;
}

export const BaSeDialogContext = React.createContext<BaSeDialogContextInterface>(
  {
    showModal: () => {},
    showNotification: () => {},
  },
);

export const BaSeDialogProvider: React.FC = ({ children }) => {
  const [modalPool, setModalPool] = React.useState<BaSeDialogModalInterface[]>(
    [],
  );
  const [notificationPool, setNotificationPool] = React.useState<
    BaSeDialogNotificationInterface[]
  >([]);

  const showModal = React.useCallback((config: BaSeDialogModalConfig) => {
    const {
      title,
      content,
      actions,
      dismissable,
      size,
      titleColor,
      alignActions,
    } = config;
    const id = idGenerator();
    const modal: BaSeDialogModalInterface = {
      id,
      size,
      title,
      actions,
      titleColor,
      dismissable,
      alignActions,
      content,
      onCancel: () => {
        config?.onDismiss?.();
        removeFromPool(setModalPool, modal);
        config?.afterClose?.();
      },
      onClose: (option: string) => {
        config?.onChooseAction?.(option);
        removeFromPool(setModalPool, modal);
        config?.afterClose?.();
      },
    };
    config?.onOpen?.();
    addInPool(setModalPool, modal);
  }, []);

  const showNotification = React.useCallback(
    (config: BaSeDialogNotificationConfig) => {
      const {
        color,
        message,
        supportMessage,
        secondsToClose,
        action,
        icon,
      } = config;
      const id = idGenerator();
      const notification: BaSeDialogNotificationInterface = {
        id,
        icon,
        color,
        message,
        supportMessage,
        secondsToClose,
        action,
        onClose: (forced: boolean) => {
          if (forced) {
            config?.onForceClose?.();
          } else {
            config?.onAutoClose?.();
          }
          removeFromPool(setNotificationPool, notification);
          config?.afterClose?.();
        },
        onAction: () => {
          config?.onActionClose?.();
          removeFromPool(setNotificationPool, notification);
          config?.afterClose?.();
        },
      };
      config?.onOpen?.();
      addInPool(setNotificationPool, notification);
    },
    [],
  );

  const providedValue: BaSeDialogContextInterface = {
    showModal,
    showNotification,
  };

  return (
    <>
      <BaSeDialogContext.Provider value={providedValue}>
        {children}
      </BaSeDialogContext.Provider>

      {modalPool.map(({ id, ...rest }) => (
        <BaSeModal key={id} {...rest} />
      ))}
      {notificationPool.map(({ id, ...rest }) => (
        <BaSeNotification key={id} {...rest} />
      ))}
    </>
  );
};

export const BaSeDialogConsumer = BaSeDialogContext.Consumer;
