import React from 'react';
import { BaSeTheme } from '../../theme';
import { BaSeHeading5 } from '../typography/heading/heading5';
import {
  TabColorProps,
  TabContainer,
  TabItem,
  TapControlProps,
} from './tabs-styled';

export interface TabProps extends TapControlProps, TabColorProps {
  title: string;
  index: number;
  isDisabled: boolean;
  onActivatedTabIndex: (tabIndex: number) => void;
  onActiveItem: (tabLeft: number, tabWidth: number) => void;
  onDestakItem: (tabLeft: number, tabWidth: number) => void;
}

export const BaSeTab: React.FC<TabProps> = ({
  title,
  index,
  isActive,
  activeColor = BaSeTheme.colors.institucionais.azulSebrae36,
  inactiveColor = BaSeTheme.colors.institucionais.cinzaSebrae45,
  isDisabled = false,
  onActivatedTabIndex,
  onActiveItem,
  onDestakItem,
}) => {
  const itemRef = React.createRef<HTMLButtonElement>();

  React.useEffect(() => {
    if (isActive) {
      const rectActive = itemRef?.current?.getBoundingClientRect();

      if ((rectActive?.left ?? 0) <= (itemRef?.current?.offsetLeft ?? 0)) {
        onActiveItem(rectActive?.left ?? 0, rectActive?.width ?? 0);
      } else {
        onActiveItem(itemRef?.current?.offsetLeft ?? 0, rectActive?.width ?? 0);
      }

      onActivatedTabIndex(index);
    }
  }, [itemRef, isActive]);

  const setDestakItem = React.useCallback(() => {
    const rectDestak = itemRef?.current?.getBoundingClientRect();
    onDestakItem(itemRef?.current?.offsetLeft ?? 0, rectDestak?.width ?? 0);
  }, [itemRef]);

  return (
    <TabContainer className="BaSe--tab" isActive={isActive}>
      <TabItem
        ref={itemRef}
        isActive={isActive}
        disabled={isDisabled}
        onPointerLeave={() => onDestakItem(0, 0)}
        onBlur={() => onDestakItem(0, 0)}
        onFocus={setDestakItem}
        onPointerEnter={setDestakItem}
        onClick={() => {
          const rect = itemRef?.current?.getBoundingClientRect();
          onDestakItem(0, 0);
          onActiveItem(itemRef?.current?.offsetLeft ?? 0, rect?.width ?? 0);
          onActivatedTabIndex(index);
        }}
      >
        <BaSeHeading5
          isBold={isActive ? true : false}
          color={isActive ? activeColor : inactiveColor}
        >
          {title}
        </BaSeHeading5>
      </TabItem>
    </TabContainer>
  );
};
