import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';

interface StaticAssetStyledProps {
  width?: string;
  height?: string;
  filter?: string;
}

export const StaticAssetStyled = styled.img<StaticAssetStyledProps>`
  width: ${props => props.width};
  height: ${props => props.height};
  filter: ${props => props.filter};

  &::selection {
    background: transparent;
  }

  &:focus {
    outline: none;
  }

  ${props => props.onClick && css`
    cursor: pointer;
    // TODO ver qual vai ser o efeito de focus e hover
    /* &:focus {
      box-shadow: 0 0 0 3px ${BaSeTheme.colors.institucionais.azulSebrae75};
    }
    &:hover {
      background: ${BaSeTheme.colors.institucionais.azulSebrae30};
    } */
  `}
`;
