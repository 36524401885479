import { ThemeColorsInterface } from './theme-interface';

const palette = {
  transparent: 'transparent',

  shadowColor: 'rgba(20, 46, 82, 0.2)',
  white: '#ffffff',
  whiteOpacity80: '#ffffffCC',
  whiteOpacity50: '#ffffff80',
  black: '#000000',
  lightGray: '#e0e6eb',

  azulSebrae10: '#0a1729',
  azulSebrae20: '#142e52',
  azulSebrae30: '#0f438a',
  azulSebrae36: '#005eb8',
  azulSebrae60: '#5c8fd6',
  azulSebrae75: '#8fb8ef',
  azulSebrae90: '#cfe2fc',
  azulSebrae97: '#f0f6fe',

  cinzaSebrae10: '#121a21',
  cinzaSebrae20: '#243342',
  cinzaSebrae30: '#394d60',
  cinzaSebrae45: '#617385',
  cinzaSebrae60: '#8b99a7',
  cinzaSebrae75: '#b6bfc8',
  cinzaSebrae90: '#e0e5eb',
  cinzaSebrae97: '#f5f7fa',

  marrom10: '#231910',
  marrom20: '#463320',
  marrom30: '#6a4c2f',
  marrom50: '#b07f4f',
  marrom60: '#b8997a',
  marrom75: '#d2c0ac',
  marrom90: '#ede5de',

  laranja10: '#29140a',
  laranja25: '#66331a',
  laranja40: '#a35229',
  laranja63: '#ff8040',
  laranja73: '#ffa375',
  laranja80: '#ffbb99',
  laranja90: '#fcdecf',

  verdeOliva10: '#1c280b',
  verdeOliva20: '#385214',
  verdeOliva30: '#547a1f',
  verdeOliva47: '#83bf30',
  verdeOliva60: '#a2cf63',
  verdeOliva75: '#c5e19e',
  verdeOliva90: '#e7f3d8',

  cinza10: '#181a1b',
  cinza25: '#3c4143',
  cinza30: '#61686b',
  cinza67: '#a1adb3',
  cinza75: '#b9c1c6',
  cinza85: '#d4dadd',
  cinza90: '#e2e6e8',

  roxo10: '#231023',
  roxo20: '#462046',
  roxo30: '#6a2f6a',
  roxo44: '#994599',
  roxo60: '#b87ab8',
  roxo75: '#d2acd2',
  roxo90: '#f0dbf0',

  turquesa10: '#003325',
  turquesa15: '#004d37',
  turquesa25: '#00805c',
  turquesa40: '#00cc92',
  turquesa60: '#55ddb7',
  turquesa75: '#94ead2',
  turquesa90: '#d4f7ed',

  azulAqua10: '#05282e',
  azulAqua20: '#094f5d',
  azulAqua30: '#0e778b',
  azulAqua44: '#14adcc',
  azulAqua60: '#58c4da',
  azulAqua75: '#96dbe9',
  azulAqua90: '#d5f1f6',

  vermelho10: '#280b0d',
  vermelho20: '#52141a',
  vermelho35: '#8e242c',
  vermelho47: '#bf303c',
  vermelho65: '#e06c76',
  vermelho80: '#f0a8af',
  vermelho90: '#f3d8da',

  amarelo10: '#291f0a',
  amarelo25: '#664d1a',
  amarelo35: '#8f6b24',
  amarelo63: '#ffbf40',
  amarelo73: '#ffd175',
  amarelo80: '#ffdd99',
  amarelo90: '#ffeecc',

  sucesso20: '#00662a',
  sucesso26: '#008537',
  sucesso45: '#35b068',
  sucesso70: '#85e0a3',
  sucesso75: '#99e5b2',
  sucesso85: '#c2efd1',
  sucesso95: '#ebfaf0',

  erro35: '#b22400',
  erro45: '#db340b',
  erro60: '#f5623d',
  erro70: '#ff8566',
  erro75: '#ff9980',
  erro85: '#ffc1b3',
  erro95: '#ffeae5',

  atencao35: '#ab6005',
  atencao55: '#ffb31a',
  atencao60: '#ffbc33',
  atencao70: '#ffdc66',
  atencao75: '#ffe380',
  atencao85: '#fff0b3',
  atencao95: '#fffae6',
};

const contrastPalette = {
  azulSebrae10: palette.white,
  azulSebrae20: palette.white,
  azulSebrae30: palette.white,
  azulSebrae36: palette.white,
  azulSebrae60: palette.cinzaSebrae10,
  azulSebrae75: palette.cinzaSebrae10,
  azulSebrae90: palette.cinzaSebrae20,
  azulSebrae97: palette.cinzaSebrae20,

  cinzaSebrae10: palette.white,
  cinzaSebrae20: palette.white,
  cinzaSebrae30: palette.white,
  cinzaSebrae45: palette.white,
  cinzaSebrae60: palette.cinzaSebrae10,
  cinzaSebrae75: palette.cinzaSebrae10,
  cinzaSebrae90: palette.cinzaSebrae20,
  cinzaSebrae97: palette.cinzaSebrae30,

  marrom10: palette.white,
  marrom20: palette.white,
  marrom30: palette.white,
  marrom50: palette.cinzaSebrae10,
  marrom60: palette.cinzaSebrae10,
  marrom75: palette.cinzaSebrae10,
  marrom90: palette.cinzaSebrae10,

  laranja10: palette.white,
  laranja25: palette.white,
  laranja40: palette.white,
  laranja63: palette.cinzaSebrae10,
  laranja73: palette.cinzaSebrae10,
  laranja80: palette.cinzaSebrae20,
  laranja90: palette.cinzaSebrae20,

  verdeOliva10: palette.white,
  verdeOliva20: palette.white,
  verdeOliva30: palette.white,
  verdeOliva47: palette.cinzaSebrae10,
  verdeOliva60: palette.cinzaSebrae10,
  verdeOliva75: palette.cinzaSebrae10,
  verdeOliva90: palette.cinzaSebrae20,

  cinza10: palette.white,
  cinza25: palette.white,
  cinza30: palette.white,
  cinza67: palette.cinzaSebrae10,
  cinza75: palette.cinzaSebrae10,
  cinza85: palette.cinzaSebrae20,
  cinza90: palette.cinzaSebrae20,

  roxo10: palette.white,
  roxo20: palette.white,
  roxo30: palette.white,
  roxo44: palette.white,
  roxo60: palette.cinzaSebrae10,
  roxo75: palette.cinzaSebrae10,
  roxo90: palette.cinzaSebrae20,

  turquesa10: palette.white,
  turquesa15: palette.white,
  turquesa25: palette.white,
  turquesa40: palette.cinzaSebrae10,
  turquesa60: palette.cinzaSebrae10,
  turquesa75: palette.cinzaSebrae10,
  turquesa90: palette.cinzaSebrae20,

  azulAqua10: palette.white,
  azulAqua20: palette.white,
  azulAqua30: palette.white,
  azulAqua44: palette.cinzaSebrae10,
  azulAqua60: palette.cinzaSebrae10,
  azulAqua75: palette.cinzaSebrae10,
  azulAqua90: palette.cinzaSebrae20,

  vermelho10: palette.white,
  vermelho20: palette.white,
  vermelho35: palette.white,
  vermelho47: palette.cinzaSebrae10,
  vermelho65: palette.cinzaSebrae10,
  vermelho80: palette.cinzaSebrae10,
  vermelho90: palette.cinzaSebrae10,

  amarelo10: palette.white,
  amarelo25: palette.white,
  amarelo35: palette.white,
  amarelo63: palette.cinzaSebrae10,
  amarelo73: palette.cinzaSebrae20,
  amarelo80: palette.cinzaSebrae20,
  amarelo90: palette.cinzaSebrae20,

  sucesso20: palette.white,
  sucesso26: palette.white,
  sucesso45: palette.cinzaSebrae10,
  sucesso70: palette.cinzaSebrae10,
  sucesso75: palette.cinzaSebrae10,
  sucesso85: palette.cinzaSebrae20,
  sucesso95: palette.cinzaSebrae20,

  erro35: palette.white,
  erro45: palette.white,
  erro60: palette.cinzaSebrae10,
  erro70: palette.cinzaSebrae10,
  erro75: palette.cinzaSebrae10,
  erro85: palette.cinzaSebrae20,
  erro95: palette.cinzaSebrae20,

  atencao35: palette.white,
  atencao55: palette.cinzaSebrae10,
  atencao60: palette.cinzaSebrae10,
  atencao70: palette.cinzaSebrae20,
  atencao75: palette.cinzaSebrae20,
  atencao85: palette.cinzaSebrae20,
  atencao95: palette.cinzaSebrae20,
};

export const BaSeColors: ThemeColorsInterface = {
  institucionais: {
    azulSebrae: palette.azulSebrae36,
    azulSebrae10: palette.azulSebrae10,
    azulSebrae20: palette.azulSebrae20,
    azulSebrae30: palette.azulSebrae30,
    azulSebrae36: palette.azulSebrae36,
    azulSebrae60: palette.azulSebrae60,
    azulSebrae75: palette.azulSebrae75,
    azulSebrae90: palette.azulSebrae90,
    azulSebrae97: palette.azulSebrae97,

    cinzaSebrae: palette.cinzaSebrae10,
    cinzaSebrae10: palette.cinzaSebrae10,
    cinzaSebrae20: palette.cinzaSebrae20,
    cinzaSebrae30: palette.cinzaSebrae30,
    cinzaSebrae45: palette.cinzaSebrae45,
    cinzaSebrae60: palette.cinzaSebrae60,
    cinzaSebrae75: palette.cinzaSebrae75,
    cinzaSebrae90: palette.cinzaSebrae90,
    cinzaSebrae97: palette.cinzaSebrae97,
  },

  gestao: {
    cooperacao: palette.marrom50,
    marrom10: palette.marrom10,
    marrom20: palette.marrom20,
    marrom30: palette.marrom30,
    marrom50: palette.marrom50,
    marrom60: palette.marrom60,
    marrom75: palette.marrom75,
    marrom90: palette.marrom90,

    empreendedorismo: palette.laranja63,
    laranja10: palette.laranja10,
    laranja25: palette.laranja25,
    laranja40: palette.laranja40,
    laranja63: palette.laranja63,
    laranja73: palette.laranja73,
    laranja80: palette.laranja80,
    laranja90: palette.laranja90,

    financas: palette.verdeOliva47,
    verdeOliva10: palette.verdeOliva10,
    verdeOliva20: palette.verdeOliva20,
    verdeOliva30: palette.verdeOliva30,
    verdeOliva47: palette.verdeOliva47,
    verdeOliva60: palette.verdeOliva60,
    verdeOliva75: palette.verdeOliva75,
    verdeOliva90: palette.verdeOliva90,

    inovacao: palette.cinza67,
    cinza10: palette.cinza10,
    cinza25: palette.cinza25,
    cinza30: palette.cinza30,
    cinza67: palette.cinza67,
    cinza75: palette.cinza75,
    cinza85: palette.cinza85,
    cinza90: palette.cinza90,

    leis: palette.roxo44,
    roxo10: palette.roxo10,
    roxo20: palette.roxo20,
    roxo30: palette.roxo30,
    roxo44: palette.roxo44,
    roxo60: palette.roxo60,
    roxo75: palette.roxo75,
    roxo90: palette.roxo90,

    mercadoVendas: palette.turquesa40,
    turquesa10: palette.turquesa10,
    turquesa15: palette.turquesa15,
    turquesa25: palette.turquesa25,
    turquesa40: palette.turquesa40,
    turquesa60: palette.turquesa60,
    turquesa75: palette.turquesa75,
    turquesa90: palette.turquesa90,

    organizacao: palette.azulAqua44,
    azulAqua10: palette.azulAqua10,
    azulAqua20: palette.azulAqua20,
    azulAqua30: palette.azulAqua30,
    azulAqua44: palette.azulAqua44,
    azulAqua60: palette.azulAqua60,
    azulAqua75: palette.azulAqua75,
    azulAqua90: palette.azulAqua90,

    pessoas: palette.vermelho47,
    vermelho10: palette.vermelho10,
    vermelho20: palette.vermelho20,
    vermelho35: palette.vermelho35,
    vermelho47: palette.vermelho47,
    vermelho65: palette.vermelho65,
    vermelho80: palette.vermelho80,
    vermelho90: palette.vermelho90,

    planejamento: palette.amarelo63,
    amarelo10: palette.amarelo10,
    amarelo25: palette.amarelo25,
    amarelo35: palette.amarelo35,
    amarelo63: palette.amarelo63,
    amarelo73: palette.amarelo73,
    amarelo80: palette.amarelo80,
    amarelo90: palette.amarelo90,
  },

  feedbackInterface: {
    sucesso: palette.sucesso70,
    sucesso20: palette.sucesso20,
    sucesso26: palette.sucesso26,
    sucesso45: palette.sucesso45,
    sucesso70: palette.sucesso70,
    sucesso75: palette.sucesso75,
    sucesso85: palette.sucesso85,
    sucesso95: palette.sucesso95,

    erro: palette.erro70,
    erro35: palette.erro35,
    erro45: palette.erro45,
    erro60: palette.erro60,
    erro70: palette.erro70,
    erro75: palette.erro75,
    erro85: palette.erro85,
    erro95: palette.erro95,

    atencao: palette.atencao70,
    atencao35: palette.atencao35,
    atencao55: palette.atencao55,
    atencao60: palette.atencao60,
    atencao70: palette.atencao70,
    atencao75: palette.atencao75,
    atencao85: palette.atencao85,
    atencao95: palette.atencao95,

    informacao: palette.azulSebrae36,
    azulSebrae10: palette.azulSebrae10,
    azulSebrae20: palette.azulSebrae20,
    azulSebrae30: palette.azulSebrae30,
    azulSebrae36: palette.azulSebrae36,
    azulSebrae60: palette.azulSebrae60,
    azulSebrae75: palette.azulSebrae75,
    azulSebrae90: palette.azulSebrae90,
  },

  defaultColors: {
    transparent: palette.transparent,
    shadowColor: palette.shadowColor,
    lightGray: palette.lightGray,
    white: palette.white,
    whiteOpacity50: palette.whiteOpacity50,
    whiteOpacity80: palette.whiteOpacity80,
    black: palette.black,
  },

  contrast: {
    institucionais: {
      azulSebrae: contrastPalette.azulSebrae36,
      azulSebrae10: contrastPalette.azulSebrae10,
      azulSebrae20: contrastPalette.azulSebrae20,
      azulSebrae30: contrastPalette.azulSebrae30,
      azulSebrae36: contrastPalette.azulSebrae36,
      azulSebrae60: contrastPalette.azulSebrae60,
      azulSebrae75: contrastPalette.azulSebrae75,
      azulSebrae90: contrastPalette.azulSebrae90,
      azulSebrae97: contrastPalette.azulSebrae97,

      cinzaSebrae: contrastPalette.cinzaSebrae10,
      cinzaSebrae10: contrastPalette.cinzaSebrae10,
      cinzaSebrae20: contrastPalette.cinzaSebrae20,
      cinzaSebrae30: contrastPalette.cinzaSebrae30,
      cinzaSebrae45: contrastPalette.cinzaSebrae45,
      cinzaSebrae60: contrastPalette.cinzaSebrae60,
      cinzaSebrae75: contrastPalette.cinzaSebrae75,
      cinzaSebrae90: contrastPalette.cinzaSebrae90,
      cinzaSebrae97: contrastPalette.cinzaSebrae97,
    },

    gestao: {
      cooperacao: contrastPalette.marrom50,
      marrom10: contrastPalette.marrom10,
      marrom20: contrastPalette.marrom20,
      marrom30: contrastPalette.marrom30,
      marrom50: contrastPalette.marrom50,
      marrom60: contrastPalette.marrom60,
      marrom75: contrastPalette.marrom75,
      marrom90: contrastPalette.marrom90,

      empreendedorismo: contrastPalette.laranja63,
      laranja10: contrastPalette.laranja10,
      laranja25: contrastPalette.laranja25,
      laranja40: contrastPalette.laranja40,
      laranja63: contrastPalette.laranja63,
      laranja73: contrastPalette.laranja73,
      laranja80: contrastPalette.laranja80,
      laranja90: contrastPalette.laranja90,

      financas: contrastPalette.verdeOliva47,
      verdeOliva10: contrastPalette.verdeOliva10,
      verdeOliva20: contrastPalette.verdeOliva20,
      verdeOliva30: contrastPalette.verdeOliva30,
      verdeOliva47: contrastPalette.verdeOliva47,
      verdeOliva60: contrastPalette.verdeOliva60,
      verdeOliva75: contrastPalette.verdeOliva75,
      verdeOliva90: contrastPalette.verdeOliva90,

      inovacao: contrastPalette.cinza67,
      cinza10: contrastPalette.cinza10,
      cinza25: contrastPalette.cinza25,
      cinza30: contrastPalette.cinza30,
      cinza67: contrastPalette.cinza67,
      cinza75: contrastPalette.cinza75,
      cinza85: contrastPalette.cinza85,
      cinza90: contrastPalette.cinza90,

      leis: contrastPalette.roxo44,
      roxo10: contrastPalette.roxo10,
      roxo20: contrastPalette.roxo20,
      roxo30: contrastPalette.roxo30,
      roxo44: contrastPalette.roxo44,
      roxo60: contrastPalette.roxo60,
      roxo75: contrastPalette.roxo75,
      roxo90: contrastPalette.roxo90,

      mercadoVendas: contrastPalette.turquesa40,
      turquesa10: contrastPalette.turquesa10,
      turquesa15: contrastPalette.turquesa15,
      turquesa25: contrastPalette.turquesa25,
      turquesa40: contrastPalette.turquesa40,
      turquesa60: contrastPalette.turquesa60,
      turquesa75: contrastPalette.turquesa75,
      turquesa90: contrastPalette.turquesa90,

      organizacao: contrastPalette.azulAqua44,
      azulAqua10: contrastPalette.azulAqua10,
      azulAqua20: contrastPalette.azulAqua20,
      azulAqua30: contrastPalette.azulAqua30,
      azulAqua44: contrastPalette.azulAqua44,
      azulAqua60: contrastPalette.azulAqua60,
      azulAqua75: contrastPalette.azulAqua75,
      azulAqua90: contrastPalette.azulAqua90,

      pessoas: contrastPalette.vermelho47,
      vermelho10: contrastPalette.vermelho10,
      vermelho20: contrastPalette.vermelho20,
      vermelho35: contrastPalette.vermelho35,
      vermelho47: contrastPalette.vermelho47,
      vermelho65: contrastPalette.vermelho65,
      vermelho80: contrastPalette.vermelho80,
      vermelho90: contrastPalette.vermelho90,

      planejamento: contrastPalette.amarelo63,
      amarelo10: contrastPalette.amarelo10,
      amarelo25: contrastPalette.amarelo25,
      amarelo35: contrastPalette.amarelo35,
      amarelo63: contrastPalette.amarelo63,
      amarelo73: contrastPalette.amarelo73,
      amarelo80: contrastPalette.amarelo80,
      amarelo90: contrastPalette.amarelo90,
    },

    feedbackInterface: {
      sucesso: contrastPalette.sucesso70,
      sucesso20: contrastPalette.sucesso20,
      sucesso26: contrastPalette.sucesso26,
      sucesso45: contrastPalette.sucesso45,
      sucesso70: contrastPalette.sucesso70,
      sucesso75: contrastPalette.sucesso75,
      sucesso85: contrastPalette.sucesso85,
      sucesso95: contrastPalette.sucesso95,

      erro: contrastPalette.erro70,
      erro35: contrastPalette.erro35,
      erro45: contrastPalette.erro45,
      erro60: contrastPalette.erro60,
      erro70: contrastPalette.erro70,
      erro75: contrastPalette.erro75,
      erro85: contrastPalette.erro85,
      erro95: contrastPalette.erro95,

      atencao: contrastPalette.atencao70,
      atencao35: contrastPalette.atencao35,
      atencao55: contrastPalette.atencao55,
      atencao60: contrastPalette.atencao60,
      atencao70: contrastPalette.atencao70,
      atencao75: contrastPalette.atencao75,
      atencao85: contrastPalette.atencao85,
      atencao95: contrastPalette.atencao95,

      informacao: contrastPalette.azulSebrae36,
      azulSebrae10: contrastPalette.azulSebrae10,
      azulSebrae20: contrastPalette.azulSebrae20,
      azulSebrae30: contrastPalette.azulSebrae30,
      azulSebrae36: contrastPalette.azulSebrae36,
      azulSebrae60: contrastPalette.azulSebrae60,
      azulSebrae75: contrastPalette.azulSebrae75,
      azulSebrae90: contrastPalette.azulSebrae90,
    },
  },
};
