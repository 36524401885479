import * as React from 'react';
import { useDebounce } from '../../../hooks/debounce';
import { BaSeTheme } from '../../../theme';
import { BaSeIcon } from '../../image/icon';
import { BaSeParagraph } from '../../typography/paragraph/paragraph';
import { BaSeSmall1 } from '../../typography/small/small1';
import { BaSeInput, InputProps } from '../text/input';
import {
  AutocompleteResultInline,
  AutocompleteResultItemImage,
  AutocompleteResultList,
  AutocompleteResultListItem,
  AutocompleteWrapper,
  HideTopOfAutocompleteResultList,
} from './autocomplete-styled';

export type AutocompleteResultItemId = number | string;

export interface AutocompleteResultItem {
  id: AutocompleteResultItemId;
  label: string;
  description?: string;
  iconName?: string;
  imageSource?: string;
}

export type AutocompleteResult =
  | AutocompleteResultItem[]
  | Promise<AutocompleteResultItem[]>;

export interface AutocompleteProps
  extends Pick<
    InputProps,
    | 'inputMode'
    | 'isDisabled'
    | 'label'
    | 'placeholder'
    | 'showHelpButton'
    | 'complement'
    | 'moreInfoLabel'
    | 'moreInfoDetails'
    | 'subLabel'
    | 'helpButtonProps'
    | 'width'
    | 'size'
  > {
  mode?: 'inline' | 'list';
  minLengthToSearch?: number;
  imageAndIconSize?: number;
  onSearchTerm: (term: string) => AutocompleteResult;
  onSelectedItem?: (item: AutocompleteResultItem) => void;
  onClearSelection?: () => void;
}

export const BaSeAutocomplete: React.FC<AutocompleteProps> = ({
  label = '',
  mode = 'list',
  placeholder = '',
  width = '100%',
  inputMode = 'search',
  isDisabled = false,
  minLengthToSearch = 3,
  showHelpButton = false,
  complement = '',
  moreInfoLabel = '',
  moreInfoDetails = '',
  size = 'medium',
  subLabel = '',
  helpButtonProps = {},
  imageAndIconSize = 48,
  onSearchTerm,
  onSelectedItem,
  onClearSelection,
}) => {
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isInputFocused, setIsInputFocused] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>('');
  const [resultList, setResultList] = React.useState<AutocompleteResultItem[]>(
    [],
  );

  const listRef = React.useRef<HTMLUListElement>(null);

  const term = useDebounce<string>(value, 350);

  const foundResult = React.useCallback(
    () => !hasError && resultList?.length > 0,
    [hasError, resultList],
  );

  const selectItem = React.useCallback(
    (item?: AutocompleteResultItem) => {
      const resultItem = item ?? resultList?.[selectedIndex];
      onSelectedItem?.(resultItem);
    },
    [resultList, selectedIndex],
  );

  const getResult = React.useCallback(
    () => resultList?.[selectedIndex]?.label,
    [resultList, selectedIndex],
  );

  const resultInline = React.useCallback(() => {
    const regExp = new RegExp(`(^.{${term.length}})(.*$)`);
    return getResult()?.replace?.(regExp, '<span>$1</span>$2');
  }, [resultList, term]);

  const isActive = React.useCallback(
    (item: AutocompleteResultItem) => {
      const itemIndex = resultList.findIndex(
        (resultItem) => resultItem.id === item.id,
      );
      return itemIndex === selectedIndex;
    },
    [selectedIndex, resultList],
  );

  const closeWithDelay = React.useCallback(
    () => setTimeout(() => setIsOpen(false), 150),
    [],
  );

  const attached = React.useCallback(() => !complement && !moreInfoLabel, [
    complement,
    moreInfoLabel,
  ]);

  const clear = React.useCallback(() => {
    setSelectedIndex(0);
    setValue('');
    onClearSelection?.();
  }, [setValue, setSelectedIndex]);

  const eventPreparation = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (mode !== 'inline') {
        event.preventDefault();
        event.stopPropagation();
        return true;
      }
      return false;
    },
    [mode],
  );

  const getCurrentItemHeightActive = React.useCallback(
    () =>
      listRef?.current?.querySelector('.active')?.getBoundingClientRect?.()
        ?.height ?? 0,
    [listRef],
  );

  const onEnter = React.useCallback(() => {
    setValue(getResult());
    selectItem();
  }, [setValue, getResult, selectItem]);

  const onEscape = React.useCallback(() => clear(), [clear]);

  const onArrowUp = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (eventPreparation(event)) {
        const min = 0;
        setSelectedIndex((actualSelectedIndex) =>
          --actualSelectedIndex < min ? min : actualSelectedIndex,
        );
        listRef?.current?.scrollBy({
          behavior: 'smooth',
          top: getCurrentItemHeightActive() * -1,
        });
      }
    },
    [setSelectedIndex],
  );

  const onArrowDown = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (eventPreparation(event)) {
        const max = resultList.length - 1;
        setSelectedIndex((actualSelectedIndex) =>
          ++actualSelectedIndex >= max ? max : actualSelectedIndex,
        );
        listRef?.current?.scrollBy({
          behavior: 'smooth',
          top: getCurrentItemHeightActive(),
        });
      }
    },
    [setSelectedIndex, resultList],
  );

  const onFocus = React.useCallback(() => {
    setIsInputFocused(true);
    setIsOpen(true);
  }, []);

  const onBlur = React.useCallback(() => {
    setIsInputFocused(false);
    closeWithDelay();
  }, [closeWithDelay]);

  React.useEffect(() => {
    if (value !== term) {
      setHasError(false);
      setResultList([]);
    }
    if (value === '') {
      onClearSelection?.();
    }
  }, [term, value]);

  React.useEffect(() => {
    setHasError(false);
    setResultList([]);
    if (minLengthToSearch > term?.length) {
      return;
    }
    setIsLoading(true);
    const afterSearchTerm = onSearchTerm(term);
    if (Array.isArray(afterSearchTerm)) {
      setResultList(afterSearchTerm);
      return setIsLoading(false);
    }
    afterSearchTerm
      .then(setResultList)
      .catch(() => setHasError(true))
      .finally(() => setIsLoading(false));
  }, [term]);

  React.useEffect(
    () => setHasError(resultList.some((item) => !item?.id || !item?.label)),
    [resultList],
  );

  React.useEffect(() => setIsOpen(foundResult() && isInputFocused), [
    resultList,
    foundResult,
  ]);

  return (
    <AutocompleteWrapper>
      <BaSeInput
        width={width}
        showHelpButton={showHelpButton}
        complement={complement}
        moreInfoLabel={moreInfoLabel}
        moreInfoDetails={moreInfoDetails}
        size={size}
        subLabel={subLabel}
        helpButtonProps={helpButtonProps}
        label={label}
        inputMode={inputMode}
        placeholder={placeholder}
        value={value}
        isDisabled={isDisabled}
        isLoading={isLoading}
        iconButton={{
          action: !!value?.length ? clear : undefined,
          color: BaSeTheme.colors.institucionais.azulSebrae,
          name: !!value?.length ? 'close' : 'search',
          typeButton: 'base-icon',
        }}
        onSpecificKeyDown={[
          { listenerKey: 'Enter', callback: onEnter },
          { listenerKey: 'Escape', callback: onEscape },
          { listenerKey: 'ArrowUp', callback: onArrowUp },
          { listenerKey: 'ArrowDown', callback: onArrowDown },
        ]}
        validateOnChange={hasError ? () => false : undefined}
        onChange={(newValue) => setValue(newValue)}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {foundResult() &&
        (mode === 'list' ? (
          <>
            {isOpen && attached() && <HideTopOfAutocompleteResultList />}
            <AutocompleteResultList
              ref={listRef}
              attached={attached()}
              hasError={hasError}
              isOpen={isOpen}
            >
              {resultList.map((item) => (
                <AutocompleteResultListItem
                  className={`BaSe--autocomplete-result-list-item ${
                    isActive(item) ? 'active' : ''
                  }`}
                  key={item.id}
                  onClick={() => {
                    setValue(item.label);
                    selectItem(item);
                  }}
                >
                  {item?.imageSource && (
                    <AutocompleteResultItemImage
                      size={imageAndIconSize}
                      src={item.imageSource}
                      alt={`Imagem de ${item?.label}`}
                    />
                  )}
                  {item?.iconName && (
                    <BaSeIcon
                      name={item.iconName}
                      size={imageAndIconSize}
                      description={`Ícone do item: ${item?.label}`}
                      color={BaSeTheme.colors.defaultColors.black}
                    />
                  )}
                  <span>
                    <BaSeParagraph
                      color={
                        isActive(item)
                          ? BaSeTheme.colors.defaultColors.white
                          : BaSeTheme.colors.defaultColors.black
                      }
                    >
                      {item?.label}
                    </BaSeParagraph>
                    {item?.description && (
                      <>
                        <br />
                        <BaSeSmall1
                          color={
                            isActive(item)
                              ? BaSeTheme.colors.institucionais.cinzaSebrae75
                              : BaSeTheme.colors.institucionais.cinzaSebrae45
                          }
                        >
                          {item?.description}
                        </BaSeSmall1>
                      </>
                    )}
                  </span>
                </AutocompleteResultListItem>
              ))}
            </AutocompleteResultList>
          </>
        ) : (
          <AutocompleteResultInline
            size={size}
            dangerouslySetInnerHTML={{ __html: resultInline() }}
          />
        ))}
    </AutocompleteWrapper>
  );
};
