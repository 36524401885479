import { Color } from './color';

const cache = {};

export const transformLightness = (
  color: string,
  lightness: number,
): string => {
  const cacheKey = `${color}_${lightness}`;
  try {
    if (cache.hasOwnProperty(cacheKey)) {
      return cache[cacheKey];
    }

    const colorInstance = Color.generate(color) as Color;
    const newColor = colorInstance.applyLightColor(() => lightness).toString();

    cache[cacheKey] = newColor;

    return newColor;
  } catch (error) {
    return color;
  }
};
