import * as React from 'react';
import { BaSeLogoSebrae } from '../components/image/logo/logo-sebrae';
import { BaSeHeading1 } from '../components/typography/heading/heading1';
import { BaSeHeading4 } from '../components/typography/heading/heading4';
import { BaSeParagraph } from '../components/typography/paragraph/paragraph';
import { BaSeTheme } from '../theme';
import { CenterStyled } from './styled/center';
import { PageWrapperStyled } from './styled/page-wrapper';
import { RotateTransitionStyled } from './styled/rotate-transition';
import { SideBySideStyled } from './styled/side-by-side';

export interface ErrorPageProps {
  title?: string;
  error?: string;
}

export const BaSeErrorPage: React.FC<ErrorPageProps> = ({ title, error }) => (
  <PageWrapperStyled color={BaSeTheme.colors.institucionais.cinzaSebrae97}>
    <CenterStyled>
      <SideBySideStyled>
        <div style={{ margin: '10px 0' }}>
          <BaSeLogoSebrae width={143} easterEgg={true} />
        </div>
        <div>
          <BaSeHeading1
            isBold={true}
            color={BaSeTheme.colors.institucionais.azulSebrae}
          >
            <RotateTransitionStyled rotation={90} delay="2s">
              : (
            </RotateTransitionStyled>
            <br />
            Ops!
          </BaSeHeading1>
          <div style={{ margin: '21px 0' }}>
            <BaSeHeading4
              isBold={true}
              color={BaSeTheme.colors.institucionais.cinzaSebrae20}
            >
              {title || 'Algo deu errado'}
            </BaSeHeading4>
            <BaSeParagraph
              color={BaSeTheme.colors.institucionais.cinzaSebrae45}
            >
              {error || 'Por favor, tente acessar mais tarde.'}
            </BaSeParagraph>
          </div>
        </div>
      </SideBySideStyled>
    </CenterStyled>
  </PageWrapperStyled>
);
