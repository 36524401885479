import * as React from 'react';
import { BaSeBreakpointProvider } from '../contexts/breakpoint';
import { BaSeDialogProvider } from '../contexts/dialog';

export const BaSeRoot: React.FC = ({ children }) => (
  <BaSeBreakpointProvider>
    <BaSeDialogProvider>
      {children}
    </BaSeDialogProvider>
  </BaSeBreakpointProvider>
);
