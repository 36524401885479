import styled, { keyframes } from 'styled-components';
import { BaSeDialogModalInterface } from '../../contexts/dialog';
import { BaSeTheme } from '../../theme';

interface ModalDialogProps {
  size: 'small' | 'medium' | 'large';
}

type ModalFooterProps = Pick<BaSeDialogModalInterface, 'alignActions'>;

const zIndexOverlay = BaSeTheme.zIndexs.thousands * 2;

function widthMobile(): string {
  return `90%;`;
}

function widthTablet(size: 'small' | 'medium' | 'large'): string {
  if (size === 'large') {
    return '450px';
  }
  if (size === 'medium') {
    return '450px';
  }
  // size === 'small'
  return '390px';
}

function widthDesktop(size: 'small' | 'medium' | 'large'): string {
  if (size === 'large') {
    return '570px';
  }
  if (size === 'medium') {
    return '450px';
  }
  // size === 'small'
  return '390px';
}

const fadeIn = keyframes`
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const ModalContainer = styled.div`
  display: grid;
  place-items: center;
  z-index: ${zIndexOverlay + 0};
  padding: 8px;
  box-sizing: border-box;

  &,
  &::before {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  &::before {
    content: '';
    background: ${BaSeTheme.colors.institucionais.cinzaSebrae20};
    opacity: 0.5;
  }
`;

export const ModalDialog = styled.div<ModalDialogProps>`
  box-sizing: content-box;
  background-color: ${BaSeTheme.colors.defaultColors.white};
  box-shadow: ${BaSeTheme.shadows.profundidade32};
  z-index: ${zIndexOverlay + 1};
  border: 2px solid ${BaSeTheme.colors.institucionais.azulSebrae90};
  border-radius: 4px;
  padding: 0;
  margin: 0;
  will-change: opacity, transform;
  animation: ${fadeIn} ${BaSeTheme.transitions.durationNormal} forwards;

  /*mobileSmall*/
  ${BaSeTheme.breakpoints.mobileSmall.mediaQueryCss(`
    width: ${widthMobile()};
    min-width: ${widthMobile()};
    max-width: ${widthMobile()};
  `)}
  /*mobile*/
  ${BaSeTheme.breakpoints.mobile.mediaQueryCss(`
    width: ${widthMobile()};
    min-width: ${widthMobile()};
    max-width: ${widthMobile()};
  `)}
  /*mobileLarge*/
  ${BaSeTheme.breakpoints.mobileLarge.mediaQueryCss(`
    width: ${widthMobile()};
    min-width: ${widthMobile()};
    max-width: ${widthMobile()};
  `)}
  /*tablet*/
  ${({ size }) =>
    BaSeTheme.breakpoints.tablet.mediaQueryCss(`
    width: ${widthTablet(size)};
    min-width: ${widthTablet(size)};
    max-width: ${widthTablet(size)};
  `)}
  /*desktop*/
  ${({ size }) =>
    BaSeTheme.breakpoints.desktop.mediaQueryCss(`
    width: ${widthDesktop(size)};
    min-width: ${widthDesktop(size)};
    max-width: ${widthDesktop(size)};
  `)}
  /*desktopLarge*/
  ${({ size }) =>
    BaSeTheme.breakpoints.desktopLarge.mediaQueryCss(`
    width: ${widthDesktop(size)};
    min-width: ${widthDesktop(size)};
    max-width: ${widthDesktop(size)};
  `)}
`;

export const ModalHeader = styled.div`
  margin: 0;
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  border: none;
  border-bottom: 1px solid ${BaSeTheme.colors.institucionais.cinzaSebrae90};
`;

export const ModalBody = styled.div`
  margin: 0;
  padding: 16px;
`;

export const ModalFooter = styled.div<ModalFooterProps>`
  margin: 0;
  padding: 0 16px 16px;
  display: flex;
  gap: 16px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${({ alignActions = 'start' }) =>
    alignActions === 'center' ? 'center' : `flex-${alignActions}`};
  align-items: center;
  &:empty {
    padding-bottom: 0;
  }
`;
