import { KeycloakInstance } from 'keycloak-js';
import { urlSanitizer } from '../../../utils/string-utils';

export function getAvatar(
  avatarUrl: string,
  keycloak?: KeycloakInstance,
  userId: string = '',
): string {
  return `${urlSanitizer(avatarUrl)}/${
    (keycloak ? keycloak.tokenParsed?.sub : userId) ?? 'O.o'
  }`;
}
