import * as React from 'react';
import { BaSeConfig } from '../../config/config';
import { transformToFilter } from '../../utils/color-transformation/transform-to-filter';
import { urlSanitizer } from '../../utils/string-utils';
import { StaticAssetStyled } from './static-asset-styled';

interface StaticAssetProps {
  width?: string;
  height?: string;
  path: string | string[];
  description: string;
  color?: string;
  onClick?: (
    e:
      | React.MouseEvent<HTMLImageElement, MouseEvent>
      | React.KeyboardEvent<HTMLImageElement>,
  ) => void;
}

function getSrc(path: string): string {
  if (!path) {
    return '';
  }
  return `${urlSanitizer(BaSeConfig.staticAssetsUrl)}/${path}`;
}

export const BaSeStaticAsset: React.FC<StaticAssetProps> = React.memo(
  ({ path, description, width, height, color, onClick }: StaticAssetProps) => {
    if (!description) {
      throw Error('Informe a descrição da imagem. Atributo "description"');
    }

    const fallbackImage =
      'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

    const [paths, setPaths] = React.useState<string[]>(
      typeof path === 'string' ? [path] : (path as string[]),
    );
    const [pathToApply, setPathToApply] = React.useState<string>(paths[0]);
    const [src, setSrc] = React.useState<string>();
    const filter = React.useMemo(
      () => (!!color ? transformToFilter(color) : 'none'),
      [color],
    );

    React.useEffect(
      () => setPaths(typeof path === 'string' ? [path] : (path as string[])),
      [path],
    );
    React.useEffect(() => setPathToApply(paths[0]), [paths]);
    React.useEffect(() => setSrc(getSrc(pathToApply)), [pathToApply]);

    return (
      <StaticAssetStyled
        loading="lazy"
        src={src}
        alt={description}
        width={width}
        height={height}
        filter={filter}
        onError={() =>
          paths.length === 0
            ? setSrc(fallbackImage)
            : setPaths((actualPaths) =>
                actualPaths.filter((_, index) => index > 0),
              )
        }
        onClick={onClick}
        onKeyDown={(e) => e.key === 'Enter' && onClick?.(e)}
        tabIndex={onClick ? 0 : -1}
        role={onClick ? 'button' : undefined}
      />
    );
  },
);
