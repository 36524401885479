import { ImageProps } from '../image-props';

export type LogoSebraeProps = Pick<ImageProps, 'width' | 'onClick'> & {
  easterEgg?: boolean;
};

export function getLogoPath(easterEgg: boolean, image: string): string[] {
  const paths = [];
  const extension = '.svg';
  easterEgg = false; // TODO quando a estratégia do easter egg estiver ok, remover essa linha
  if (easterEgg) {
    const easterEggPrefix = '__';
    const today = new Date();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    paths.push(`${image}${easterEggPrefix}${month}_${day}${extension}`);
    paths.push(`${image}${easterEggPrefix}${month}${extension}`);
  }
  paths.push(`${image}${extension}`);
  return paths;
}
