import styled from 'styled-components';

interface StyledList {
  columnNum: string | number;
  gap: string | number;
  maxHeight: string | number;
}

interface StyledItem {
  direction: 'column' | 'row' | 'column-reverse' | 'row-reverse';
}

export const WrapperList = styled.ul.attrs({
  className: 'BaSe--list',
}) <StyledList>`
  display: grid;
  grid-template-columns: ${({ columnNum }) => `repeat(${columnNum}, 1fr)`};
  gap: ${({ gap }) => (typeof gap === 'string' ? gap : `${gap}px`)};
  max-height: ${({ maxHeight }) => (typeof maxHeight === 'string' ? maxHeight : `${maxHeight}px`)};
  overflow: auto;
  padding: 22px 0;
`;

export const Wrapperbtn = styled.div`
  display: flex;
`;

export const WrapperItem = styled.li.attrs({
  className: 'BaSe--list-item',
}) <StyledItem>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: center;
  justify-content: ${({ direction }) => (direction === 'row-reverse' ? 'space-between' : 'none')};
`;
