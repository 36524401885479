import * as React from 'react';
import {
  AccordionContainerTitle,
  AccordionContainerContent,
  AccordionTitle,
  AccordionIconContainer,
} from './accordion-styled';
import { BaSeIcon } from '../image/icon';

export interface AccordionOption {
  title: string;
  content: JSX.Element;
}

export interface AccordionProps {
  options: AccordionOption[];
}

export const BaSeAccordion: React.FC<AccordionProps> = ({
  options = [],
}) => {

  const [selected, setSelected] = React.useState(-1);

  const showAccordion = (index: number) => {
    setSelected(value => value === index ? -1 : index);
  };

  return (
    <>
      {options.map((data: AccordionOption, key: number) => (
        <React.Fragment key={key}>
          <AccordionContainerTitle
            isFirst={key === 0}
            isLast={key === options.length - 1}
            isFocused={selected === key}
            onClick={() => showAccordion(key)}>
            <AccordionTitle>{data.title}</AccordionTitle>
            <AccordionIconContainer isOpen={selected === key}>
              <BaSeIcon
                size={28}
                description="Icone para abrir o acordão"
                color="#617385"
                name={'angle-down'} />
            </AccordionIconContainer>
          </AccordionContainerTitle>
          <AccordionContainerContent
            isLast={key === options.length - 1}
            isOpen={selected === key}
          >
            {data.content}
          </AccordionContainerContent>
        </React.Fragment>
      ))}
    </>
  );
};
