import { KeycloakInstance } from 'keycloak-js';
import * as React from 'react';
import { BaSeConfig } from '../../config/config';
import { useOutsideEvent } from '../../hooks/outside-event';
import { BaSeTheme } from '../../theme';
import {
  createButtonFocusColor,
  createColor,
} from '../../utils/color-transformation/calculate-pattern';
import { nameAbbreviation, urlSanitizer } from '../../utils/string-utils';
import { BaSeIcon } from '../image/icon';
import { BaSeText } from '../typography/text/text';
import { AccountProfileProps } from './account-profile-props';
import { AvatarOrIconAndNameAndEmail } from './avatar-or-icon-and-name-and-email';
import {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_FOCUS_BOX_SHADOW_COLOR,
  BUTTON_HEIGHT,
  BUTTON_HOVER_BACKGROUND_COLOR,
  BUTTON_ICON_COLOR,
  BUTTON_ICON_SIZE,
  BUTTON_TEXT_COLOR,
  MENU_BACKGROUND_COLOR,
  MENU_BORDER_COLOR,
  MENU_COLOR,
  MENU_EMAIL_COLOR,
  MENU_ICON_SHADOW,
  MENU_ICON_SIZE,
  MENU_LINK_COLOR,
  MENU_NAME_COLOR,
  MENU_SEPARATOR_COLOR,
  MENU_SHADOW,
  TRANSITION,
} from './constants';
import { ContainerButtonStyled } from './container-button-styled';
import { ContainerMenuStyled } from './container-menu-styled';
import { LinkStyled } from './link-styled';
import { WrapperStyled } from './wrapper-styled';

const focusHoverConfig = {
  opacity: 0.2,
  l: 0.7,
};

function makeAuthUrl(authServerUrl: string, realm: string): string {
  return urlSanitizer(`${authServerUrl}/realms/${realm}`);
}

export const BaSeAccountProfile: React.FC<AccountProfileProps> = ({
  clientName,
  clientUri,
  clientId,
  avatarUrl,
  cpeUrl,
  authServerUrl,
  authRealm,
  authenticated = false,
  keycloak = null,
  userName = '',
  userEmail = '',
  userId = '',
  contextLinks = [],
  buttonCollapsed = false,
  foregroundColor = BaSeTheme.colors.defaultColors.white,
  backgroundColor: iconBackColor = BaSeTheme.colors.institucionais.azulSebrae36,
}: AccountProfileProps) => {
  if (!Array.isArray(contextLinks)) {
    contextLinks = [];
  }

  authServerUrl = urlSanitizer(authServerUrl as string);
  avatarUrl = avatarUrl
    ? urlSanitizer(avatarUrl as string)
    : BaSeConfig.ameiConfig?.[authServerUrl as string]?.avatarUrl;
  cpeUrl = cpeUrl
    ? urlSanitizer(cpeUrl as string)
    : BaSeConfig.ameiConfig?.[authServerUrl as string]?.cpeUrl;

  function keycloakCopyPaste(kc: null | KeycloakInstance): void {
    if (kc) {
      authenticated = kc.authenticated ?? false;
      authServerUrl = urlSanitizer(kc.authServerUrl as string);
      authRealm = kc.realm;
      clientId = kc.clientId;
      userName = kc.tokenParsed?.['name'] as string;
      userEmail = kc.tokenParsed?.['email'] as string;
      avatarUrl = BaSeConfig.ameiConfig?.[authServerUrl as string]?.avatarUrl;
      cpeUrl = BaSeConfig.ameiConfig?.[authServerUrl as string]?.cpeUrl;
    }
  }

  keycloakCopyPaste(keycloak);

  clientUri = clientUri
    ? urlSanitizer(clientUri as string)
    : window.location.href;

  const authLinks = [
    {
      label: 'Meu perfil',
      url:
        `${makeAuthUrl(
          authServerUrl as string,
          authRealm as string,
        )}/account/` +
        `?referrer=${encodeURIComponent(clientId as string)}` +
        `&referrer_url=${encodeURIComponent(clientUri as string)}` +
        (!!clientName
          ? `&referrer_name=${encodeURIComponent(clientName as string)}`
          : ''),
    },
    {
      label: 'Minha empresa',
      url:
        cpeUrl +
        `?voltar=${encodeURIComponent(clientUri as string)}` +
        (!!clientName
          ? `&sistema=${encodeURIComponent(clientName as string)}`
          : ''),
    },
    {
      label: 'Alterar senha',
      url:
        `${makeAuthUrl(
          authServerUrl as string,
          authRealm as string,
        )}/account/password` +
        `?referrer=${encodeURIComponent(clientId as string)}` +
        `&referrer_url=${encodeURIComponent(clientUri as string)}` +
        (!!clientName
          ? `&referrer_name=${encodeURIComponent(clientName as string)}`
          : ''),
    },
    {
      label: 'Vincular redes sociais',
      url:
        `${makeAuthUrl(
          authServerUrl as string,
          authRealm as string,
        )}/account/identity` +
        `?referrer=${encodeURIComponent(clientId as string)}` +
        `&referrer_url=${encodeURIComponent(clientUri as string)}` +
        (!!clientName
          ? `&referrer_name=${encodeURIComponent(clientName as string)}`
          : ''),
    },
  ];

  const [menuOpened, setMenuOpened] = React.useState(false);

  const wrapperRef = React.useRef<HTMLDivElement>(null);

  useOutsideEvent<HTMLDivElement>(wrapperRef, setMenuOpened);

  React.useEffect(() => keycloakCopyPaste(keycloak), [keycloak]);

  function isAuthenticated(): boolean {
    return keycloak?.authenticated ?? (authenticated as boolean);
  }

  function getLoggedFullName(): string {
    return (
      keycloak?.tokenParsed?.['name'] ??
      keycloak?.tokenParsed?.['given_name'] ??
      keycloak?.tokenParsed?.['preferred_username'] ??
      userName ??
      'Minha Conta'
    );
  }

  function getLoggedName(): string {
    return getLoggedFullName()?.split(' ')?.[0] ?? '';
  }

  function getLoggedEmail(): string {
    return (keycloak ? keycloak?.tokenParsed?.['email'] : userEmail) ?? '';
  }

  function getAvatar(): string {
    return `${avatarUrl}/${
      (keycloak ? keycloak?.tokenParsed?.sub : userId) ?? 'O.o'
    }`;
  }

  function authLogin(): void {
    if (keycloak) {
      if (clientUri) {
        keycloak.login?.({ redirectUri: clientUri });
        return;
      }
      keycloak.login?.();
      return;
    }
    window.location.href =
      `${makeAuthUrl(
        authServerUrl as string,
        authRealm as string,
      )}/protocol/openid-connect/auth` +
      `?client_id=${encodeURIComponent(
        clientId as string,
      )}&response_type=code` +
      `&redirect_uri=${encodeURIComponent(clientUri as string)}`;
    // `${authUrl}/protocol/openid-connect/login?redirect_uri=${encodeURIComponent(clientUri as string)}`;
  }

  function authLogout(): void {
    if (keycloak) {
      if (clientUri) {
        keycloak.logout?.({ redirectUri: clientUri });
        return;
      }
      keycloak.logout?.();
      return;
    }
    window.location.href =
      `${makeAuthUrl(
        authServerUrl as string,
        authRealm as string,
      )}/protocol/openid-connect/logout?` +
      `redirect_uri=${encodeURIComponent(clientUri as string)}`;
  }

  if (isAuthenticated()) {
    return (
      <WrapperStyled ref={wrapperRef} wrapperHeight={BUTTON_HEIGHT}>
        <ContainerButtonStyled
          type="button"
          onClick={() => setMenuOpened((opened) => !opened)}
          hoverBackgroundColor={
            createColor(foregroundColor, focusHoverConfig) ??
            BUTTON_HOVER_BACKGROUND_COLOR
          }
          focusBoxShadowColor={
            createButtonFocusColor(foregroundColor) ??
            BUTTON_FOCUS_BOX_SHADOW_COLOR
          }
          backgroundColor={BUTTON_BACKGROUND_COLOR}
          iconSize={BUTTON_ICON_SIZE}
          textColor={foregroundColor}
          iconColor={iconBackColor}
          transition={TRANSITION}
        >
          <AvatarOrIconAndNameAndEmail
            avatar={getAvatar()}
            icon={nameAbbreviation(getLoggedFullName())}
            name={buttonCollapsed ? '' : getLoggedName()}
          />
        </ContainerButtonStyled>
        <ContainerMenuStyled
          className={menuOpened ? '' : 'BaSe--account-profile-hidden'}
          role="menu"
          aria-label="Menu com as opções do Autenticador"
          wrapperHeight={BUTTON_HEIGHT}
          backgroundColor={MENU_BACKGROUND_COLOR}
          borderColor={MENU_BORDER_COLOR}
          textColor={MENU_COLOR}
          textEmailColor={MENU_EMAIL_COLOR}
          iconShadow={MENU_ICON_SHADOW}
          iconSize={MENU_ICON_SIZE}
          textNameColor={MENU_NAME_COLOR}
          separatorColor={MENU_SEPARATOR_COLOR}
          menuShadow={MENU_SHADOW}
          transition={TRANSITION}
        >
          <ul className="BaSe--account-profile-menu-wrapper">
            <li
              role="menuitem"
              className="BaSe--account-profile-avatar-and-name-email"
            >
              <AvatarOrIconAndNameAndEmail
                avatar={getAvatar()}
                icon={nameAbbreviation(getLoggedFullName())}
                name={getLoggedFullName()}
                email={getLoggedEmail()}
                onClose={() => setMenuOpened(false)}
              />
            </li>

            {contextLinks?.map((link, index) => (
              <li
                role="menuitem"
                className="BaSe--account-profile-context-link"
                key={index}
              >
                <LinkStyled
                  textColor={MENU_LINK_COLOR}
                  as="a"
                  href={link.url}
                  title={link.label}
                >
                  {link.label}
                </LinkStyled>
              </li>
            ))}

            <li
              className="BaSe--account-profile-separator"
              aria-label="Espaço entre os links"
            />

            {authLinks.map((link, index) => (
              <li
                role="menuitem"
                className="BaSe--account-profile-auth-link"
                key={index}
              >
                <LinkStyled
                  textColor={MENU_LINK_COLOR}
                  as="a"
                  href={link.url}
                  title={link.label}
                >
                  {link.label}
                </LinkStyled>
              </li>
            ))}

            <li role="menuitem" className="BaSe--account-profile-auth-link">
              <LinkStyled
                textColor={MENU_LINK_COLOR}
                as="button"
                type="button"
                onClick={authLogout}
                title="Sair"
              >
                Sair
              </LinkStyled>
            </li>
          </ul>
        </ContainerMenuStyled>
      </WrapperStyled>
    );
  }

  return (
    <ContainerButtonStyled
      type="button"
      onClick={authLogin}
      hoverBackgroundColor={
        createColor(foregroundColor, focusHoverConfig) ??
        BUTTON_HOVER_BACKGROUND_COLOR
      }
      focusBoxShadowColor={
        createButtonFocusColor(foregroundColor) ?? BUTTON_FOCUS_BOX_SHADOW_COLOR
      }
      backgroundColor={BUTTON_BACKGROUND_COLOR}
      iconSize={BUTTON_ICON_SIZE}
      textColor={BUTTON_TEXT_COLOR}
      iconColor={BUTTON_ICON_COLOR}
      transition={TRANSITION}
    >
      <BaSeIcon
        size={BUTTON_ICON_SIZE}
        name="user"
        color={foregroundColor}
        description="Imagem de uma pessoa, indicando a área para fazer login"
      />
      <BaSeText
        isBold={true}
        isItalic={false}
        isThin={false}
        color={foregroundColor}
      >
        {buttonCollapsed ? '' : 'Entrar'}
      </BaSeText>
    </ContainerButtonStyled>
  );
};
