// import { string } from 'prop-types';
import * as React from 'react';
import { BaSeTheme } from '../../../theme';
import { BaSeShapeButton } from '../../button/shape-button/shape-button';
import {
  WrapperList,
  WrapperItem,
  Wrapperbtn,
} from './list-styled';

type ListValueId = number | string;

interface ListValue {
  id: ListValueId;
  label: string;
}

interface ActionsType {
  size: 'medium' | 'small' | 'big';
  sizeIcon: 'medium' | 'small' | 'big';
  ActionisDisabled?: boolean;
  nameIcon: string;
  color: string;
  listAction: (value: string) => void;
}

export interface ListType {
  direction: 'column' | 'row' | 'column-reverse' | 'row-reverse';
  columnNum?: number | string;
  maxHeight?: number | string;
  gap?: number | string;
  listActions: ActionsType[];
  valuesList: ListValue[];
}

export const BaSeList: React.FC<ListType> = ({
  direction = 'column',
  maxHeight = '',
  gap = '10px',
  valuesList = [],
  columnNum = 1,
  listActions = [{
    size: 'medium',
    sizeIcon: 'medium',
    ActionisDisabled: false,
    nameIcon: 'trash-alt',
    color: BaSeTheme.colors.institucionais.azulSebrae36,
    listAction: () => { },
  }],
}) => {
  return (
    <WrapperList columnNum={columnNum} gap={gap} maxHeight={maxHeight}>
      {valuesList.map((item) => {
        return <WrapperItem key={item.id} direction={direction}>
          <Wrapperbtn >
            {(listActions as any[]).map((action, index) => (
              <React.Fragment key={index}>
                <BaSeShapeButton
                  value=""
                  onClick={() => action.listAction(item)}
                  type="tertiary"
                  nameIcon={action.nameIcon}
                  size={action.size}
                  sizeIcon={action.sizeIcon}
                  buttonType="button"
                  isDisabled={action.ActionisDisabled}
                  color={action.color}
                />
              </React.Fragment>
            ))}
          </Wrapperbtn>
          {item.label}
        </WrapperItem>;
    })}
    </WrapperList>
  );
};
