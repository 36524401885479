import * as React from 'react';
import { useOutsideEvent } from '../../hooks/outside-event';
import { BaSeTheme } from '../../theme';
import { normalize } from '../../utils/string-utils';
import { BaSeHelperText } from '../helpers/helper-text/helper-text';
import {
  BaSePopupButton,
  HelperButtonInterface,
} from '../helpers/popup-button/popup-button';
import { BaSeIcon } from '../image/icon';
import { BaSeText } from '../typography/text/text';
import {
  ButtonInfoContainerHelper,
  HideTopOfWrapperOptions,
  MoreInfoContainer,
  SelectInput,
  SelectSizeType,
  StyledLabel,
  StyledSubLabel,
  WrapperHelper,
  WrapperIcon,
  WrapperInputlabel,
  WrapperOption,
  WrapperOptions,
  WrapperSelect,
} from './select-styled';
import { getNextHash, idGenerator } from '../../utils/id-generator';

const idSequence = idGenerator();

export type ValueId = number | string | boolean;

export interface SelectValue {
  id: ValueId;
  label: string;
}
export interface SelectProps {
  selectedValueId: ValueId;
  values: SelectValue[];
  label?: string;
  showHelpButton?: string;
  size?: SelectSizeType;
  emptyValueLabel?: string;
  searchable?: boolean;
  subLabel?: string;
  width?: number | string;
  helpButtonProps?: HelperButtonInterface;
  isDisabled?: boolean;
  moreInfoLabel?: string;
  moreInfoDetails?: string;
  complement?: string;
  hasError?: boolean;
  removeEmptyValue?: boolean;
  onChange: (selectedValue: SelectValue) => void;
}

function filteredEmptyValue<T extends { label: string }>(
  list: T[],
  removeEmptyValue: boolean,
  emptyValueLabel: string,
): T[] {
  if (removeEmptyValue) {
    return list.filter((item) => item.label !== emptyValueLabel);
  }
  return list;
}

export const BaSeSelect: React.FC<SelectProps> = ({
  complement = '',
  hasError = false,
  label = '',
  searchable = false,
  selectedValueId = null,
  subLabel = '',
  showHelpButton = false,
  values = [],
  width = null,
  size = 'medium',
  emptyValueLabel = 'Selecione',
  isDisabled = false,
  helpButtonProps = {},
  moreInfoLabel = '',
  moreInfoDetails = '',
  removeEmptyValue = false,
  onChange = () => {},
}) => {
  if (!Array.isArray(values)) {
    values = [];
  }
  const id = getNextHash(idSequence);

  const emptyValue = React.useMemo(
    () => ({ label: emptyValueLabel, id: 0 }),
    [],
  );

  const initialValue = React.useMemo(
    () => values.filter((s) => s.id === selectedValueId),
    [values],
  );

  const originalValues = React.useMemo(
    () =>
      [
        emptyValue,
        ...filteredEmptyValue(values, removeEmptyValue, emptyValueLabel),
      ].map((item) => ({
        ...item,
        ref: React.createRef<HTMLLIElement>(),
      })),
    [values],
  );
  const initialValueFiltered = React.useMemo(
    () => initialValue?.[0] ?? originalValues[0],
    [initialValue, originalValues],
  );

  const [isOpen, setIsOpen] = React.useState(false);
  const [allValues, setAllValues] = React.useState<
    (SelectValue & { ref?: React.RefObject<HTMLLIElement> })[]
  >([]);
  const [inputValue, setInputValue] = React.useState(
    initialValueFiltered.label,
  );
  const [idSelected, setIdSelected] = React.useState(initialValueFiltered.id);

  const selectInputRef = React.useRef<HTMLInputElement>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  useOutsideEvent<HTMLDivElement>(wrapperRef, setIsOpen);

  const updateValue = React.useCallback(
    (selected: any, isNotInputEvent = false, noClose = false) => {
      if (isNotInputEvent) {
        if (!noClose) {
          setIsOpen(false);
        }
        setAllValues(originalValues);
        setIdSelected(selected.id);
        setInputValue(selected.label);
        const valueToEmit = { ...selected };
        delete valueToEmit.ref;
        onChange(valueToEmit);
      } else {
        const valueFromTarget = selected.target.value;
        const filteredValues = filteredEmptyValue(
          originalValues,
          removeEmptyValue,
          emptyValueLabel,
        ).filter((item) =>
          normalize(item.label).includes(normalize(valueFromTarget)),
        );

        setInputValue(valueFromTarget);
        setAllValues(filteredValues);
      }
    },
    [],
  );

  const onFocusInput = React.useCallback(() => setIsOpen(!isDisabled && true), [
    isDisabled,
  ]);

  const onKeyDownWrapper = React.useCallback(
    (key) => {
      if (['ArrowUp', 'ArrowDown'].includes(key)) {
        const focusedIndex = allValues.findIndex((option) =>
          option?.ref?.current?.classList?.contains?.('focus'),
        );
        const activeIndex = allValues.findIndex(
          (option) => option.id === idSelected,
        );
        const actualIndex = focusedIndex > -1 ? focusedIndex : activeIndex;
        const nextIndex = actualIndex + (key === 'ArrowDown' ? 1 : -1);
        const nextItem = allValues?.[nextIndex];
        nextItem?.ref?.current?.focus();
      }
    },
    [idSelected, allValues],
  );

  const handleHelperColor = React.useCallback(
    () =>
      hasError
        ? BaSeTheme.colors.feedbackInterface.erro35
        : BaSeTheme.colors.institucionais.cinzaSebrae45,
    [hasError],
  );

  const onClose = React.useCallback(() => {
    allValues.forEach(({ ref }) => ref?.current?.classList?.remove?.('focus'));
    const selectedValue = originalValues.find((item) => item.id === idSelected);
    setInputValue((selectedValue ?? emptyValue).label);
  }, [idSelected]);

  React.useEffect(() => {
    const selectedValue = originalValues.find(
      (item) => item.id === selectedValueId,
    );
    updateValue(selectedValue ?? emptyValue, true, true);
  }, [selectedValueId]);

  React.useEffect(() => {
    setAllValues(originalValues);
  }, [originalValues]);

  React.useEffect(() => {
    if (isDisabled) {
      return;
    }
    if (isOpen) {
      selectInputRef?.current?.focus();
      setInputValue('');
    } else {
      onClose();
    }
  }, [isOpen]);

  return (
    <WrapperSelect
      ref={wrapperRef}
      isDisabled={isDisabled}
      width={width}
      onKeyDown={(event) => onKeyDownWrapper(event.key)}
    >
      <WrapperInputlabel>
        <StyledLabel
          fontSize={size === 'small' ? '13px' : '16px'}
          lineHeight={size === 'small' ? '16px' : '23px'}
          htmlFor={id}
        >
          {label}
        </StyledLabel>
        <StyledSubLabel htmlFor={id}>{subLabel}</StyledSubLabel>
        {showHelpButton && (
          <ButtonInfoContainerHelper>
            <BaSePopupButton {...helpButtonProps} />
          </ButtonInfoContainerHelper>
        )}
      </WrapperInputlabel>
      <WrapperIcon
        hasLabel={!!label}
        vSize={size}
        isOpen={isOpen}
        searchable={searchable}
      >
        <BaSeIcon
          description={`${isOpen ? 'Fecha' : 'Abre'} as opções`}
          name="arrow-head-down"
          size={16}
          onClick={
            isDisabled
              ? undefined
              : () => setIsOpen((actualState) => !actualState)
          }
          color={
            isDisabled
              ? BaSeTheme.colors.institucionais.cinzaSebrae75
              : BaSeTheme.colors.feedbackInterface.azulSebrae36
          }
        />
        {searchable && inputValue && isOpen && (
          <BaSeIcon
            description="Limpar pesquisa"
            name="close"
            size={16}
            onClick={
              isDisabled ? undefined : () => updateValue(emptyValue, true, true)
            }
            color={
              isDisabled
                ? BaSeTheme.colors.institucionais.cinzaSebrae75
                : BaSeTheme.colors.feedbackInterface.azulSebrae36
            }
          />
        )}
      </WrapperIcon>
      <SelectInput
        id={id}
        ref={selectInputRef}
        hasSelectedOption={inputValue !== emptyValueLabel}
        disabled={isDisabled}
        readOnly={!searchable}
        vSize={size}
        value={inputValue}
        isOpen={isOpen}
        hasError={hasError}
        onChange={(newValue) => updateValue(newValue)}
        onFocus={onFocusInput}
      />
      {allValues.length > 0 && (
        <>
          {isOpen && <HideTopOfWrapperOptions />}
          <WrapperOptions hasError={hasError} isOpen={isOpen} role="menu">
            {filteredEmptyValue(
              allValues,
              removeEmptyValue,
              emptyValueLabel,
            ).map((option) => (
              <WrapperOption
                ref={option.ref}
                key={option.id.toString()}
                isActive={idSelected === option.id}
                role="menuitem"
                tabIndex={-1}
                onKeyDown={(event) =>
                  event.key === 'Enter' && updateValue(option, true)
                }
                onClick={() => updateValue(option, true)}
                onFocus={() => option.ref?.current?.classList.add('focus')}
                onBlur={() => option.ref?.current?.classList.remove('focus')}
              >
                <BaSeText
                  color={
                    idSelected === option.id
                      ? BaSeTheme.colors.defaultColors.white
                      : BaSeTheme.colors.institucionais.cinzaSebrae30
                  }
                >
                  {option.label}
                </BaSeText>
              </WrapperOption>
            ))}
          </WrapperOptions>
        </>
      )}
      {!!complement && (
        <WrapperHelper>
          <BaSeHelperText
            isItalic={true}
            size="medium"
            color={handleHelperColor()}
            label={complement}
          />
        </WrapperHelper>
      )}
      {!!moreInfoLabel && (
        <MoreInfoContainer>
          <BaSeHelperText
            size="small"
            label={moreInfoLabel}
            details={moreInfoDetails}
          />
        </MoreInfoContainer>
      )}
    </WrapperSelect>
  );
};
