import * as React from 'react';
import { useNextHashId } from '../../hooks/next-id';
import { idGenerator } from '../../utils/id-generator';
import {
  mappedSingleColors,
  spinnerCustomMap,
  spinnerMap,
} from './spinner-map';
import {
  GeometrySpinnerGradient,
  SvgSpinner,
  UseSpinnerAnimated,
} from './styled';

export interface LoadingSpinnerProps {
  diameter: number;
  description: string | false;
  color?: string;
  type?: string;
  disabled?: boolean;
}

const validateType = (type: string) => {
  if (type === 'primary' || type === 'secondary' || type === 'tertiary') {
    return type;
  }
  return 'primary';
};

const idSequence = idGenerator();

export const BaSeLoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  diameter = 64,
  description = 'Carregando… Aguarde.',
  color,
  type = 'tertiary',
  disabled = false,
}) => {
  const uuid = useNextHashId(idSequence);

  // TODO refatorar operadores ternarios para algo mais simples
  const mappedColorValues = disabled
    ? spinnerMap.disabled
    : color === 'negative'
    ? spinnerMap[validateType(type)].negative
    : !color || color === 'default'
    ? spinnerMap[validateType(type)]
    : color in mappedSingleColors
    ? spinnerCustomMap(mappedSingleColors[color], type === 'primary')
    : spinnerCustomMap(color, type === 'primary');

  const { darkColor, lightColor, mediumColor } = mappedColorValues;

  const halfDiameter = (diameter / 2).toFixed(1);
  const quarterDiameter = (diameter / 4).toFixed(1);

  return (
    <SvgSpinner
      role="img"
      className="BaSe--loading-spinner"
      aria-label={description || undefined}
      aria-hidden={!description ? 'true' : undefined}
      viewBox={`-${halfDiameter} -${quarterDiameter} ${diameter} ${halfDiameter}`}
      diameter={diameter}
    >
      <linearGradient id={`base-loading-spinner-${uuid}-gradient-1`}>
        <stop stopColor={mediumColor} />
        <stop stopColor={darkColor} offset="1" />
      </linearGradient>

      <linearGradient id={`base-loading-spinner-${uuid}-gradient-2`}>
        <stop stopColor={lightColor} />
        <stop stopColor={mediumColor} offset="1" />
      </linearGradient>

      <circle id={`base-loading-spinner-${uuid}-circle`} r="50%" />

      <mask id={`base-loading-spinner-${uuid}-mask`}>
        <circle />
        <UseSpinnerAnimated
          xlinkHref={`#base-loading-spinner-${uuid}-circle`}
        />
      </mask>

      <GeometrySpinnerGradient rotation="310deg" index="2" uuid={uuid}>
        <use xlinkHref={`#base-loading-spinner-${uuid}-circle`} />
      </GeometrySpinnerGradient>

      <GeometrySpinnerGradient rotation="80deg" index="1" uuid={uuid}>
        <use xlinkHref={`#base-loading-spinner-${uuid}-circle`} />
      </GeometrySpinnerGradient>
    </SvgSpinner>
  );
};
