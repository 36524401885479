import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';

interface AccordionContainerTitleProps {
  isFocused: boolean;
  isFirst: boolean;
  isLast: boolean;
}

interface AccordionContainerContentProps {
  isOpen: boolean;
  isLast: boolean;
}

interface AccordionIconContainerProps {
  isOpen: boolean;
}

export const AccordionContainer = styled.div``;

export const TransitionCss = css`
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
`;

export const AccordionContainerTitle = styled.div<AccordionContainerTitleProps>`
  padding: 12px 16px;
  background: ${BaSeTheme.colors.defaultColors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${BaSeTheme.colors.institucionais.cinzaSebrae97};
  box-sizing: border-box;
  box-shadow: ${BaSeTheme.shadows.profundidade2};

  ${(props) =>
    props.isFirst &&
    css`
      border-radius: 4px 4px 0px 0px;
    `}
  ${(props) =>
    props.isLast &&
    !props.isFocused &&
    css`
      border-radius: 0px 0px 4px 4px;
    `}
`;

export const AccordionContainerContent = styled.div<AccordionContainerContentProps>`
  padding: ${(props) => (props.isOpen ? '16px' : '0 16px')};
  max-height: ${(props) => (props.isOpen ? '100vh' : '0px')};
  overflow: hidden;
  background: ${BaSeTheme.colors.institucionais.cinzaSebrae97};
  box-sizing: border-box;
  box-shadow: ${BaSeTheme.shadows.profundidade2};
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 23px;
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae30};
  ${(props) =>
    props.isLast &&
    css`
      border-radius: 0px 0px 4px 4px;
    `}
  ${TransitionCss};
`;

export const AccordionTitle = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 28px;
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae45};
`;

export const AccordionIconContainer = styled.div<AccordionIconContainerProps>`
  ${TransitionCss};
  ${(props) =>
    props.isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;
