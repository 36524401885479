import * as React from 'react';
import {
  RadioMarker,
  ContainerRadio,
  InputRadio,
  LabelRadio,
  RadioWrapper,
} from './radio-styled';

export type RadioId = number | string;

export interface RadioProps {
  label: string;
  id: RadioId;
  size: 'medium' | 'small';
  checked: boolean;
  onChange: (id: RadioId) => void;
  name: string;
  disabled: boolean;
  labelWidth: string;
}

export const BaSeRadio: React.FC<RadioProps> = ({
  id,
  name,
  label = '',
  labelWidth = 'auto',
  size = 'medium',
  checked = false,
  onChange = () => {},
  disabled = false,
}) => {
  if (!name) {
    throw Error('Atributo "name" é obrigatório no BaSeRadio');
  }
  if (!id) {
    throw Error('Atributo "id" é obrigatório no BaSeRadio');
  }

  const [hover, setHover] = React.useState(false);

  const changeValue = () => onChange(id);

  return (
    <RadioWrapper className="BaSe--radio">
      <ContainerRadio
        className={`BaSe--radio-marker-container ${hover ? 'hover' : ''}`}
        checked={checked}
        disabled={disabled}
      >
        <InputRadio
          className="BaSe--radio-input"
          onChange={changeValue}
          name={name}
          value={id}
          checked={checked}
          aria-checked={checked}
          disabled={disabled}
        />
        <RadioMarker
          className="BaSe--radio-marker"
          checked={checked}
          disabled={disabled}
        />
      </ContainerRadio>
      <LabelRadio
        className="BaSe--radio-label"
        disabled={disabled}
        size={size}
        width={labelWidth}
        onClick={() => !disabled && onChange(id)}
        onPointerEnter={() => setHover(true)}
        onPointerLeave={() => setHover(false)}
      >
        {label}
      </LabelRadio>
    </RadioWrapper>
  );
};
