import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../../theme';

interface StyledButtonProps {
  backgroundColor: string;
  backgroundOnHover: string;
  width: string;
  minWidth: number;
  border: string;
  justifyContent: string;
  boxShadowFocus: string;
  padding: string;
  disabled: boolean;
  type: string;
  hoverColor?: string;
  hideBorder?: boolean;
  boxShadow?: boolean;
}

interface ButtonSize {
  size: string;
}

export const StyledButton = styled.button<StyledButtonProps>`
  ${({
  backgroundColor,
  width,
  border,
  padding,
  minWidth,
  justifyContent,
  backgroundOnHover,
  boxShadowFocus,
  hideBorder,
  boxShadow,

}) => css`
    position: relative;
    background-color: ${backgroundColor};
    ${width && `width: ${width}px;`}
    min-width: ${minWidth}px;
    border-radius: 4px;
    border: ${border};
    padding: ${padding};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: ${justifyContent};
    ${boxShadow && `box-shadow: 0px 2px 4px ${BaSeTheme.colors.defaultColors.shadowColor};`}
    &:hover {
      cursor: pointer;
      background-color: ${backgroundOnHover};
    }
    &:focus {
      outline: none;
      box-shadow: ${boxShadow && `0px 5px 4px ${BaSeTheme.colors.defaultColors.shadowColor},`} ${boxShadowFocus};
      ${hideBorder && `border:2px solid ${BaSeTheme.colors.defaultColors.transparent};`}
    }
    &:disabled {
      cursor: not-allowed;
    }
  `};
`;

export const SpinnerContainer = styled.div<ButtonSize>`
  ${({ size }) => css`
    margin-right: ${size === 'big' ? 12 : 4}px;
    display: grid;
    place-items: center;
  `}
`;
