import * as React from 'react';
import { BaSeTheme } from '../../theme';
import { BaSeTooltip } from '../alerts/tooltip/tooltip';
import { BaSeIcon } from '../image/icon';
import { BaSeSmall1 } from '../typography/small/small1';
import { BaSeStepContent } from './step-content';
import { StepProps } from './stepper';
import {
  FadableItem,
  StepCircleHeader,
  StepItem,
  StepContainer,
  StepOrientationProps,
  StepColorProps,
  StepText,
  TypeTransitionProps,
  StepperContent,
  StepContentTransition,
} from './stepper-styled';

export interface StepHeaderProps extends StepColorProps, StepOrientationProps {
  steps: StepProps[];
  title: string;
  tooltip?: 'none' | 'top' | 'bottom' | 'left' | 'right';
  currentStep: number;
  isDisabled: boolean;
  transition: TypeTransitionProps;
  onActived: () => void;
  onStepChange: (step: number) => void;
}

export function getContent(
  orientation: 'horizontal' | 'vertical' | undefined,
  transition: TypeTransitionProps,
  steps: StepProps[],
  currentStep: number,
  onStepChange: (step: number) => void,
):
  | string
  | number
  | boolean
  | {}
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | React.ReactNodeArray
  | React.ReactPortal
  | null
  | undefined {
  return (
    <StepperContent
      as="section"
      className="BaSe--stepper-content"
      orientation={orientation}
    >
      <StepContentTransition transition={transition}>
        <BaSeStepContent
          content={steps[currentStep - 1].content}
          canGotoNext={
            currentStep < steps.length &&
            (!steps?.[currentStep]?.isDisabled ?? true)
          }
          canGotoPrev={
            currentStep > 1 && (!steps?.[currentStep - 2]?.isDisabled ?? true)
          }
          gotoStep={onStepChange}
          onNext={() => onStepChange(currentStep + 1)}
          onPrev={() => onStepChange(currentStep - 1)}
        />
      </StepContentTransition>
    </StepperContent>
  );
}

export const BaSeStepHeader: React.FC<StepHeaderProps> = ({
  steps,
  transition,
  title,
  tooltip = 'none',
  currentStep,
  isActive,
  isCompleted,
  activeColor = BaSeTheme.colors.institucionais.azulSebrae36,
  completedColor = BaSeTheme.colors.feedbackInterface.sucesso45,
  incompletedColor = BaSeTheme.colors.institucionais.cinzaSebrae45,
  orientation,
  isDisabled = false,
  onActived,
  onStepChange,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
  const itemRef = React.useRef<HTMLButtonElement>(null);

  const renderText = React.useCallback(
    (text) => (
      <StepText
        isActive={isActive}
        isCompleted={isCompleted}
        activeColor={activeColor}
        completedColor={completedColor}
        incompletedColor={incompletedColor}
        orientation={orientation}
      >
        <BaSeSmall1>{text}</BaSeSmall1>
      </StepText>
    ),
    [
      isActive,
      isCompleted,
      orientation,
      activeColor,
      completedColor,
      incompletedColor,
    ],
  );

  return (
    <StepContainer
      className="BaSe--stepper-header"
      isActive={isActive}
      isCompleted={isCompleted}
      activeColor={activeColor}
      completedColor={completedColor}
      incompletedColor={incompletedColor}
      orientation={orientation}
    >
      <StepItem
        ref={itemRef}
        isActive={isActive}
        isCompleted={isCompleted}
        activeColor={activeColor}
        completedColor={completedColor}
        incompletedColor={incompletedColor}
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
        disabled={isDisabled}
        onClick={() => onActived()}
        orientation={orientation}
      >
        <StepCircleHeader
          activeColor={activeColor}
          completedColor={completedColor}
          incompletedColor={incompletedColor}
          isActive={isActive}
          isCompleted={isCompleted}
        >
          {!isCompleted ? (
            renderText(currentStep)
          ) : (
            <FadableItem>
              <BaSeIcon
                name="check"
                description={`Etapa ${currentStep} concluida`}
                color={completedColor}
                size={16}
              />
            </FadableItem>
          )}
        </StepCircleHeader>

        {renderText(title)}
      </StepItem>

      {tooltip !== 'none' && (
        <BaSeTooltip
          buddyRef={itemRef}
          color="outlined"
          direction={tooltip}
          message={title}
          open={isTooltipOpen}
        />
      )}

      {isActive &&
        orientation === 'vertical' &&
        getContent(orientation, transition, steps, currentStep, onStepChange)}
    </StepContainer>
  );
};
