import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';
import { BaSeScrollbarCss } from '../../theme/scrollbar';

export type SelectSizeType = 'small' | 'medium' | 'big';

interface WrapperOptionsProps {
  hasError: boolean;
  isOpen: boolean;
}

interface SelectInputProps {
  vSize: SelectSizeType;
  hasError: boolean;
  isOpen: boolean;
  hasSelectedOption: boolean;
}

interface WrapperOptionProps {
  isActive: boolean;
}

interface WrapperSelectProps {
  width: number | string | null;
  isDisabled: boolean;
}

interface WrapperIconProps {
  vSize: SelectSizeType;
  isOpen: boolean;
  searchable: boolean;
  hasLabel: boolean;
}

interface StyledLabelProps {
  fontSize: string;
  lineHeight: string;
}

const getPixelsFromSize = (size: SelectSizeType, hasLabel: boolean) => {
  if (hasLabel) {
    return size === 'small' ? 28 : size === 'medium' ? 38 : 43;
  } else {
    return size === 'small' ? 11 : size === 'medium' ? 15 : 20;
  }
};

export const WrapperSelect = styled.div.attrs({
  className: 'BaSe--select',
})<WrapperSelectProps>`
  position: relative;
  width: ${({ width }) =>
    typeof width === 'number' ? `${width}px` : width ?? 'auto'};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      * {
        cursor: not-allowed;
        user-select: none;
      }
    `}
`;

export const WrapperInputlabel = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  z-index: ${BaSeTheme.zIndexs.tens};
  background-color: transparent;
  position: relative;
  margin-bottom: 2px;
`;

export const HideTopOfWrapperOptions = styled.div`
  height: 8px;
  width: calc(100% - 2px);
  background: ${BaSeTheme.colors.defaultColors.white};
  border: none;
  box-sizing: border-box;
  position: relative;
  z-index: ${BaSeTheme.zIndexs.thousands + 1};
  margin: 0;
  padding: 0;
  margin-top: -8px;
  margin-left: 1px;
`;

export const WrapperOptions = styled.ul.attrs({
  className: 'BaSe--select-options',
})<WrapperOptionsProps>`
  display: flex;
  flex-direction: column;
  padding: 1px;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  position: absolute;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
  z-index: ${BaSeTheme.zIndexs.thousands};
  background-color: ${BaSeTheme.colors.defaultColors.white};
  border-top: none;
  border-bottom: 1px solid
    ${({ hasError }) =>
      hasError
        ? BaSeTheme.colors.feedbackInterface.erro35
        : BaSeTheme.colors.institucionais.azulSebrae36};
  border-left: 1px solid
    ${({ hasError }) =>
      hasError
        ? BaSeTheme.colors.feedbackInterface.erro35
        : BaSeTheme.colors.institucionais.azulSebrae36};
  border-right: 1px solid
    ${({ hasError }) =>
      hasError
        ? BaSeTheme.colors.feedbackInterface.erro35
        : BaSeTheme.colors.institucionais.azulSebrae36};
  border-radius: 0px 0px 4px 4px;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0px')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  margin-top: ${({ isOpen }) => (isOpen ? '-3px' : '0')};
  border-width: ${({ isOpen }) => (isOpen ? '1px' : '0')};
  box-shadow: ${({ isOpen, hasError }) =>
    isOpen
      ? hasError
        ? BaSeTheme.shadows.focusError
        : BaSeTheme.shadows.focusPrimary
      : 'none'};

  ${BaSeScrollbarCss}
`;

export const WrapperOption = styled.li.attrs({
  className: 'BaSe--select-option',
})<WrapperOptionProps>`
  padding-left: 12px;
  display: flex;
  align-items: center;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};
  will-change: background-color;
  padding: 5px;
  background-color: ${({ isActive }) =>
    isActive
      ? BaSeTheme.colors.institucionais.azulSebrae36
      : BaSeTheme.colors.defaultColors.white};
  cursor: pointer;

  & > * {
    will-change: font-weight;
  }

  &:focus,
  &:hover {
    outline: none;
    background-color: ${({ isActive }) =>
      isActive
        ? BaSeTheme.colors.institucionais.azulSebrae36
        : BaSeTheme.colors.institucionais.azulSebrae97};
  }

  &:focus {
    & > * {
      font-weight: bold;
    }
  }
`;

export const WrapperIcon = styled.div<WrapperIconProps>`
  position: absolute;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  will-change: transform;
  transform: ${({ isOpen }) => (!isOpen ? 'rotateX(0)' : 'rotateX(180deg)')};
  right: 9px;
  top: ${({ vSize, hasLabel }) => getPixelsFromSize(vSize, hasLabel)}px;
  width: 16px;
  height: 16px;
  & > * {
    transition: opacity ${BaSeTheme.transitions.durationNormal};
    will-change: transform, opacity;
    transform: scale(0);
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;

    &:last-child {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export const SelectInput = styled.input.attrs({
  className: 'BaSe--select-input',
})<SelectInputProps>`
  width: calc(100% - 46px);
  height: ${({ vSize }) =>
    vSize === 'small' ? 30 : vSize === 'medium' ? 38 : 46}px;

  border: 1px solid
    ${({ hasError, isOpen }) =>
      hasError
        ? BaSeTheme.colors.feedbackInterface.erro35
        : isOpen
        ? BaSeTheme.colors.institucionais.azulSebrae36
        : BaSeTheme.colors.institucionais.cinzaSebrae75};
  border-radius: 4px;
  font-size: 16px;
  padding: 0px 32px 0px 12px;

  text-overflow: ellipsis;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  ${({ isOpen, hasError }) =>
    isOpen &&
    css`
      box-shadow: ${hasError
        ? BaSeTheme.shadows.focusError
        : BaSeTheme.shadows.focusPrimary};
      color: ${hasError
        ? BaSeTheme.colors.feedbackInterface.erro35
        : BaSeTheme.colors.institucionais.cinzaSebrae45};

      &:focus {
        color: ${hasError
          ? BaSeTheme.colors.feedbackInterface.erro35
          : BaSeTheme.colors.institucionais.azulSebrae36};
      }
    `}

  ${({ hasError, hasSelectedOption }) =>
    hasError
      ? css`
          color: ${BaSeTheme.colors.feedbackInterface.erro35};
          border-color: ${BaSeTheme.colors.feedbackInterface.erro35};
        `
      : css`
          color: ${hasSelectedOption
            ? BaSeTheme.colors.institucionais.cinzaSebrae30
            : BaSeTheme.colors.institucionais.cinzaSebrae60};
          &:disabled {
            color: ${BaSeTheme.colors.institucionais.cinzaSebrae75};
            border-color: ${BaSeTheme.colors.institucionais.cinzaSebrae75};
            background-color: ${BaSeTheme.colors.institucionais.cinzaSebrae97};
          }
        `}

  &:read-only {
    cursor: default;
  }

  &:disabled {
    cursor: not-allowed;
    user-select: none;
  }
`;

export const StyledApoio = styled.span`
  font-size: 0.83em;
  color: ${BaSeTheme.colors.feedbackInterface.erro35};
  margin-top: 3px;
  line-height: 1.2em;
  font-family: ${BaSeTheme.typography.fontFamily};
  font-style: italic;
  font-weight: normal;
`;

export const StyledLabel = styled.label<StyledLabelProps>`
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae45};
  font-family: ${BaSeTheme.typography.fontFamily};
  font-style: normal;
  font-weight: normal;
`;

export const ButtonInfoContainer = styled.div`
  margin-left: 5.33px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSubLabel = styled.label`
  font-size: 16px;
  line-height: 23px;
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae60};
  font-family: ${BaSeTheme.typography.fontFamily};
  font-style: italic;
  font-weight: normal;
  margin-left: 4px;
`;

export const ButtonInfoContainerHelper = styled.div`
  margin-left: 5.33px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MoreInfoContainer = styled.div`
  padding-top: 4px;
`;

export const WrapperHelper = styled.div`
  padding-top: 2px;
`;
