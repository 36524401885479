import FocusTrap from 'focus-trap-react';
import * as React from 'react';
import {
  BaSeDialogModalInterface,
  BaSeDiologModalActionsInterface,
} from '../../contexts/dialog';
import { useNextCounterId } from '../../hooks/next-id';
import { BaSeTheme } from '../../theme';
import { idGenerator } from '../../utils/id-generator';
import { BaSeButton } from '../button/button/button';
import { BaSeShapeButton } from '../button/shape-button/shape-button';
import { BaSeHeading4 } from '../typography/heading/heading4';
import {
  ModalBody,
  ModalContainer,
  ModalDialog,
  ModalFooter,
  ModalHeader,
} from './modal-styled';

const ModalContent: React.FC<
  Pick<BaSeDialogModalInterface, 'content' | 'onClose'>
> = React.memo(({ content, onClose }) => <>{content?.(onClose)}</>);

const idSequence = idGenerator();

export const BaSeModal: React.FC<
  Omit<BaSeDialogModalInterface, 'id'>
> = React.memo(
  ({
    size,
    title,
    onClose,
    onCancel,
    content,
    actions = ['Fechar'],
    dismissable = true,
    titleColor,
    alignActions = 'start',
  }: Omit<BaSeDialogModalInterface, 'id'>) => {
    const ramdom = useNextCounterId(idSequence);

    const [dismiss, setDismiss] = React.useState<boolean>(false);
    const [actionSelected, setActionSelected] = React.useState<string>();

    React.useEffect(() => {
      if (dismiss) {
        return onCancel();
      }

      if (actionSelected) {
        return onClose(actionSelected);
      }
    }, [actionSelected, dismiss]);

    // tslint:disable-next-line: no-console
    React.useEffect(() => console.log('>>> modal'), []);
    // tslint:disable-next-line: no-console
    React.useEffect(() => console.log('>>> content'), [content]);
    // tslint:disable-next-line: no-console
    React.useEffect(() => console.log('>>> onClose'), [onClose]);

    const dismissing = React.useCallback(() => setDismiss(true), [setDismiss]);

    const handleClickAction = React.useCallback(
      (option: string | BaSeDiologModalActionsInterface) => {
        if (typeof option === 'string') {
          setActionSelected(option);
        } else {
          setActionSelected(option.action);
        }
      },
      [setActionSelected],
    );

    return (
      <ModalContainer className="BaSe--modal-container">
        <FocusTrap
          focusTrapOptions={{
            delayInitialFocus: true,
            escapeDeactivates: dismissable,
            clickOutsideDeactivates: dismissable,
            onDeactivate: dismissing,
          }}
        >
          <ModalDialog
            className="BaSe--modal-dialog"
            role="dialog"
            aria-labelledby={`BaSe--modal-title-${ramdom}`}
            aria-describedby={`BaSe--modal-description-${ramdom}`}
            aria-modal="true"
            size={size}
          >
            {(title || dismissable) && (
              <ModalHeader className="BaSe--modal-header">
                <span id={`BaSe--modal-title-${ramdom}`}>
                  {typeof title === 'string' ? (
                    <BaSeHeading4
                      color={
                        titleColor ??
                        BaSeTheme.colors.institucionais.cinzaSebrae45
                      }
                      isBold={true}
                    >
                      {title}
                    </BaSeHeading4>
                  ) : (
                    title()
                  )}
                </span>
                {dismissable && (
                  <BaSeShapeButton
                    buttonType="button"
                    nameIcon="close-big"
                    shape="circle"
                    size="small"
                    sizeIcon="small"
                    type="primary"
                    color="negative"
                    onClick={() => dismissing()}
                  />
                )}
              </ModalHeader>
            )}
            <ModalBody id={`BaSe--modal-description-${ramdom}`}>
              <ModalContent content={content} onClose={onClose} />
            </ModalBody>
            <ModalFooter
              className="BaSe--modal-footer"
              alignActions={alignActions}
            >
              {actions?.map((action, index) => (
                <React.Fragment key={index}>
                  <BaSeButton
                    autoFocus={index === 0}
                    buttonType="button"
                    size="medium"
                    onClick={() => handleClickAction(action)}
                    type={
                      typeof action === 'string'
                        ? `${index === 0 ? 'primary' : 'secondary'}`
                        : action?.type ??
                          `${index === 0 ? 'primary' : 'secondary'}`
                    }
                    color={
                      typeof action === 'string'
                        ? 'default'
                        : action?.color ?? 'default'
                    }
                    value={typeof action === 'string' ? action : action.action}
                  />
                </React.Fragment>
              ))}
            </ModalFooter>
          </ModalDialog>
        </FocusTrap>
      </ModalContainer>
    );
  },
);
