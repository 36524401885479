import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';

interface ContainerButtonStyledProps {
  focusBoxShadowColor: string;
  hoverBackgroundColor: string;
  backgroundColor: string;
  iconSize: number;
  textColor: string;
  iconColor: string;
  transition: string;
}

export const ContainerButtonStyled = styled.button<ContainerButtonStyledProps>`
  ${({
  iconSize,
  backgroundColor,
  textColor,
  transition,
  focusBoxShadowColor,
  hoverBackgroundColor,
  iconColor,
}) => css`

  font-size: ${BaSeTheme.typography.fontSizeBase};
  display: grid;
  gap: 6px;
  grid-template-columns: ${iconSize}px 1fr;
  place-items: center;
  background: ${backgroundColor};
  border-radius: 4px;
  border: none;
  outline: none;
  color: ${textColor};
  overflow: hidden;
  transition: ${transition};
  padding: 4px 6px;
  margin: 0;

  &,
  * {
    font-family: ${BaSeTheme.typography.fontFamily};
  }

  &:focus,
  &:focus-within,
  &:hover,
  &:active {
    cursor: pointer;
    outline: none;
  }

  &:focus,
  &:focus-within {
    box-shadow: 0px 0px 0px 3px ${focusBoxShadowColor};
  }

  &:hover,
  &:active {
    background-color: ${hoverBackgroundColor};
  }

  .BaSe--account-profile-avatar,
  .BaSe--account-profile-name-abbr-icon {
    background: ${textColor};
    width: ${iconSize}px;
    height: ${iconSize}px;
    border-radius: ${iconSize / 2}px;
    overflow: hidden;
  }

  .BaSe--account-profile-avatar {
    object-fit: contain;
  }

  .BaSe--account-profile-name-abbr-icon {
    display: grid;
    place-items: center;
    color: ${iconColor};
    line-height: ${iconSize}px;
    font-size: 13px;
  }

  .BaSe--account-profile-name {
    display: inline;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100px;
  }
  `}
`;
