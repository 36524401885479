import * as React from 'react';
import { BaSeIcon } from '../../image/icon';
import { BaSeTheme } from '../../../theme';
import { BaSeSmall2 } from '../../typography/small/small2';
import {
  HelperStyled,
  HelperContainer,
  HelperDetailsContainer,
} from './helper-text-styled';

export interface HelperTextInterface {
  label: string;
  details?: string;
  color?: string;
  size?: 'small' | 'medium';
  isItalic?: boolean;
}

export const BaSeHelperText: React.FC<HelperTextInterface> = ({
  label = '',
  details = '',
  color,
  size = 'medium',
  isItalic = false,
}) => {

  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <HelperContainer>
      <HelperStyled
        isItalic={isItalic}
        size={size}
        open={open}
        color={color}
        hasComplement={!!details}
        onClick={() => setOpen(prev => !prev && !!details)}>
        {!!details &&
          (open ? <BaSeIcon
            size={12}
            name={'angle-up'}
            description="esconder detalhes"
            color={BaSeTheme.colors.institucionais.azulSebrae36}
          /> :
            <BaSeIcon
              size={12}
              name={'angle-down'}
              description="exibir detalhes"
              color={BaSeTheme.colors.institucionais.azulSebrae36}
            />)
        }
        {label}
      </HelperStyled>
      {
        <HelperDetailsContainer open={!!details && open}>
          <BaSeSmall2 color={BaSeTheme.colors.institucionais.cinzaSebrae45}>
            {details}
          </BaSeSmall2>
        </HelperDetailsContainer>
      }
    </HelperContainer>
  );
};
