import * as React from 'react';
import { BaSeTheme } from '../../../theme';
import { BaSeSelect } from '../../select/select';
import { BaSeList, ListType } from '../list-icon/list-icon';
import { BaSeButton } from '../../button/button/button';
import {
  ContainerSelectList,
  WrapperSelectList,
} from './select-list-styled';

type SelectListValueId = number | string;

interface SelectValue {
  id: SelectListValueId;
  label: string;
}

interface SelectListType {
  width?: string | number;
  isDisabled?: boolean;
  sizeInput?: 'medium' | 'small' | 'big';
  nameButton: string;
  valueButton: string;
  label?: string;
  subLabel?: string;
  gapButton?: string | number;
  values: SelectValue[];
  buttonAction: (value: string) => void;
  selectIdValue: SelectListValueId;
  listItems: ListType;
}

export const BaSeSelectList: React.FC<SelectListType> = ({
  width = '100%',
  gapButton = '8px',
  isDisabled = false,
  listItems = {
    valuesList: [],
    direction: 'column',
    listActions: [{
        size: 'medium',
        sizeIcon: 'medium',
        ActionisDisabled: false,
        nameIcon: 'trash-alt',
        color: BaSeTheme.colors.institucionais.azulSebrae36,
        listAction: () => { },
    }],
  },
  valueButton = 'Adicionar',
  label = '',
  subLabel = '',
  values = [],
  sizeInput = 'medium',
  nameButton = 'plus',
  selectIdValue = 0,
  buttonAction = () => { },
}) => {
  const [newValue, setNewValue] = React.useState(selectIdValue as any);
  const inputAction = () => {
    buttonAction(newValue.label);
  };
  return (
    <ContainerSelectList width={width}>
      <WrapperSelectList gap={gapButton}>
        <BaSeSelect
          isDisabled={isDisabled}
          label={label}
          subLabel={subLabel}
          values={values}
          size={sizeInput}
          selectedValueId={selectIdValue}
          onChange={(value) => setNewValue(value)}
        />
        <BaSeButton
          color={BaSeTheme.colors.institucionais.azulSebrae36}
          onClick={inputAction}
          isDisabled={isDisabled}
          size={sizeInput}
          value={valueButton}
          leftIcon={nameButton}
        />
      </WrapperSelectList>
      <BaSeList
        {...listItems}
      />
    </ContainerSelectList>
  );
};
