import * as React from 'react';
import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';

export interface TagStyledProps {
  smallSize: number;
  mediumSize: number;
  bigSize: number;
  size: 'small' | 'medium' | 'big';
  icon?: string;
  iconBackgroundColor?: string;
  labelBackgroundColor?: string;
  borderColor?: string;
  onClick?: (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLSpanElement>,
  ) => void;
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const borderSize = 1;

function getSize({
  size,
  bigSize,
  mediumSize,
  smallSize,
}: Pick<
  TagStyledProps,
  'size' | 'bigSize' | 'mediumSize' | 'smallSize'
>): number {
  return size === 'big' ? bigSize : size === 'medium' ? mediumSize : smallSize;
}
function getTextMargin({ size }: Pick<TagStyledProps, 'size'>): string {
  return size === 'big' ? '8px' : '3px 8px 4px';
}

export const TagContainerStyled = styled.span<TagStyledProps>`
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border: ${borderSize}px solid transparent;
  height: ${getSize}px;
  border-radius: ${(props) => getSize(props) / 2}px;
  ${({ borderColor }) =>
    borderColor &&
    css`
      border-color: ${borderColor};
    `}
  ${({ labelBackgroundColor }) =>
    labelBackgroundColor &&
    css`
      background-color: ${labelBackgroundColor};
    `}
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      &:focus {
        outline: none;
        box-shadow: ${BaSeTheme.shadows.focusPrimary};
      }
    `}

  & > * {
    display: grid;
    place-items: center;
  }

  .icon {
    border-radius: 50%;
    ${({ iconBackgroundColor, borderColor }) =>
      iconBackgroundColor &&
      css`
        background-color: ${iconBackgroundColor};
        ${borderColor === BaSeTheme.colors.defaultColors.transparent &&
        iconBackgroundColor !== BaSeTheme.colors.defaultColors.transparent &&
        css`
          transform: translateX(-${borderSize * 2}px);
        `}
      `}
    ${({ borderColor, size, bigSize, mediumSize, smallSize }) =>
      borderColor !== BaSeTheme.colors.defaultColors.transparent
        ? css`
            height: ${getSize({ size, bigSize, mediumSize, smallSize }) - 2}px;
            width: ${getSize({ size, bigSize, mediumSize, smallSize }) - 2}px;
          `
        : css`
            height: ${getSize({ size, bigSize, mediumSize, smallSize })}px;
            width: ${getSize({ size, bigSize, mediumSize, smallSize })}px;
          `}
  }

  .text {
    flex: 1;
    text-align: center;
    margin: ${getTextMargin};
    ${({ icon, iconBackgroundColor }) =>
      icon &&
      iconBackgroundColor === BaSeTheme.colors.defaultColors.transparent &&
      css`
        margin-left: 0;
      `}
  }

  ${({ onClose, labelBackgroundColor, size }) =>
    onClose &&
    css`
      .close button {
        padding: ${size === 'small' ? 0 : 3}px;
        ${labelBackgroundColor &&
        css`
          &:not(:hover) {
            background-color: ${labelBackgroundColor};
          }
        `}
        img {
          height: ${size === 'big' ? 16 : 12}px;
          width: ${size === 'big' ? 16 : 12}px;
        }
      }
    `}
`;
