import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../theme';

const cssMobile = css`
  gap: 21px;
  flex-direction: column;
  & > *:first-child {
    align-self: center;
  }
`;

export const SideBySideStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 70px;

  /*mobileSmall*/
  ${BaSeTheme.breakpoints.mobileSmall.mediaQueryCss(cssMobile.toString())}
  /*mobile*/
  ${BaSeTheme.breakpoints.mobile.mediaQueryCss(cssMobile.toString())}
  /*mobileLarge*/
  ${BaSeTheme.breakpoints.mobileLarge.mediaQueryCss(cssMobile.toString())}
`;
