import * as React from 'react';
import { BaSeStaticAsset } from '../../static-asset/static-asset';
import { getLogoPath, LogoSebraeProps } from './logo-utils';

export const BaSeLogoSebraeInverse: React.FC<LogoSebraeProps> = ({
  onClick,
  width = 'auto',
  easterEgg = false,
}) => (
  <BaSeStaticAsset
    description="Logotipo do Sebrae"
    path={getLogoPath(easterEgg, 'images/logos/sebrae_logo-inverse')}
    onClick={onClick}
    width={typeof width === 'string' ? width : `${width}px`}
  />
);
