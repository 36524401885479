import styled from 'styled-components';
import { BaSeTheme } from '../../../theme';

interface DualButtonType {
  background: string;
  borderColor: string;
  borderRadius: string;
  boxShadowFocus: string;
  onHoverColor: string;
  width: number | string;
}

export const LeftStyledButton = styled.button<DualButtonType>`
  border-radius : 4px 0px 0px 4px;
  display : flex;
  width : ${props => typeof props.width === 'string' ? props.width : `${props.width}px`};
  background-color : ${props => props.background};
  border-style: solid;
  border-color: ${props => props.borderColor};
  border-width: 1px 1px 1px 1px;
  align-items: center;
  justify-content: center;
  padding : 8px;
  &:hover {
    &:before{
      background-color: ${props => props.onHoverColor};
    }
    cursor: pointer;
    background-color: ${props => props.onHoverColor};
  };
  &:focus{
    border-radius : ${props => props.borderRadius}px 0px 0px ${props => props.borderRadius}px;
    background-color : ${props => props.onHoverColor};
    box-shadow: ${props => props.boxShadowFocus};
    outline: none;
    z-index: ${BaSeTheme.zIndexs.hundreds};
    &:before{
      border-radius : ${props => props.borderRadius}px 0px 0px ${props => props.borderRadius}px;
      background-color : ${props => props.onHoverColor};
      box-shadow: ${BaSeTheme.shadows.profundidade2};
      outline: none;
    }
  }
`;

export const RightStyledButton = styled.button<DualButtonType>`
  border-radius :  0px 4px 4px 0px;
  display : flex;
  width : ${props => typeof props.width === 'string' ? props.width : `${props.width}px`};
  background-color : ${props => props.background};
  border-style: solid;
  border-color: ${props => props.borderColor};
  border-width: 1px 1px 1px 0px;
  align-items: center;
  justify-content: center;
  padding : 8px;
  &:hover {
    cursor: pointer;
    background-color: ${props => props.onHoverColor};
  };
  &:focus{
    border-radius : 0px ${props => props.borderRadius}px ${props => props.borderRadius}px 0px;
    background-color:${props => props.onHoverColor};
    box-shadow: ${props => props.boxShadowFocus};
    outline: none;
    z-index: ${BaSeTheme.zIndexs.hundreds};
  }
`;

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction:'row';
`;
