import { ThemeColorValue } from './../../theme/theme-interface';
import styled, { css, keyframes } from 'styled-components';
import { BaSeTheme } from '../../theme';

export type TypeTransitionProps = 'leftToRight' | 'rightToLeft' | 'inert';
export interface TabTransitionProps {
  transition: TypeTransitionProps;
}
export interface TapControlProps {
  isActive: boolean;
}
export interface TabColorProps {
  activeColor: ThemeColorValue;
  inactiveColor?: ThemeColorValue;
}

export interface LinesProps extends Pick<TabColorProps, 'activeColor'> {
  activeLeft: number;
  activeWidth: number;
  destakLeft: number;
  destakWidth: number;
}

export const sidePadding = 8;

const slideIn = (origin: number) => keyframes`
  from {
    transform: translateX(${origin}%);
    opacity: 0.9;
  }
  to {
    opacity: 1;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabSeparator = styled.hr<LinesProps>`
  margin: 0;
  width: 100%;
  height: 1px;
  border: none;
  overflow: visible;
  position: relative;
  background-color: ${BaSeTheme.colors.institucionais.cinzaSebrae90};

  &::before,
  &::after {
    content: '';
    top: 0;
    left: 0;
    height: 4px;
    position: absolute;
    background: ${({ activeColor }) => activeColor};
    z-index: ${BaSeTheme.zIndexs.units};
  }

  &::before {
    transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
    will-change: transform, width;
    ${({ activeLeft, activeWidth }) => css`
      transform: translateX(${activeLeft}px);
      width: ${activeWidth}px;
    `}
  }

  &::after {
    ${({ destakLeft, destakWidth }) => css`
      transform: translateX(${destakLeft}px);
      width: ${destakWidth}px;
    `};
  }
`;

export const TabHeader = styled.ul`
  display: flex;
  gap: 24px;
  align-items: flex-end;
  position: relative;

  overflow-y: hidden;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  flex-shrink: 0;
  min-block-size: fit-content;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TabContent = styled.div<TabTransitionProps>`
  position: relative;
  ${({ transition }) =>
    transition !== 'inert' &&
    css`
      animation: ${slideIn(transition === 'leftToRight' ? -100 : 100)}
        ${BaSeTheme.transitions.durationNormal}
        ${BaSeTheme.transitions.continuousTimingFunction};
    `};
`;

export const TabContainer = styled.li<TapControlProps>``;

export const TabItem = styled.button<TapControlProps>`
  appearance: none;
  background: ${BaSeTheme.colors.defaultColors.transparent};
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: ${sidePadding}px;
  white-space: nowrap;
  scroll-snap-align: start;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover,
  &:focus {
    outline: none;
    ${({ isActive }) =>
      !isActive &&
      css`
        h5 {
          color: ${BaSeTheme.colors.institucionais.cinzaSebrae30};
        }
        background: ${BaSeTheme.colors.institucionais.cinzaSebrae97};
      `};
  }
`;
