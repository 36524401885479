import styled from 'styled-components';
import { mapCounterTypes } from './map-counter-style';
import { BaSeTheme } from '../../theme';

interface StyledLabelProps {
  fontSize: string;
  lineHeight: string;
}

interface StyledCounterInputProps {
  value: number;
  disabled: boolean;
  height: number;
  width: number;
  hasError: boolean;
}

const zIndexLevel = BaSeTheme.zIndexs.tens;

export const StyledLabel = styled.label<StyledLabelProps>`
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae45};
  font-family: ${BaSeTheme.typography.fontFamily};
  font-style: normal;
  font-weight: normal;
  margin: 0;
  padding: 0;
  margin-bottom: 2px;
`;

export const StyledApoio = styled.span`
  font-size: 0.83em;
  color: ${BaSeTheme.colors.feedbackInterface.erro35};
  margin-top: 3px;
  line-height: 1.2em;
  font-family: ${BaSeTheme.typography.fontFamily};
  font-style: italic;
  font-weight: normal;
`;

export const StyledCounterInput = styled.input<StyledCounterInputProps>`
  padding-left: 12px;
  background: ${BaSeTheme.colors.defaultColors.transparent};
  border-top: 1px solid
    ${(props) =>
    props.hasError
      ? BaSeTheme.colors.feedbackInterface.erro35
      : BaSeTheme.colors.institucionais.cinzaSebrae75};
  border-bottom: 1px solid
    ${(props) =>
    props.hasError
      ? BaSeTheme.colors.feedbackInterface.erro35
      : BaSeTheme.colors.institucionais.cinzaSebrae75};
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
  height: ${(props) => props.height}px;
  padding-right: 26px;
  padding: 0;
  margin-left: -2px;
  margin-right: -2px;
  width: ${(props) => (!!props.width ? `${props.width}px` : '100%')};
  min-width: 60px;
  color: ${(props) =>
    props.hasError
      ? BaSeTheme.colors.feedbackInterface.erro35
      : BaSeTheme.colors.institucionais.cinzaSebrae75};
  z-index: ${zIndexLevel};
  text-align: center;
  &:focus {
    outline: 0;
  }
`;

export const CounterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CounterInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  button {
    z-index: ${zIndexLevel + 1};
  }
`;

export const getButtonType = (
  type: string,
  isDisabled: boolean,
  hasError: boolean,
) => {
  if (hasError) {
    return mapCounterTypes[type].error;
  }
  if (isDisabled) {
    return mapCounterTypes[type].disabled;
  }

  return mapCounterTypes[type].default;
};
