import styled from 'styled-components';
import { BaSeTheme } from '../../theme';
import { ThemeBreakpointType } from '../../theme/theme-interface';

export type GridItemSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type GridItemStartAt = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export type GripItemWrapperProps = {
  all: {
    size: GridItemSize;
    startAt?: GridItemStartAt;
  };
} & {
  [breakpoint in ThemeBreakpointType]?: {
    size: GridItemSize;
    startAt?: GridItemStartAt;
  };
};

function gridColumn(size: GridItemSize, startAt?: GridItemStartAt): string {
  return startAt !== undefined
    ? `${startAt + 1} / span ${size}`
    : `span ${size}`;
}

function get({
  what,
  props,
  breakpoint,
}: {
  what: 'size' | 'startAt';
  props: GripItemWrapperProps;
  breakpoint: ThemeBreakpointType;
}): GridItemSize | GridItemStartAt {
  const fallback = {
    mobileSmall: props.all?.[what],
    mobile: props?.mobileSmall?.[what] ?? props.all?.[what],
    mobileLarge:
      props?.mobile?.[what] ?? props?.mobileSmall?.[what] ?? props.all?.[what],
    tablet:
      props?.mobileLarge?.[what] ??
      props?.mobile?.[what] ??
      props?.mobileSmall?.[what] ??
      props.all?.[what],
    desktop:
      props?.tablet?.[what] ??
      props?.mobileLarge?.[what] ??
      props?.mobile?.[what] ??
      props?.mobileSmall?.[what] ??
      props.all?.[what],
    desktopLarge:
      props?.desktop?.[what] ??
      props?.tablet?.[what] ??
      props?.mobileLarge?.[what] ??
      props?.mobile?.[what] ??
      props?.mobileSmall?.[what] ??
      props.all?.[what],
  };
  return props?.[breakpoint]?.[what] ?? (fallback[breakpoint] as any);
}

function getSize({
  from: props,
  atBreakpoint: breakpoint,
}: {
  from: GripItemWrapperProps;
  atBreakpoint: ThemeBreakpointType;
}): GridItemSize {
  return get({ props, breakpoint, what: 'size' }) as GridItemSize;
}

function getStartAt({
  from: props,
  atBreakpoint: breakpoint,
}: {
  from: GripItemWrapperProps;
  atBreakpoint: ThemeBreakpointType;
}): GridItemStartAt {
  return get({ props, breakpoint, what: 'startAt' }) as GridItemStartAt;
}

function gridColumnBreakpoint(
  props: GripItemWrapperProps,
  breakpoint: ThemeBreakpointType,
): string {
  return gridColumn(
    getSize({ from: props, atBreakpoint: breakpoint }),
    getStartAt({ from: props, atBreakpoint: breakpoint }),
  );
}

export const GridItemWrapper = styled.div<GripItemWrapperProps>`
  grid-column: ${(props) => gridColumn(props.all.size, props.all?.startAt)};

  /* mobileSmall */
  ${(props) =>
    BaSeTheme.breakpoints.mobileSmall.mediaQueryCss(`
    grid-column: ${gridColumnBreakpoint(props, 'mobileSmall')};
  `)}
  /* mobile */
  ${(props) =>
    BaSeTheme.breakpoints.mobile.mediaQueryCss(`
    grid-column: ${gridColumnBreakpoint(props, 'mobile')};
  `)}
  /* mobileLarge */
  ${(props) =>
    BaSeTheme.breakpoints.mobileLarge.mediaQueryCss(`
    grid-column: ${gridColumnBreakpoint(props, 'mobileLarge')};
  `)}
  /* tablet */
  ${(props) =>
    BaSeTheme.breakpoints.tablet.mediaQueryCss(`
    grid-column: ${gridColumnBreakpoint(props, 'tablet')};
  `)}
  /* desktop */
  ${(props) =>
    BaSeTheme.breakpoints.desktop.mediaQueryCss(`
    grid-column: ${gridColumnBreakpoint(props, 'desktop')};
  `)}
  /* desktopLarge */
  ${(props) =>
    BaSeTheme.breakpoints.desktopLarge.mediaQueryCss(`
    grid-column: ${gridColumnBreakpoint(props, 'desktopLarge')};
  `)}
`;
