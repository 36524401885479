import { BaSeTheme } from '../../theme';
import { Color } from '../../utils/color-transformation/color';
import { createButtonHoverColor, createButtonFocusColor, createButtonDarkTextColor } from '../../utils/color-transformation/calculate-pattern';

const tertiaryMappedOpacityBlue1 = 'rgba(121, 171, 236, 0.83)';
const tertiaryMappedOpacityBlue2 = 'rgba(63, 138, 243, 0.14)';
const tertiaryMappedOpacityRed1 = 'rgba(255, 133, 102, 0.83)';
const tertiaryMappedOpacityRed2 = 'rgba(255, 112, 77, 0.14)';
const tertiaryMappedOpacityGreen1 = 'rgba(133, 224, 163, 0.83)';
const tertiaryMappedOpacityGreen2 = 'rgba(112, 219, 148, 0.14)';

// *********************
// REGULAR BUTTONS
// *********************
export const mapButtonValues = {
  primary: {
    default: {
      color: BaSeTheme.colors.defaultColors.white,
      iconColor: BaSeTheme.colors.defaultColors.white,
      style: {
        bgC: BaSeTheme.colors.institucionais.azulSebrae36,
        bcCHover: BaSeTheme.colors.institucionais.azulSebrae30,
        boxShadowFocus: `0px 0px 0px 3px ${BaSeTheme.colors.institucionais.azulSebrae75}`,
        border: 'none',
      },
    },
    destructive: {
      color: BaSeTheme.colors.defaultColors.white,
      iconColor: BaSeTheme.colors.defaultColors.white,
      style: {
        bgC: BaSeTheme.colors.feedbackInterface.erro45,
        bcCHover: BaSeTheme.colors.feedbackInterface.erro35,
        boxShadowFocus: `0px 0px 0px 3px ${BaSeTheme.colors.feedbackInterface.erro75}`,
        border: 'none',
      },
    },
    confirmation: {
      color: BaSeTheme.colors.defaultColors.white,
      iconColor: BaSeTheme.colors.defaultColors.white,
      style: {
        bgC: BaSeTheme.colors.feedbackInterface.sucesso26,
        bcCHover: BaSeTheme.colors.feedbackInterface.sucesso20,
        boxShadowFocus: `0px 0px 0px 3px ${BaSeTheme.colors.feedbackInterface.sucesso75}`,
        border: 'none',
      },
    },
    disabled: {
      color: BaSeTheme.colors.institucionais.cinzaSebrae75,
      iconColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
      style: {
        bgC: BaSeTheme.colors.defaultColors.lightGray,
        bcCHover: BaSeTheme.colors.defaultColors.lightGray,
        boxShadowFocus: 'none',
        border: 'none',
      },
    },
    negative: {
      color: BaSeTheme.colors.institucionais.azulSebrae36,
      iconColor: BaSeTheme.colors.institucionais.azulSebrae36,
      style: {
        bgC: BaSeTheme.colors.defaultColors.white,
        bcCHover: BaSeTheme.colors.institucionais.cinzaSebrae97,
        boxShadowFocus: `0px 0px 0px 3px ${BaSeTheme.colors.institucionais.azulSebrae90}`,
        border: 'none',
        colorHover: BaSeTheme.colors.institucionais.azulSebrae30,
      },
      disabled: {
        color: BaSeTheme.colors.institucionais.cinzaSebrae75,
        iconColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
        style: {
          bgC: BaSeTheme.colors.defaultColors.lightGray,
          bcCHover: BaSeTheme.colors.defaultColors.lightGray,
          boxShadowFocus: 'none',
          border: 'none',
        },
      },
    },
  },
  secondary: {
    default: {
      color: BaSeTheme.colors.institucionais.cinzaSebrae45,
      iconColor: BaSeTheme.colors.institucionais.cinzaSebrae45,
      style: {
        bgC: BaSeTheme.colors.defaultColors.white,
        bcCHover: BaSeTheme.colors.institucionais.cinzaSebrae97,
        boxShadowFocus: `0px 0px 0px 3px ${BaSeTheme.colors.institucionais.cinzaSebrae75}`,
        border: `1px solid ${BaSeTheme.colors.institucionais.cinzaSebrae30}`,
        colorHover: BaSeTheme.colors.institucionais.cinzaSebrae30,
      },

    },
    destructive: {
      color: BaSeTheme.colors.feedbackInterface.erro45,
      iconColor: BaSeTheme.colors.feedbackInterface.erro45,
      style: {
        bgC: BaSeTheme.colors.defaultColors.white,
        bcCHover: BaSeTheme.colors.feedbackInterface.erro95,
        boxShadowFocus: `0px 0px 0px 3px ${BaSeTheme.colors.feedbackInterface.erro75}`,
        border: `1px solid ${BaSeTheme.colors.feedbackInterface.erro45}`,
        colorHover: BaSeTheme.colors.feedbackInterface.erro35,
      },
    },
    confirmation: {
      color: BaSeTheme.colors.feedbackInterface.sucesso26,
      iconColor: BaSeTheme.colors.feedbackInterface.sucesso26,
      style: {
        bgC: BaSeTheme.colors.defaultColors.white,
        bcCHover: BaSeTheme.colors.feedbackInterface.sucesso95,
        boxShadowFocus: `0px 0px 0px 3px ${BaSeTheme.colors.feedbackInterface.sucesso75}`,
        border: `1px solid ${BaSeTheme.colors.feedbackInterface.sucesso26}`,
        colorHover: BaSeTheme.colors.feedbackInterface.sucesso20,
      },
    },
    disabled: {
      color: BaSeTheme.colors.institucionais.cinzaSebrae75,
      iconColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
      style: {
        bgC: BaSeTheme.colors.institucionais.cinzaSebrae90,
        bcCHover: '',
        boxShadowFocus: ``,
        border: `1px solid ${BaSeTheme.colors.institucionais.cinzaSebrae75}`,
      },
    },
    negative: {
      color: BaSeTheme.colors.defaultColors.white,
      iconColor: BaSeTheme.colors.defaultColors.white,
      style: {
        bgC: BaSeTheme.colors.defaultColors.transparent,
        bcCHover: BaSeTheme.colors.defaultColors.whiteOpacity50,
        boxShadowFocus: `0px 0px 0px 4px ${BaSeTheme.colors.institucionais.azulSebrae75}`,
        border: `2px solid ${BaSeTheme.colors.defaultColors.white}`,
      },
      disabled: {
        color: BaSeTheme.colors.institucionais.cinzaSebrae75,
        iconColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
        style: {
          bgC: BaSeTheme.colors.institucionais.cinzaSebrae90,
          bcCHover: '',
          boxShadowFocus: ``,
          border: `2px solid ${BaSeTheme.colors.institucionais.cinzaSebrae75}`,
        },
      },
    },
  },
  tertiary: {
    default: {
      color: BaSeTheme.colors.institucionais.azulSebrae36,
      iconColor: BaSeTheme.colors.institucionais.azulSebrae36,
      style: {
        bgC: BaSeTheme.colors.defaultColors.transparent,
        bcCHover: tertiaryMappedOpacityBlue2,
        boxShadowFocus: `0px 0px 0px 3px ${tertiaryMappedOpacityBlue1};`,
        border: `none`,
        colorHover: BaSeTheme.colors.institucionais.azulSebrae30,
      },
    },
    destructive: {
      color: BaSeTheme.colors.feedbackInterface.erro45,
      iconColor: BaSeTheme.colors.feedbackInterface.erro45,
      style: {
        bgC: BaSeTheme.colors.defaultColors.transparent,
        bcCHover: tertiaryMappedOpacityRed2,
        boxShadowFocus: `0px 0px 0px 3px ${tertiaryMappedOpacityRed1}`,
        border: `none`,
        colorHover: BaSeTheme.colors.feedbackInterface.erro35,
      },
    },
    confirmation: {
      color: BaSeTheme.colors.feedbackInterface.sucesso26,
      iconColor: BaSeTheme.colors.feedbackInterface.sucesso26,
      style: {
        bgC: BaSeTheme.colors.defaultColors.transparent,
        bcCHover: tertiaryMappedOpacityGreen2,
        boxShadowFocus: `0px 0px 0px 3px ${tertiaryMappedOpacityGreen1}`,
        border: `none`,
        colorHover: BaSeTheme.colors.feedbackInterface.sucesso20,
      },
    },
    disabled: {
      color: BaSeTheme.colors.institucionais.cinzaSebrae75,
      iconColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
      style: {
        bgC: BaSeTheme.colors.defaultColors.transparent,
        bcCHover: BaSeTheme.colors.defaultColors.transparent,
        boxShadowFocus: `none`,
        border: `none`,
      },
    },
    negative: {
      color: BaSeTheme.colors.defaultColors.white,
      iconColor: BaSeTheme.colors.defaultColors.white,
      style: {
        bgC: BaSeTheme.colors.defaultColors.transparent,
        bcCHover: BaSeTheme.colors.defaultColors.whiteOpacity50,
        boxShadowFocus: `0px 0px 0px 4px ${BaSeTheme.colors.institucionais.azulSebrae90}`,
        border: `none`,
      },
      disabled: {
        color: BaSeTheme.colors.institucionais.cinzaSebrae60,
        iconColor: BaSeTheme.colors.institucionais.cinzaSebrae60,
        style: {
          bgC: BaSeTheme.colors.defaultColors.transparent,
          bcCHover: BaSeTheme.colors.defaultColors.transparent,
          boxShadowFocus: `none`,
          border: `none`,
        },
      },
    },
  },
};

export const styleMapSize = {
  medium: {
    padding: '8.5px 12px',
    fontSize: '1',
    lineHeight: '1.4375',
    loadingSize: 16,
  },
  small: {
    padding: '8px',
    fontSize: '0.83',
    lineHeight: '1.24',
    loadingSize: 16,
  },
  big: {
    padding: '12px 16px',
    fontSize: '1.2',
    lineHeight: '1.25',
    loadingSize: 24,
  },
};

export const mapIconColorStyle = {
  primary: {
    default: BaSeTheme.colors.defaultColors.white,
    error: BaSeTheme.colors.defaultColors.white,
    success: BaSeTheme.colors.defaultColors.white,
    disabled: BaSeTheme.colors.defaultColors.white,
    negative: BaSeTheme.colors.institucionais.azulSebrae36,
  },
  secondary: {
    default: BaSeTheme.colors.institucionais.azulSebrae36,
    error: BaSeTheme.colors.feedbackInterface.erro45,
    success: BaSeTheme.colors.feedbackInterface.sucesso26,
    disabled: BaSeTheme.colors.institucionais.cinzaSebrae75,
    negative: BaSeTheme.colors.defaultColors.white,
  },
};

// *********************
//  SHAPE BUTTON MAPS
// *********************
export const shapeButtonMapStyle = {
  primary: {
    default: {
      background: BaSeTheme.colors.institucionais.azulSebrae36,
      iconColor: BaSeTheme.colors.defaultColors.white,
      onHoverColor: BaSeTheme.colors.institucionais.azulSebrae30,
      boxShadowFocus: `0px 0px 0px 3px ${BaSeTheme.colors.institucionais.azulSebrae75}`,
      border: 'none',
    },
    negative: {
      background: BaSeTheme.colors.defaultColors.white,
      iconColor: BaSeTheme.colors.institucionais.azulSebrae36,
      onHoverColor: BaSeTheme.colors.institucionais.azulSebrae97,
      boxShadowFocus: `0px 0px 0px 3px ${BaSeTheme.colors.institucionais.azulSebrae75}`,
      border: 'none',
    },
    disabled: {
      background: BaSeTheme.colors.defaultColors.lightGray,
      iconColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
      onHoverColor: '',
      boxShadowFocus: 'none',
      border: 'none',
    },
  },
  secondary: {
    default: {
      background: BaSeTheme.colors.defaultColors.white,
      iconColor: BaSeTheme.colors.institucionais.cinzaSebrae45,
      onHoverColor: BaSeTheme.colors.institucionais.cinzaSebrae97,
      boxShadowFocus: `0px 0px 0px 3px ${BaSeTheme.colors.institucionais.cinzaSebrae75}`,
      border: `1px solid ${BaSeTheme.colors.institucionais.cinzaSebrae45}`,
      borderHoverColor: `1px solid ${BaSeTheme.colors.institucionais.cinzaSebrae30}`,
      iconHoverColor: BaSeTheme.colors.institucionais.cinzaSebrae30,
    },
    negative: {
      background: BaSeTheme.colors.defaultColors.transparent,
      iconColor: BaSeTheme.colors.defaultColors.white,
      onHoverColor: BaSeTheme.colors.defaultColors.whiteOpacity80,
      border: `2px solid ${BaSeTheme.colors.defaultColors.white}`,
      borderColor: BaSeTheme.colors.institucionais.azulSebrae36,
      boxShadowFocus: `0px 0px 0px 3px ${BaSeTheme.colors.institucionais.azulSebrae75}`,
    },
    disabled: {
      background: BaSeTheme.colors.defaultColors.lightGray,
      iconColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
      onHoverColor: '',
      boxShadowFocus: 'none',
      border: `1px solid ${BaSeTheme.colors.institucionais.cinzaSebrae75}`,
    },
  },
  counter: {
    default: {
      background: BaSeTheme.colors.defaultColors.white,
      iconColor: BaSeTheme.colors.institucionais.azulSebrae36,
      onHoverColor: 'none',
      boxShadowFocus: 'none',
      border: `1px solid ${BaSeTheme.colors.institucionais.cinzaSebrae75}`,
    },
    fillerror: {
      background: BaSeTheme.colors.feedbackInterface.erro35,
      iconColor: BaSeTheme.colors.defaultColors.white,
      onHoverColor: 'none',
      boxShadowFocus: 'none',
      border: 'none',
    },
    blankerror: {
      background: BaSeTheme.colors.defaultColors.white,
      iconColor: BaSeTheme.colors.feedbackInterface.erro35,
      onHoverColor: '',
      boxShadowFocus: 'none',
      border: `1px solid ${BaSeTheme.colors.feedbackInterface.erro35}`,
    },
  },
  tertiary: {
    default: {
      background: BaSeTheme.colors.defaultColors.transparent,
      iconColor: BaSeTheme.colors.institucionais.azulSebrae36,
      onHoverColor: tertiaryMappedOpacityBlue2,
      boxShadowFocus: `0px 0px 0px 3px ${tertiaryMappedOpacityBlue1}`,
      border: 'none',
    },
    negative: {
      background: BaSeTheme.colors.defaultColors.transparent,
      iconColor: BaSeTheme.colors.defaultColors.white,
      onHoverColor: BaSeTheme.colors.defaultColors.whiteOpacity50,
      boxShadowFocus: `0px 0px 0px 3px ${BaSeTheme.colors.institucionais.azulSebrae90}`,
      border: 'none',
    },
    disabled: {
      background: BaSeTheme.colors.institucionais.cinzaSebrae90,
      iconColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
      onHoverColor: 'none',
      boxShadowFocus: 'none',
      border: 'none',
    },
  },
};

export const shapeButtonIconMapSize = {
  small: {
    iconSize: 16,
  },
  medium: {
    iconSize: 24,
  },
};

export const shapeButtonBorderRadius = {
  circle: '50%',
  square: '4px;',
};

// *********************
//  DUAL BUTTON MAPS
// *********************
export const dualButtonMapStyle = {
  primary: {
    default: {
      background: BaSeTheme.colors.defaultColors.white,
      color: BaSeTheme.colors.institucionais.azulSebrae36,
      onHoverColor: BaSeTheme.colors.institucionais.azulSebrae97,
      boxShadowFocus: `0 0 0 4px ${BaSeTheme.colors.institucionais.azulSebrae75}`,
      border: '1px 1px 1px 1px',
      borderColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
      borderRadius: '4',
    },
    negative: {
      background: BaSeTheme.colors.defaultColors.transparent,
      color: BaSeTheme.colors.defaultColors.white,
      onHoverColor: BaSeTheme.colors.defaultColors.whiteOpacity50,
      boxShadowFocus: `0 0 0 4px ${BaSeTheme.colors.institucionais.azulSebrae75}`,
      border: '1px 1px 1px 0px',
      borderColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
      borderRadius: '4',
    },
    error: {
      background: BaSeTheme.colors.defaultColors.transparent,
      color: BaSeTheme.colors.feedbackInterface.erro35,
      onHoverColor: '',
      boxShadowFocus: '0px 0px 0px 0px',
      border: '1px 1px 1px 0px',
      borderColor: BaSeTheme.colors.feedbackInterface.erro35,
      borderRadius: '4',
    },
  },
  selected: {
    default: {
      background: BaSeTheme.colors.institucionais.azulSebrae36,
      color: BaSeTheme.colors.defaultColors.white,
      onHoverColor: '',
      boxShadowFocus: `0 0 0 4px ${BaSeTheme.colors.institucionais.azulSebrae75}`,
      border: '1px 1px 1px 0px',
      borderColor: BaSeTheme.colors.institucionais.azulSebrae36,
      borderRadius: '4',
    },
    negative: {
      background: BaSeTheme.colors.defaultColors.white,
      color: BaSeTheme.colors.institucionais.azulSebrae36,
      onHoverColor: '',
      boxShadowFocus: `0 0 0 4px ${BaSeTheme.colors.institucionais.azulSebrae75}`,
      border: '1px 1px 1px 0px',
      borderColor: BaSeTheme.colors.defaultColors.white,
      borderRadius: '4',
    },
    error: {
      background: BaSeTheme.colors.defaultColors.transparent,
      color: BaSeTheme.colors.feedbackInterface.erro35,
      onHoverColor: '',
      boxShadowFocus: '0px 0px 0px 0px',
      border: '1px 1px 1px 0px',
      borderColor: BaSeTheme.colors.feedbackInterface.erro35,
      borderRadius: '4',
    },
  },
};

// *********************
//  ARROW BUTTON MAPS
// *********************
export const arrowButtonMapStyle = {
  primary: {
    default: {
      background: BaSeTheme.colors.institucionais.azulSebrae36,
      color: BaSeTheme.colors.defaultColors.white,
      hoverColor: BaSeTheme.colors.institucionais.azulSebrae30,
      boxShadowFocus: BaSeTheme.colors.institucionais.azulSebrae60,
      border: '0px',
      borderColor: BaSeTheme.colors.institucionais.azulSebrae36,
      borderRadius: '7.5',
      arrows: {
        top: '4.75px',
      },
    },
    negative: {
      background: BaSeTheme.colors.defaultColors.white,
      color: BaSeTheme.colors.institucionais.azulSebrae36,
      hoverColor: BaSeTheme.colors.institucionais.azulSebrae97,
      boxShadowFocus: BaSeTheme.colors.institucionais.azulSebrae60,
      border: '0px',
      borderColor: BaSeTheme.colors.defaultColors.white,
      borderRadius: '7.5',
      textHoverColor: BaSeTheme.colors.institucionais.azulSebrae30,
      borderHoverColor: BaSeTheme.colors.institucionais.azulSebrae30,
      arrows: {
        top: '4.75px',
      },
    },
    disabled: {
      background: BaSeTheme.colors.institucionais.cinzaSebrae90,
      color: BaSeTheme.colors.institucionais.cinzaSebrae75,
      hoverColor: '',
      boxShadowFocus: '',
      border: '0px',
      borderColor: BaSeTheme.colors.defaultColors.white,
      borderRadius: '7.5',
      arrows: {
        top: '4.75px',
      },
      negative: {
        background: BaSeTheme.colors.defaultColors.white,
        color: BaSeTheme.colors.institucionais.azulSebrae36,
        hoverColor: BaSeTheme.colors.institucionais.azulSebrae97,
        boxShadowFocus: BaSeTheme.colors.institucionais.azulSebrae60,
        border: '0px',
        borderColor: BaSeTheme.colors.defaultColors.white,
        borderRadius: '7.5',
        textHoverColor: BaSeTheme.colors.institucionais.azulSebrae30,
        borderHoverColor: BaSeTheme.colors.institucionais.azulSebrae30,
        arrows: {
          top: '4.75px',
        },
      },
    },
  },
  secondary: {
    default: {
      background: BaSeTheme.colors.defaultColors.white,
      color: BaSeTheme.colors.institucionais.cinzaSebrae45,
      hoverColor: BaSeTheme.colors.institucionais.cinzaSebrae97,
      boxShadowFocus: BaSeTheme.colors.institucionais.cinzaSebrae75,
      border: '1px',
      borderColor: BaSeTheme.colors.institucionais.cinzaSebrae45,
      borderRadius: '7.5',
      textHoverColor: BaSeTheme.colors.institucionais.cinzaSebrae30,
      borderHoverColor: BaSeTheme.colors.institucionais.cinzaSebrae30,
      arrows: {
        top: '3.75px',
      },
    },
    negative: {
      background: BaSeTheme.colors.defaultColors.transparent,
      color: BaSeTheme.colors.defaultColors.white,
      hoverColor: BaSeTheme.colors.defaultColors.whiteOpacity50,
      boxShadowFocus: `${BaSeTheme.colors.institucionais.azulSebrae75}`,
      border: '2px',
      borderColor: BaSeTheme.colors.defaultColors.white,
      borderRadius: '7.5',
      arrows: {
        top: '2.75px',
      },
    },
    disabled: {
      background: BaSeTheme.colors.institucionais.cinzaSebrae90,
      color: BaSeTheme.colors.institucionais.cinzaSebrae75,
      hoverColor: '',
      boxShadowFocus: '',
      border: '1px',
      borderColor: `${BaSeTheme.colors.institucionais.cinzaSebrae75}`,
      borderRadius: '7.5',
      negative: {
        background: BaSeTheme.colors.institucionais.cinzaSebrae90,
        color: BaSeTheme.colors.institucionais.cinzaSebrae75,
        hoverColor: '',
        boxShadowFocus: '',
        border: '2px',
        borderColor: `${BaSeTheme.colors.institucionais.cinzaSebrae75}`,
        borderRadius: '7.5',
        arrows: {
          top: '2.75px',
        },
      },
      arrows: {
        top: '3.5px',
      },
    },
  },
};

// *********************
//  CUSTOM COLORS BUTTON MAPS
// *********************
export const mapCustomColorsButtonValues = (customColor: string) => {
  const customColorHsv = Color.generateFromHex(customColor);
  const generateColor = (color: Color): string => color.toString();

  return {
    primary: {
      color: BaSeTheme.colors.defaultColors.white,
      iconColor: BaSeTheme.colors.defaultColors.white,
      style: {
        bgC:
          (customColor && customColorHsv) ||
          BaSeTheme.colors.institucionais.azulSebrae36,
        bcCHover:
          customColor && customColorHsv
            ? `${generateColor(customColorHsv.generateDarkColor())}`
            : BaSeTheme.colors.institucionais.azulSebrae30,
        boxShadowFocus:
          customColor && customColorHsv
            ? `0px 0px 0px 3px ${generateColor(
              customColorHsv.generateLighterColorToFocus(),
            )}`
            : `0px 0px 0px 3px ${BaSeTheme.colors.institucionais.azulSebrae75}`,
        border: 'none',
      },
    },
    secondary: {
      color:
        customColor && customColorHsv
          ? customColor
          : BaSeTheme.colors.institucionais.cinzaSebrae45,
      iconColor:
        customColor && customColorHsv
          ? customColor
          : BaSeTheme.colors.institucionais.cinzaSebrae45,
      style: {
        bgC: BaSeTheme.colors.defaultColors.white,
        bcCHover:
          customColor && customColorHsv
            ? `${generateColor(customColorHsv.generateLighterColorToHover())}`
            : BaSeTheme.colors.institucionais.cinzaSebrae97,
        boxShadowFocus:
          customColor && customColorHsv
            ? `0px 0px 0px 3px ${generateColor(
              customColorHsv.generateLighterColorToFocus(),
            )}`
            : `0px 0px 0px 3px ${BaSeTheme.colors.institucionais.cinzaSebrae75}`,
        border:
          customColor && customColorHsv
            ? `1px solid ${customColor}`
            : `1px solid ${BaSeTheme.colors.institucionais.cinzaSebrae30}`,
        colorHover: customColor && customColorHsv
          ? createButtonDarkTextColor(customColor)
          : BaSeTheme.colors.institucionais.cinzaSebrae30,
      },
    },
    tertiary: {
      color:
        customColor && customColorHsv
          ? customColor
          : BaSeTheme.colors.institucionais.cinzaSebrae45,
      iconColor:
        customColor && customColorHsv
          ? customColor
          : BaSeTheme.colors.institucionais.cinzaSebrae45,
      style: {
        bgC: BaSeTheme.colors.defaultColors.transparent,
        bcCHover:
          customColor && customColorHsv
            ? `${createButtonHoverColor(customColor)}`
            : tertiaryMappedOpacityBlue2,
        boxShadowFocus:
          customColor && customColorHsv
            ? `0px 0px 0px 3px ${createButtonFocusColor(customColor)}`
            : `0px 0px 0px 3px ${tertiaryMappedOpacityBlue1}`,
        border: `none`,
        colorHover: customColor && customColorHsv
          ? createButtonDarkTextColor(customColor)
          : BaSeTheme.colors.institucionais.azulSebrae30,
      },
    },
  };
};

// *********************
//  CUSTOM COLORS ARROW BUTTON MAPS
// *********************
export const mapCustomColorsArrowButtonValues = (customColor: string) => {
  const customColorHsv = Color.generateFromHex(customColor);
  const generateColor = (color: Color): string => color.toString();

  return {
    primary: {
      background:
        customColor && customColorHsv
          ? customColorHsv.toString()
          : BaSeTheme.colors.institucionais.azulSebrae36,
      color: BaSeTheme.colors.defaultColors.white,
      hoverColor:
        customColor && customColorHsv
          ? generateColor(customColorHsv.generateDarkColor())
          : BaSeTheme.colors.institucionais.azulSebrae30,
      boxShadowFocus:
        customColor && customColorHsv
          ? generateColor(customColorHsv.generateLighterColorToFocus())
          : BaSeTheme.colors.institucionais.azulSebrae60,
      border: '0px',
      borderColor: BaSeTheme.colors.defaultColors.white,
      borderRadius: '7.5',
    },
    secondary: {
      background: BaSeTheme.colors.defaultColors.white,
      color:
        customColor && customColorHsv
          ? customColorHsv.toString()
          : BaSeTheme.colors.institucionais.azulSebrae36,
      hoverColor:
        customColor && customColorHsv
          ? generateColor(customColorHsv.generateLighterColorToHover())
          : '#C4C4C4', // cor nao mapeada
      boxShadowFocus:
        customColor && customColorHsv
          ? generateColor(customColorHsv.generateLighterColorToFocus())
          : BaSeTheme.colors.institucionais.azulSebrae75,
      border: '1px',
      borderColor:
        customColor && customColorHsv
          ? customColorHsv.toString()
          : BaSeTheme.colors.institucionais.azulSebrae36,
      borderRadius: '7.5',
    },
  };
};

// *********************
//  CUSTOM COLORS ARROW BUTTON MAPS
// *********************
export const mapCustomColorsShapeButtonValues = (customColor: string) => {
  const customColorHsv = Color.generateFromHex(customColor);
  const generateColor = (color: Color): string => color.toString();

  return {
    primary: {
      background:
        customColor && customColorHsv
          ? customColorHsv.toString()
          : BaSeTheme.colors.institucionais.azulSebrae36,
      iconColor: BaSeTheme.colors.defaultColors.white,
      onHoverColor:
        customColor && customColorHsv
          ? generateColor(customColorHsv.generateDarkColor())
          : BaSeTheme.colors.institucionais.azulSebrae30,
      boxShadowFocus: `0px 0px 0px 3px ${customColor && customColorHsv
        ? generateColor(customColorHsv.generateLighterColorToFocus())
        : BaSeTheme.colors.institucionais.azulSebrae75
        }`,
      border: 'none',
    },
    secondary: {
      background: BaSeTheme.colors.defaultColors.white,
      iconColor:
        customColor && customColorHsv
          ? customColor
          : BaSeTheme.colors.institucionais.azulSebrae36,
      onHoverColor:
        customColor && customColorHsv
          ? generateColor(customColorHsv.generateLighterColorToHover())
          : BaSeTheme.colors.institucionais.azulSebrae97,
      boxShadowFocus: `0px 0px 0px 3px ${customColor && customColorHsv
        ? generateColor(customColorHsv.generateLighterColorToFocus())
        : BaSeTheme.colors.institucionais.azulSebrae75
        }`,
      border: `1px solid ${customColor && customColorHsv
        ? customColorHsv.toString()
        : BaSeTheme.colors.institucionais.azulSebrae36
        }`,
    },
    tertiary: {
      background: BaSeTheme.colors.defaultColors.transparent,
      iconColor:
        customColor && customColorHsv
          ? customColor
          : BaSeTheme.colors.institucionais.azulSebrae36,
      onHoverColor:
        customColor && customColorHsv
          ? createButtonHoverColor(customColor)
          : tertiaryMappedOpacityBlue2,
      boxShadowFocus: `0px 0px 0px 3px ${customColor && customColorHsv
        ? createButtonFocusColor(customColor)
        : tertiaryMappedOpacityBlue1
        }`,
      border: 'none',
    },
  };
};
