import * as React from 'react';
import InputMask from 'react-input-mask';
import { BaSeIcon } from '../../image/icon';
import { BaSeShapeButton } from '../../button/shape-button/shape-button';
import { BaSeButton } from '../../button/button/button';
import { MappedInputMasks, currencyMask } from '../../../utils/mask-utils';
import { BaSeTheme } from '../../../theme';
import {
  HelperButtonInterface,
  BaSePopupButton,
} from '../../helpers/popup-button/popup-button';
import {
  translationValueToName,
  mapBaSeInputColor,
  mapBaSeInputSize,
} from './map-input-style';
import { BaSeHelperText } from '../../helpers/helper-text/helper-text';
import {
  StyledInput,
  StyledLabel,
  WrapperInputlabel,
  ContainerInput,
  ButtonInfoContainer,
  WrapperInput,
  ContainerIcon,
  StyledSubLabel,
  MoreInfoContainer,
  WrapperHelper,
} from './input-styled';
import { getNextHash, idGenerator } from '../../../utils/id-generator';
import { BaSeLoadingSpinner } from '../../loading/spinner';

const idSequence = idGenerator();

interface KeyDownListenerProp {
  callback: (event: React.KeyboardEvent) => void;
  listenerKey: string;
}

interface IconButtonInputProps {
  typeButton: 'base-icon' | 'base-button' | 'base-shape-button';
  name?: string;
  action?: () => void;
  type?: 'primary' | 'secondary';
  value?: string;
  color?: string;
}

export interface InputProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  showHelpButton?: boolean;
  placeholder?: string;
  label?: string;
  complement?: string;
  size?: 'medium' | 'small' | 'big';
  typeInput?: 'password' | 'text' | 'tel' | 'email' | 'url';
  inputMode?: 'search' | 'tel' | 'email' | 'url' | 'text' | 'decimal';
  value?: string;
  width?: string | number;
  subLabel?: string;
  helpButtonProps?: HelperButtonInterface;
  moreInfoLabel?: string;
  moreInfoDetails?: string;
  onSpecificKeyDown?: KeyDownListenerProp[];
  mask?: string;
  iconButton?: IconButtonInputProps;
  validateOnChange?: (value: string) => boolean;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

const moneyDecimalMask = 'decimal';

type InputOmition =
  | 'label'
  | 'subLabel'
  | 'showHelpButton'
  | 'complement'
  | 'width'
  | 'helpButtonProps'
  | 'moreInfoLabel'
  | 'moreInfoDetails'
  | 'onChange'
  | 'mask';

interface InputWrappedMaskType extends Omit<InputProps, InputOmition> {
  validateMask: boolean;
  onKeyDownHandler: (event: React.KeyboardEvent) => void;
  onChangeValue: (newValue: string) => void;
  handleMaskType: string;
}

const InpupMaskedWrapper: React.FC<InputWrappedMaskType | any> = ({
  validateMask,
  onFocus,
  onBlur,
  onKeyDownHandler,
  value,
  onChangeValue,
  isDisabled,
  isReadOnly,
  handleMaskType,
  children,
}) => {
  if (validateMask) {
    return (
      <InputMask
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDownHandler}
        value={value}
        onChange={onChangeValue}
        mask={handleMaskType}
        disabled={isDisabled}
        readOnly={isReadOnly}
      >
        {(inputProps: any) => {
          if (React.isValidElement(children)) {
            return React.cloneElement(children, { ...inputProps });
          }
          return children;
        }}
      </InputMask>
    );
  }
  return children;
};

export const BaSeInput: React.FC<InputProps> = ({
  isLoading = false,
  isDisabled = false,
  isReadOnly = false,
  showHelpButton = false,
  placeholder = '',
  label = '',
  complement = '',
  moreInfoLabel = '',
  moreInfoDetails = '',
  size = 'medium',
  typeInput = 'text',
  inputMode = 'text',
  value = '',
  width = '100%',
  subLabel = '',
  helpButtonProps = {},
  onSpecificKeyDown = [],
  mask,
  iconButton = {
    typeButton: '',
    name: '',
    action: () => {},
    type: 'primary',
    value: '',
    color: BaSeTheme.colors.institucionais.azulSebrae36,
  },
  validateOnChange,
  onChange = () => {},
  onBlur = () => {},
  onFocus = () => {},
}) => {
  if (!['text', 'password', 'email', 'tel', 'url'].includes(typeInput)) {
    throw Error(
      'Atributo "typeInput" deve ser do tipo: text ou password ou email ou tel ou url',
    );
  }

  if (
    !['text', 'decimal', 'email', 'tel', 'url', 'search'].includes(inputMode)
  ) {
    throw Error(
      'Atributo "inputMode" deve ser do tipo: text ou decimal ou email ou tel ou url',
    );
  }

  const id = getNextHash(idSequence);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [showPassword, setShowPassword] = React.useState(false);
  const [validatedValue, setValidateValue] = React.useState(2);
  const hascomplement = complement !== '';
  const inputType = isDisabled
    ? translationValueToName[3]
    : value === ''
    ? translationValueToName[2]
    : translationValueToName[validatedValue];

  const onClickIconButton = React.useCallback(() => {
    if (typeInput === 'password') {
      return setShowPassword(
        (actualShowPassword: boolean) => !actualShowPassword,
      );
    }
    iconButton?.action?.();
    inputRef?.current?.focus?.();
  }, [typeInput, iconButton, inputRef]);

  const onChangeValue = (newValue: React.FormEvent<HTMLInputElement>) => {
    if (mask === moneyDecimalMask) {
      onChange(currencyMask(newValue.currentTarget.value));
    } else {
      onChange(newValue.currentTarget.value);
    }
  };

  const onKeyDownHandler = React.useCallback(
    (event: React.KeyboardEvent) => {
      for (const keyListener of onSpecificKeyDown) {
        if (keyListener?.listenerKey === event.key) {
          keyListener?.callback?.(event);
        }
      }
    },
    [onSpecificKeyDown],
  );

  const handleHelperColor = () => {
    const type =
      value === ''
        ? translationValueToName[2]
        : translationValueToName[validatedValue];
    return mapBaSeInputColor[type]?.color ?? mapBaSeInputColor.default.color;
  };

  const handleMaskExist = React.useCallback(() => {
    return !!(mask && mask !== moneyDecimalMask);
  }, [mask]);

  const handleMaskType = React.useCallback(() => {
    return mask && mask !== moneyDecimalMask
      ? MappedInputMasks[mask] ?? mask
      : null;
  }, [mask]);

  React.useEffect(() => {
    if (validateOnChange) {
      const wasValidated = validateOnChange(value);
      setValidateValue(Number(wasValidated));
    }
  }, [value]);

  return (
    <ContainerInput width={width}>
      <WrapperInputlabel>
        <StyledLabel
          fontSize={size === 'small' ? '13px' : '16px'}
          lineHeight={size === 'small' ? '16px' : '23px'}
          htmlFor={id}
        >
          {label}
        </StyledLabel>
        <StyledSubLabel
          fontSize={size === 'small' ? '13px' : '16px'}
          lineHeight={size === 'small' ? '16px' : '23px'}
          htmlFor={id}
        >
          {subLabel}
        </StyledSubLabel>
        {showHelpButton && (
          <ButtonInfoContainer>
            <BaSePopupButton {...helpButtonProps} />
          </ButtonInfoContainer>
        )}
      </WrapperInputlabel>
      <WrapperInput>
        <InpupMaskedWrapper
          validateMask={handleMaskExist()}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDownHandler={onKeyDownHandler}
          value={value}
          onChangeValue={onChangeValue}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          handleMaskType={handleMaskType()}
        >
          <StyledInput
            id={id}
            ref={inputRef}
            size={
              iconButton.type === 'secondary'
                ? mapBaSeInputSize[size] + 2
                : mapBaSeInputSize[size]
            }
            validateOnChange={!!validateOnChange}
            value={value}
            type={
              typeInput === 'password'
                ? !showPassword
                  ? 'password'
                  : 'text'
                : typeInput
            }
            inputMode={inputMode}
            inputType={inputType}
            typeButton={iconButton.typeButton}
            disabled={isDisabled}
            readOnly={isReadOnly}
            placeholder={placeholder}
            onChange={onChangeValue}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDownHandler}
            style={
              typeInput === 'password' || iconButton.typeButton === 'base-icon'
                ? { paddingRight: '26px' }
                : { paddingRight: '12px' }
            }
          />
        </InpupMaskedWrapper>
        <ContainerIcon
          rotation={typeInput === 'password' && showPassword}
          padding={
            isLoading ||
            typeInput === 'password' ||
            iconButton.typeButton === 'base-icon'
              ? size === 'small'
                ? 8
                : 12
              : 0
          }
        >
          {isLoading ? (
            <BaSeLoadingSpinner
              description="Carregando informação"
              diameter={size === 'big' ? 24 : 16}
              color={
                !validateOnChange || value === ''
                  ? iconButton.color
                  : mapBaSeInputColor[translationValueToName[validatedValue]]
                      .color
              }
            />
          ) : (
            <>
              {(typeInput === 'password' ||
                iconButton.typeButton === 'base-icon') && (
                <BaSeIcon
                  description="right-button-icon"
                  name={
                    typeInput === 'password'
                      ? !showPassword
                        ? 'eye-slash'
                        : 'eye'
                      : iconButton.name ?? ''
                  }
                  size={size === 'big' ? 24 : 16}
                  color={
                    !validateOnChange || value === ''
                      ? iconButton.color
                      : mapBaSeInputColor[
                          translationValueToName[validatedValue]
                        ].color
                  }
                  onClick={onClickIconButton}
                />
              )}
              {iconButton.typeButton === 'base-button' && (
                <BaSeButton
                  value={iconButton.value}
                  onClick={onClickIconButton}
                  type={iconButton.type as string}
                  leftIcon={iconButton.name}
                  size={size}
                  buttonType="button"
                  isDisabled={isDisabled}
                  color={iconButton.color}
                />
              )}
              {iconButton.typeButton === 'base-shape-button' && (
                <BaSeShapeButton
                  onClick={onClickIconButton}
                  buttonType="button"
                  nameIcon={iconButton.name ?? ''}
                  shape="square"
                  size={size}
                  type={iconButton.type as string}
                  isDisabled={isDisabled}
                  color={iconButton.color}
                />
              )}
            </>
          )}
        </ContainerIcon>
      </WrapperInput>
      {hascomplement && (
        <WrapperHelper>
          <BaSeHelperText
            isItalic={true}
            size="medium"
            color={handleHelperColor()}
            label={complement}
          />
        </WrapperHelper>
      )}
      {!!moreInfoLabel && (
        <MoreInfoContainer>
          <BaSeHelperText
            size="small"
            label={moreInfoLabel}
            details={moreInfoDetails}
          />
        </MoreInfoContainer>
      )}
    </ContainerInput>
  );
};
