import * as React from 'react';
import { AlertProps } from '../alerts-props';
import { BaSeSmall1 } from '../../typography/small/small1';
import { TriangleMapper, TriangleMapperSmaller } from './tooltip-map';
import {
  TooltipContainer,
  TooltipCard,
  TooltipTriangle,
  TooltipTriangleSmaller,
} from './tooltip-styled';

export const BaSeTooltip: React.FC<
  Omit<AlertProps, 'title' | 'handleClose'>
> = ({
  direction = 'neutral',
  message = '',
  open = false,
  color = 'filled',
  width = 106,
  coordinates = {},
  buddyRef,
  refOutput = () => {},
}) => {
  const TriangleProps = TriangleMapper[color]
    ? TriangleMapper[color][direction] ?? TriangleMapper[color].neutral
    : TriangleMapper.filled.neutral;

  const TrianglePropsSmaller = TriangleMapperSmaller[color]
    ? TriangleMapperSmaller[color][direction] ??
      TriangleMapperSmaller[color].neutral
    : TriangleMapperSmaller.filled.neutral;

  const getCoordenatesFromBuddyRef = React.useCallback(() => {
    const rect = buddyRef?.current?.getBoundingClientRect?.();

    if (!rect) {
      throw Error('Não foi possível recuperar o getBoundingClientRect');
    }

    if (direction === 'neutral') {
      throw Error('Informe uma direção aqui');
    }

    const zero = '0';
    const unset = 'unset';
    const halfHeight = `${rect.height}`;
    const heightPlusEight = `${rect.height + 8}px`;
    const widthPlusEight = `${rect.width + 8}px`;
    return {
      top: {
        top: unset,
        bottom: heightPlusEight,
        left: zero,
        right: zero,
      },
      bottom: {
        top: heightPlusEight,
        bottom: unset,
        left: zero,
        right: zero,
      },
      left: {
        top: halfHeight,
        bottom: halfHeight,
        left: unset,
        right: widthPlusEight,
      },
      right: {
        top: halfHeight,
        bottom: halfHeight,
        left: widthPlusEight,
        right: unset,
      },
    }[direction];
  }, []);

  const { top, left, bottom, right } = buddyRef?.current
    ? getCoordenatesFromBuddyRef()
    : coordinates;

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref?.current) {
      refOutput(ref?.current);
    }
  }, [ref, message]);

  return (
    <TooltipContainer
      aria-hidden={!open}
      open={open}
      ref={ref}
      top={top}
      left={left}
      bottom={bottom}
      right={right}
      width={width}
    >
      <TooltipTriangle {...TriangleProps} />
      <TooltipTriangleSmaller {...TrianglePropsSmaller} />
      <TooltipCard color={color}>
        <BaSeSmall1>{message}</BaSeSmall1>
      </TooltipCard>
    </TooltipContainer>
  );
};
