export interface AmeiConfig {
  authRealm: string;
  avatarUrl: string;
  cpeUrl: string;
}

export interface AmeiConfigByServerUrl {
  [authServerUrl: string]: AmeiConfig;
}

const ameiConfig: AmeiConfigByServerUrl = {
  'https://amei.sebrae.com.br/auth': {
    authRealm: 'externo',
    avatarUrl: 'https://minio-cpe.sebrae.com.br/foto',
    cpeUrl: 'https://cpe.sebrae.com.br',
  },
  'https://amei.homolog.kubernetes.sebrae.com.br/auth': {
    authRealm: 'dev',
    avatarUrl: 'https://minio-cpe.homologacao.sebrae.com.br/foto',
    cpeUrl: 'https://cpe-frontend.homologacao.sebrae.com.br',
  },
  'http://localhost:8080/auth': {
    authRealm: 'master',
    avatarUrl: 'https://minio-cpe.homologacao.sebrae.com.br/foto',
    cpeUrl: 'https://cpe-frontend.homologacao.sebrae.com.br',
  },
};

export const BaSeConfig = {
  docsUrl: 'https://base-react.sebrae.com.br',
  staticAssetsUrl: 'https://base-estatico.sebrae.com.br',
  ameiConfig,
};
