import styled, { css, keyframes } from 'styled-components';
import { transformLightness } from '../../utils/color-transformation/transform-lightness';
import { BaSeTheme } from './../../theme/index';
import { ThemeColorValue } from './../../theme/theme-interface';

function lighting(color: string): string {
  return transformLightness(color, 0.75);
}

export type TypeTransitionProps = 'leftToRight' | 'rightToLeft' | 'inert';
export interface StepTransitionProps {
  transition: TypeTransitionProps;
}
export interface StepOrientationProps {
  orientation?: 'horizontal' | 'vertical';
}
export interface StepColorProps {
  isActive?: boolean;
  isCompleted?: boolean;
  activeColor?: ThemeColorValue;
  completedColor?: ThemeColorValue;
  incompletedColor?: ThemeColorValue;
}

type StepperProps = StepOrientationProps & StepColorProps;

const slideIn = (origin: number) => keyframes`
  from {
    transform: translateX(${origin}%);
    opacity: 0.9;
  }
  to {
    opacity: 1;
  }
`;

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const StepperContainer = styled.div<StepOrientationProps>`
  display: flex;
  gap: 30px;
  flex-direction: ${({ orientation }) =>
    orientation === 'horizontal' ? 'column' : 'row'};
`;

export const StepsContainer = styled.ul<StepOrientationProps>`
  position: relative;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  gap: 10px;
  margin: 0;
  padding: 0;
  align-items: flex-start;

  flex-direction: ${({ orientation }) =>
    orientation === 'horizontal' ? 'row' : 'column'};
`;

export const StepContainer = styled.li<StepperProps>`
  flex: 1;
  display: grid;
  place-items: ${({ orientation }) =>
    orientation === 'horizontal' ? 'center' : 'start'};
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    ${({ orientation }) =>
      orientation === 'horizontal'
        ? css`
            width: 100%;
            height: 2px;
            top: 20px;
            left: 50%;
          `
        : css`
            width: 2px;
            height: 100%;
            top: 15px;
            left: 28px;
          `}
    z-index: ${BaSeTheme.zIndexs.units};
  }

  &::before {
    background-color: ${({ isActive, activeColor, incompletedColor }) =>
      isActive ? activeColor : lighting(incompletedColor as string)};
    ${({ orientation, isActive, isCompleted }) =>
      orientation === 'horizontal'
        ? css`
            width: ${!isCompleted || isActive ? '100%' : '0'};
          `
        : css`
            height: ${!isCompleted || isActive ? '100%' : '0'};
          `}
  }

  &::after {
    transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
    background-color: ${({ completedColor }) =>
      lighting(completedColor as string)};
    ${({ orientation, isCompleted }) =>
      orientation === 'horizontal'
        ? css`
            width: ${isCompleted ? '100%' : '0'};
          `
        : css`
            height: ${isCompleted ? '100%' : '0'};
          `}
    will-change: width;
  }

  &:last-child::before,
  &:last-child::after {
    content: none;
  }
`;

export const StepItem = styled.button<StepperProps>`
  appearance: none;
  background: ${BaSeTheme.colors.defaultColors.transparent};
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover,
  &:focus {
    outline: none;
  }

  ${({ orientation }) =>
    orientation === 'horizontal'
      ? css`
          flex-direction: column;
          justify-content: center;
        `
      : css`
          flex-direction: row;
          justify-content: flex-start;
        `}
`;

export const StepCircleHeader = styled.span<StepColorProps>`
  height: 40px;
  width: 40px;
  max-height: 40px;
  max-width: 40px;
  min-height: 40px;
  min-width: 40px;
  border: 2px solid ${BaSeTheme.colors.defaultColors.transparent};
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: ${BaSeTheme.colors.defaultColors.white};
  flex-basis: 50px;
  transition: ${`${BaSeTheme.transitions.allPropertiesContinuousNormal} ${BaSeTheme.transitions.durationNormal}`};
  border-color: ${({
    isActive,
    isCompleted,
    activeColor,
    completedColor,
    incompletedColor,
  }) =>
    isActive
      ? activeColor
      : lighting((isCompleted ? completedColor : incompletedColor) as string)};
  z-index: ${BaSeTheme.zIndexs.units + 1};
`;

export const FadableItem = styled.span<StepperProps>`
  opacity: 0;
  animation: ${fade} ${BaSeTheme.transitions.durationNormal}
    ${BaSeTheme.transitions.durationNormal} 1 forwards;
`;

export const CustomTooltip = styled.div`
  width: 100%;
  visibility: hidden;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.5s ease;
`;

export const StepText = styled(FadableItem)`
  //max-width: 100px;

  text-align: ${({ orientation }) =>
    orientation === 'horizontal' ? 'center' : 'start'};

  & > [class*='BaSe'] {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    transition: ${`${BaSeTheme.transitions.allPropertiesContinuousNormal} ${BaSeTheme.transitions.durationNormal}`};
    color: ${({
      isActive,
      isCompleted,
      activeColor,
      completedColor,
      incompletedColor,
    }) =>
      isActive ? activeColor : isCompleted ? completedColor : incompletedColor};
  }

  &:hover + ${CustomTooltip} {
    visibility: visible;
    transition: opacity 0.5s ease;
    opacity: 1;
  }
`;

export const StepperContent = styled(FadableItem)`
  overflow: hidden;
  ${({ orientation }) =>
    orientation === 'vertical' &&
    css`
      padding-left: 64px;
    `};
`;

export const StepContentTransition = styled.div<StepTransitionProps>`
  ${({ transition }) =>
    transition !== 'inert' &&
    css`
      animation: ${slideIn(transition === 'leftToRight' ? -100 : 100)}
        ${BaSeTheme.transitions.durationNormal}
        ${BaSeTheme.transitions.continuousTimingFunction};
    `};
`;
