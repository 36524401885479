import styled from 'styled-components';

interface StyledContainerInputList {
  width: number | string;
}

export const ContainerInputList = styled.div.attrs({
  className: 'BaSe--input-list-container',
}) <StyledContainerInputList>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ width }) => (typeof width === 'string' ? width : `${width}px`)};
`;

export const WrapperInputList = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
`;
