import styled from 'styled-components';
import { BaSeTheme } from '../../../public-api/others';
import { AlertStyledProps, AlertTriangleProps } from '../alerts-props';

export const PopoverContainer = styled.div.attrs({
  className: 'BaSe--Popover',
}) <AlertStyledProps>`
  ${(props) => (props.open ? 'visibility: visible;' : 'visibility: hidden;')}
  ${(props) => !props.open && 'pointer-events: none;'}
  opacity: ${(props) => (props.open ? 1 : 0)};
  position: absolute;
  ${(props) => props.top && 'top:' + props.top + ';'}
  ${(props) => props.right && 'right:' + props.right + ';'}
  ${(props) => props.bottom && 'bottom:' + props.bottom + ';'}
  ${(props) => props.left && 'left:' + props.left + ';'}
  min-width: ${(props) => props.width}px;
  z-index: ${BaSeTheme.zIndexs.tens};
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  will-change: opacity;
`;

export const PopoverCard = styled.div.attrs({
  className: 'BaSe--Popover-card',
})`
  border-radius: 4px;
  padding: 12px 24px 16px 16px;
  position: relative;
  background-color: ${BaSeTheme.colors.defaultColors.white};
  border: 1px solid ${BaSeTheme.colors.institucionais.azulSebrae90};
  box-shadow: ${BaSeTheme.shadows.profundidade4};
`;

export const PopoverCardTitleContainer = styled.div`
  padding-bottom: 1px;
  align-items: center;
  > small {
    font-weight: bold;
    color: ${BaSeTheme.colors.institucionais.azulSebrae36};
  }
`;

export const PopoverCardBodyContainer = styled.div`
  > small {
    color: ${BaSeTheme.colors.institucionais.cinzaSebrae45}
  }
`;

export const PopoverButtonContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;

  > button {
    width: 20px;
    height: 20px;
    > img {
      width: 12px;
      height: 12px;
    }
  }
`;

export const PopoverTriangle = styled.div<AlertTriangleProps>`
  width: 0;
  height: 0;
  position: absolute;
  ${(props) => props.positionTop && `top: ${props.positionTop};`}
  ${(props) => props.positionRight && `right: ${props.positionRight};`}
  ${(props) => props.positionLeft && `left: ${props.positionLeft};`}
  ${(props) => props.positionBottom && `bottom: ${props.positionBottom};`}
  border-top: ${(props) => props.widthTop};
  border-right: ${(props) => props.widthRight};
  border-left: ${(props) => props.widthLeft};
  border-bottom: ${(props) => props.widthBottom};
  z-index: ${BaSeTheme.zIndexs.tens + 1};
`;

export const PopoverTriangleSmaller = styled.div<AlertTriangleProps>`
  width: 0;
  height: 0;
  position: absolute;
  ${(props) => props.positionTop && `top: ${props.positionTop};`}
  ${(props) => props.positionRight && `right: ${props.positionRight};`}
  ${(props) => props.positionLeft && `left: ${props.positionLeft};`}
  ${(props) => props.positionBottom && `bottom: ${props.positionBottom};`}
  border-top: ${(props) => props.widthTop};
  border-right: ${(props) => props.widthRight};
  border-left: ${(props) => props.widthLeft};
  border-bottom: ${(props) => props.widthBottom};
  z-index: ${BaSeTheme.zIndexs.tens + 2};
`;
