import styled, { keyframes } from 'styled-components';
import { BaSeDialogNotificationColorType } from '../../contexts/dialog';
import { BaSeTheme } from '../../theme';
import { ThemeColorValue } from '../../theme/theme-interface';

function getBackground(color: BaSeDialogNotificationColorType): string {
  const colorMap: {
    [k in BaSeDialogNotificationColorType]: ThemeColorValue;
  } = {
    sucesso: BaSeTheme.colors.feedbackInterface.sucesso95,
    erro: BaSeTheme.colors.feedbackInterface.erro95,
    atencao: BaSeTheme.colors.feedbackInterface.atencao70,
    informacao: BaSeTheme.colors.institucionais.azulSebrae97,
  };
  return colorMap[color];
}

export function getColor(color: BaSeDialogNotificationColorType): string {
  const colorMap: {
    [k in BaSeDialogNotificationColorType]: ThemeColorValue;
  } = {
    sucesso: BaSeTheme.colors.feedbackInterface.sucesso20,
    erro: BaSeTheme.colors.feedbackInterface.erro35,
    atencao: BaSeTheme.colors.feedbackInterface.atencao35,
    informacao: BaSeTheme.colors.institucionais.azulSebrae36,
  };
  return colorMap[color];
}

const show = keyframes`
  from {
    transform: translate(-50%, -100%);
  }
  to {
    transform: translate(-50%, 0);
  }
`;

interface NotificationDialogProps {
  color: BaSeDialogNotificationColorType;
}

export const NotificationDialog = styled.div<NotificationDialogProps>`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-start;
  padding: 12px;
  border-radius: 4px;
  box-shadow: ${BaSeTheme.shadows.profundidade16};
  width: 90%;
  max-width: 390px;
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  transition: transform ${BaSeTheme.transitions.durationNormal}
    ${BaSeTheme.transitions.elasticTimingFunction};
  background: ${({ color }) => getBackground(color)};
  animation: ${show} ${BaSeTheme.transitions.durationNormal}
    ${BaSeTheme.transitions.elasticTimingFunction} 1;

  &.exiting {
    transform: translate(-50%, -100%);
  }

  .message {
    flex: 1;
  }

  h6 {
    color: ${({ color }) => getColor(color)};
  }

  p {
    color: ${BaSeTheme.colors.institucionais.cinzaSebrae30};
  }

  /*mobileSmall*/
  ${BaSeTheme.breakpoints.mobileSmall.mediaQueryCss(`
    top: unset;
    bottom: 5%;
  `)}
  /*mobile*/
  ${BaSeTheme.breakpoints.mobile.mediaQueryCss(`
    top: unset;
    bottom: 5%;
  `)}
  /*mobileLarge*/
  ${BaSeTheme.breakpoints.mobileLarge.mediaQueryCss(`
    top: unset;
    bottom: 5%;
  `)}
`;
