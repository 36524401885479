import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../../theme';
import { BaSeScrollbarCss } from '../../../theme/scrollbar';

export interface AutocompleteResultListProps {
  hasError: boolean;
  isOpen: boolean;
  attached: boolean;
}

export interface AutocompleteResultInlineProps {
  size: 'small' | 'medium' | 'big';
}

export interface AutocompleteResultItemImageProps {
  size: number;
}

export const AutocompleteWrapper = styled.div.attrs({
  className: 'BaSe--autocomplete',
})`
  position: relative;
`;

export const AutocompleteResultInline = styled.output.attrs({
  className: 'BaSe--autocomplete-result-inline',
})<AutocompleteResultInlineProps>`
  position: absolute;
  bottom: ${({ size }) =>
    size === 'small' ? 6 : size === 'medium' ? 10 : 14}px;
  left: 12px;
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae75};
  pointer-events: none;

  & > span {
    color: ${BaSeTheme.colors.defaultColors.transparent};
  }
`;

export const HideTopOfAutocompleteResultList = styled.div`
  height: 8px;
  width: calc(100% - 2px);
  background: ${BaSeTheme.colors.defaultColors.white};
  border: none;
  box-sizing: border-box;
  position: relative;
  z-index: ${BaSeTheme.zIndexs.thousands + 1};
  margin: 0;
  padding: 0;
  margin-top: -8px;
  margin-left: 1px;
`;

export const AutocompleteResultList = styled.ul.attrs({
  className: 'BaSe--autocomplete-result-list',
})<AutocompleteResultListProps>`
  display: flex;
  flex-direction: column;
  padding: 1px;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  position: absolute;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
  z-index: ${BaSeTheme.zIndexs.thousands};
  background-color: ${BaSeTheme.colors.defaultColors.white};
  border-radius: 4px;
  border: 1px solid
    ${({ hasError }) =>
      hasError
        ? BaSeTheme.colors.feedbackInterface.erro35
        : BaSeTheme.colors.institucionais.azulSebrae36};
  ${({ attached }) =>
    attached &&
    css`
      border-top: none;
      border-radius: 0px 0px 4px 4px;
    `};
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0px')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  margin-top: ${({ isOpen, attached }) =>
    isOpen && attached ? '-3px' : '4px'};
  border-width: ${({ isOpen }) => (isOpen ? '1px' : '0')};
  box-shadow: ${({ isOpen, hasError }) =>
    isOpen
      ? hasError
        ? BaSeTheme.shadows.focusError
        : BaSeTheme.shadows.focusPrimary
      : 'none'};

  ${BaSeScrollbarCss}
`;

export const AutocompleteResultListItem = styled.li`
  padding-left: 12px;
  display: flex;
  align-items: center;
  transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};
  will-change: background-color;
  padding: 8px 4px;
  gap: 16px;
  cursor: pointer;
  background-color: ${BaSeTheme.colors.contrast.institucionais.azulSebrae};

  &:hover {
    outline: none;
    background-color: ${BaSeTheme.colors.institucionais.azulSebrae97};
  }

  &.active {
    background-color: ${BaSeTheme.colors.institucionais.azulSebrae};
  }
`;

export const AutocompleteResultItemImage = styled.img<AutocompleteResultItemImageProps>`
  border: 2px solid ${BaSeTheme.colors.institucionais.cinzaSebrae75};
  object-fit: contain;
  ${({ size }) => css`
    border-radius: ${size}px;
    height: ${size}px;
    width: ${size}px;
    max-width: ${size}px;
    max-height: ${size}px;
    flex: 0 0 ${size}px;
  `}
`;
