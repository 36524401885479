import { BaSeTheme } from '../../theme';

export const TRANSITION = BaSeTheme.transitions.allPropertiesContinuousNormal;
export const BUTTON_FOCUS_BOX_SHADOW_COLOR =
  BaSeTheme.colors.institucionais.azulSebrae75;
export const BUTTON_HOVER_BACKGROUND_COLOR =
  BaSeTheme.colors.institucionais.azulSebrae30;
export const BUTTON_BACKGROUND_COLOR =
  BaSeTheme.colors.defaultColors.transparent;
export const BUTTON_HEIGHT = 32;
export const BUTTON_ICON_COLOR = BaSeTheme.colors.institucionais.azulSebrae;
export const BUTTON_ICON_SIZE = 32;
export const BUTTON_TEXT_COLOR = BaSeTheme.colors.defaultColors.white;
export const MENU_BACKGROUND_COLOR = BaSeTheme.colors.defaultColors.white;
export const MENU_BORDER_COLOR = BaSeTheme.colors.institucionais.azulSebrae90;
export const MENU_COLOR = BaSeTheme.colors.institucionais.azulSebrae;
export const MENU_EMAIL_COLOR = BaSeTheme.colors.institucionais.cinzaSebrae60;
export const MENU_ICON_SHADOW = BaSeTheme.shadows.profundidade2;
export const MENU_ICON_SIZE = 38;
export const MENU_LINK_COLOR = BaSeTheme.colors.institucionais.azulSebrae;
export const MENU_NAME_COLOR = BaSeTheme.colors.institucionais.cinzaSebrae30;
export const MENU_SEPARATOR_COLOR =
  BaSeTheme.colors.institucionais.cinzaSebrae90;
export const MENU_SHADOW = BaSeTheme.shadows.profundidade8;
