import * as React from 'react';
import { BaSeTheme } from '../../theme';
import { StepContentProps } from './step-content';
import { BaSeStepHeader, getContent, StepHeaderProps } from './step-header';
import {
  StepperContainer,
  StepsContainer,
  TypeTransitionProps,
} from './stepper-styled';

export type StepProps = Pick<
  StepHeaderProps,
  'title' | 'isDisabled' | 'tooltip'
> &
  Pick<StepContentProps, 'content'>;

export interface StepperProps
  extends Pick<
    StepHeaderProps,
    'activeColor' | 'completedColor' | 'incompletedColor' | 'orientation'
  > {
  steps: StepProps[];
  currentStep: number;
  withTransition: boolean;
  onStepChange: (step: number) => void;
}

export const BaSeStepper: React.FC<StepperProps> = ({
  steps,
  activeColor,
  completedColor,
  incompletedColor,
  currentStep = 1,
  orientation = 'horizontal',
  withTransition = false,
  onStepChange,
}: StepperProps) => {
  const [lastActivatedTabIndex, setLastActivatedTabIndex] = React.useState(
    currentStep,
  );
  const [transition, setTransition] = React.useState<TypeTransitionProps>(
    'inert',
  );

  if (!Array.isArray(steps) || steps?.length === 0) {
    throw Error('Prop "steps" é obrigatória');
  }

  if (!onStepChange) {
    throw Error('Prop "onStepChange" é obrigatória');
  }

  React.useEffect(() => {
    onStepChange(currentStep);
    if (withTransition) {
      setTransition(() =>
        lastActivatedTabIndex < currentStep ? 'leftToRight' : 'rightToLeft',
      );
      setTimeout(() => {
        setTransition(() => 'inert');
      }, BaSeTheme.transitions.durationNormalInMilliseconds);
    }
    setLastActivatedTabIndex(currentStep);
  }, [currentStep]);

  return (
    <StepperContainer className="BaSe--stepper" orientation={orientation}>
      <nav>
        <StepsContainer
          className="BaSe--stepper-container"
          orientation={orientation}
        >
          {steps.map(({ title, isDisabled, tooltip }, index) => (
            <React.Fragment key={index}>
              <BaSeStepHeader
                steps={steps}
                isDisabled={isDisabled}
                tooltip={tooltip}
                title={title}
                currentStep={index + 1}
                activeColor={activeColor}
                completedColor={completedColor}
                incompletedColor={incompletedColor}
                isActive={currentStep === index + 1}
                isCompleted={currentStep > index + 1}
                orientation={orientation}
                onActived={() => onStepChange(index + 1)}
                onStepChange={onStepChange}
                transition={transition}
              />
            </React.Fragment>
          ))}
        </StepsContainer>
      </nav>
      {orientation === 'horizontal' &&
        getContent(orientation, transition, steps, currentStep, onStepChange)}
    </StepperContainer>
  );
};
