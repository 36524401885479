import styled from 'styled-components';

import { BaSeTheme } from '../../../theme';

interface TextButtonProps {
  color: string;
  fontSize: number;
  lineHeight: number;
  hasLeftIcon: boolean;
  hasRightIcon: boolean;
  fontWeigt: string;
}

export const TextButton = styled.span<TextButtonProps>`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size : ${props => props.fontSize}em;
    font-weight : ${props => props.fontWeigt};
    font-style : normal;
    font-family : ${BaSeTheme.typography.fontFamily};
    color: ${props => props.color};
    line-height: ${props => props.lineHeight}em;
    align-self: auto;
    margin-left:
    ${props => !!props.hasLeftIcon
    ? props.fontSize > 1
      ? '12px'
      : '8px'
    : '0px'
  };
    margin-right:${props => !!props.hasRightIcon
    ? props.fontSize > 1
      ? '12px'
      : '8px'
    : '0px'
  }
`;
