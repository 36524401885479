import styled from 'styled-components';
import { BaSeTheme } from '../../theme';

interface ContainerMenuStyledProps {
  wrapperHeight: number;
  backgroundColor: string;
  borderColor: string;
  textColor: string;
  textEmailColor: string;
  iconShadow: string;
  iconSize: number;
  textNameColor: string;
  separatorColor: string;
  menuShadow: string;
  transition: string;
}

export const ContainerMenuStyled = styled.div<ContainerMenuStyledProps>`
  font-size: ${BaSeTheme.typography.fontSizeBase};
  z-index: ${BaSeTheme.zIndexs.thousands};
  height: 350px;
  min-width: 300px;
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 4px;
  top: ${(props) => props.wrapperHeight * 1.25}px;
  right: 0;
  transition: ${(props) => props.transition};
  box-shadow: ${(props) => props.menuShadow};

  &,
  * {
    font-family: ${BaSeTheme.typography.fontFamily};
  }

  &,
  &::before {
    position: absolute;
    opacity: 1;
    transition-delay: 0;
  }

  &::before {
    content: '';
    display: block;
    transform: rotate(45deg) scale(1) translateY(0);
    top: -8px;
    right: 11px;
    height: 14px;
    width: 14px;
    background: ${(props) => props.backgroundColor};
    border: 1px solid transparent;
    border-color: ${(props) => props.borderColor} transparent transparent
      ${(props) => props.borderColor};
    pointer-events: none;
  }

  .BaSe--account-profile-menu-wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: grid;
    padding: 0;
    margin: 0;
    list-style: none;

    .BaSe--account-profile-separator,
    .BaSe--account-profile-context-link,
    .BaSe--account-profile-auth-link {
      margin: 0 32px 0 70px;
    }

    .BaSe--account-profile-avatar-and-name-email {
      padding: 0;
      display: grid;
      grid-template-columns: 70px 1fr;
      place-items: center;

      & > span:not(.BaSe--account-profile-name-abbr-icon) {
        justify-self: start;
      }

      .BaSe--account-profile-name-abbr-icon,
      .BaSe--account-profile-avatar {
        object-fit: contain;
        width: ${(props) => props.iconSize}px;
        height: ${(props) => props.iconSize}px;
        border-radius: ${(props) => props.iconSize / 2}px;
        display: grid;
        place-items: center;
      }

      .BaSe--account-profile-name-abbr-icon {
        box-shadow: ${(props) => props.iconShadow};
      }

      .BaSe--account-profile-name-with-close {
        width: calc(100% - 16px);
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .BaSe--account-profile-name {
        color: ${(props) => props.textNameColor};
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .BaSe--account-profile-email {
        color: ${(props) => props.textEmailColor};
      }
    }

    .BaSe--account-profile-separator {
      border-bottom: 1px solid ${(props) => props.separatorColor};
      height: 1px;
    }

    .BaSe--account-profile-context-link,
    .BaSe--account-profile-auth-link {
      text-overflow: ellipsis;
      display: inline;
      overflow: hidden;
      white-space: nowrap;
    }

    .BaSe--account-profile-close-button {
      position: absolute;
      right: 16px;
    }
  }

  &.BaSe--account-profile-hidden {
    pointer-events: none;

    &,
    &::before {
      border: none;
    }
    &::before {
      transform: rotate(0) scale(0) translateY(100%);
    }

    &,
    .BaSe--account-profile-menu-wrapper {
      height: 0;
    }
  }
`;
