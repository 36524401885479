import styled, { css } from 'styled-components';
import { mapSizeRadio } from './map-radio-styled';
import { BaSeTheme } from '../../../theme';

interface ContainerRadioProps {
  disabled: boolean;
  checked: boolean;
}

interface LabelRadioProps {
  disabled: boolean;
  size: 'medium' | 'small';
  width?: string;
}

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const RadioMarker = styled.div<ContainerRadioProps>`
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: ${BaSeTheme.zIndexs.units};
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  background: ${({ disabled }) =>
    BaSeTheme.colors.institucionais[
      disabled ? 'cinzaSebrae75' : 'azulSebrae30'
    ]};
  ${({ checked }) =>
    checked
      ? css`
          width: calc(100% - 10px);
          height: calc(100% - 10px);
        `
      : css`
          width: 0;
          height: 0;
        `}
`;

export const ContainerRadio = styled.div<ContainerRadioProps>`
  margin: 5px;
  cursor: pointer;
  width: 18px;
  height: 18px;
  position: relative;
  display: flex;

  &::before {
    content: '';
    border-radius: 100%;
    border: 2px solid
      ${({ disabled, checked }) =>
        BaSeTheme.colors.institucionais[
          disabled
            ? 'cinzaSebrae90'
            : checked
            ? 'azulSebrae30'
            : 'cinzaSebrae75'
        ]};
    background: ${({ disabled }) =>
      disabled
        ? BaSeTheme.colors.institucionais.cinzaSebrae97
        : BaSeTheme.colors.defaultColors.white};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: ${BaSeTheme.zIndexs.default};
  }
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover,
      &.hover {
        &::before {
          border: 2px solid ${BaSeTheme.colors.institucionais.azulSebrae30};
        }
        & ${RadioMarker} {
          background: ${BaSeTheme.colors.institucionais.azulSebrae30};
        }
      }
    `};
`;

export const InputRadio = styled.input.attrs({ type: 'radio' })`
  opacity: 0;
  z-index: ${BaSeTheme.zIndexs.units + 1};
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export const LabelRadio = styled.label<LabelRadioProps>`
  display: block;
  font-size: ${({ size }) => mapSizeRadio[size] ?? mapSizeRadio.medium}px;
  width: ${({ width }) => width};
  ${({ disabled }) =>
    disabled
      ? css`
          color: ${BaSeTheme.colors.institucionais.cinzaSebrae45};
          cursor: not-allowed;
        `
      : css`
          color: ${BaSeTheme.colors.institucionais.cinzaSebrae30};
          cursor: pointer;
        `};
`;
