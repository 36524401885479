import * as React from 'react';

import { BaSeIcon } from '../../image/icon';
import {
  shapeButtonMapStyle,
  shapeButtonBorderRadius,
  shapeButtonIconMapSize,
  mapCustomColorsShapeButtonValues,
} from '../map-button-style';
import { ShapeStyle } from './shape-button-styled';
import { ButtonProps } from '../button-props';
import { BaSeTheme } from '../../../theme';

const extraSmallIconSize = { iconSize: 12 };

export const BaSeShapeButton: React.FC<Omit<ButtonProps, 'textColor'>> = ({
  nameIcon,
  type = 'primary',
  color = BaSeTheme.components.button.colors.default,
  shape = 'circle',
  sizeIcon = 'small',
  size = 'medium',
  isDisabled = false,
  onClick,
  buttonType = 'button',
  sideButton = 'none',
  autoFocus = false,
}) => {
  if (!nameIcon) {
    throw Error('Nome do ícone obrigatório!');
  }

  const borderRadius =
    shapeButtonBorderRadius[shape] ?? shapeButtonBorderRadius.circle;

  const { iconSize } =
    size === 'extra-small' || size === 'mega-small'
      ? extraSmallIconSize
      : shapeButtonIconMapSize[sizeIcon] ?? shapeButtonIconMapSize.medium;

  // TODO molhorar código
  const padding =
    size === 'big'
      ? (40 - iconSize) / 2 + 4
      : size === 'medium'
        ? (40 - iconSize) / 2
        : size === 'small' || size === 'extra-small'
          ? 4
          : 1.5;

  const colorIsMapped =
    color === BaSeTheme.components.button.colors.default ||
    color === BaSeTheme.components.button.colors.negative;

  const getColorsAtributes = () => {
    if (color) {
      if (!colorIsMapped) {
        return (
          mapCustomColorsShapeButtonValues(color)?.[type] ??
          mapCustomColorsShapeButtonValues(color).primary
        );
      } else {
        return (
          shapeButtonMapStyle[type]?.[color] ??
          shapeButtonMapStyle.primary?.[color]
        );
      }
    } else {
      return (
        shapeButtonMapStyle[type]?.default ??
        shapeButtonMapStyle.primary.default
      );
    }
  };

  const typeMapped = isDisabled
    ? shapeButtonMapStyle[type]?.disabled ??
    shapeButtonMapStyle.primary.disabled
    : getColorsAtributes();

  const {
    background,
    border,
    iconColor,
    onHoverColor,
    boxShadowFocus,
    borderHoverColor,
    iconHoverColor,
  } = typeMapped;

  const [isHover, setIsHover] = React.useState<boolean>(false);

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      buttonRef?.current?.focus();
      onClick(event);
    },
    [onClick, buttonRef],
  );

  return (
    <ShapeStyle
      ref={buttonRef}
      className="BaSe--button-shape"
      autoFocus={autoFocus}
      disabled={isDisabled}
      onClick={handleClick}
      type={buttonType}
      border={isHover && !!borderHoverColor ? borderHoverColor : border}
      borderRadius={borderRadius}
      backgroundColor={background}
      backgroundOnHover={onHoverColor}
      boxShadowOnFocus={
        size.includes('small')
          ? boxShadowFocus.replace('3', '2')
          : boxShadowFocus
      }
      padding={border === 'none' ? padding : padding - 1}
      sideButton={sideButton}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <BaSeIcon
        description="shape-icon"
        name={nameIcon}
        size={iconSize}
        color={isHover && !!iconHoverColor ? iconHoverColor : iconColor}
      />
    </ShapeStyle>
  );
};
