import * as React from 'react';
import {
  BaSeDialogNotificationColorType,
  BaSeDialogNotificationInterface,
} from '../../contexts/dialog';
import { useNextCounterId } from '../../hooks/next-id';
import { BaSeTheme } from '../../theme';
import { idGenerator } from '../../utils/id-generator';
import { BaSeButton } from '../button/button/button';
import { BaSeShapeButton } from '../button/shape-button/shape-button';
import { BaSeIcon } from '../image/icon';
import { BaSeHeading6 } from '../typography/heading/heading6';
import { BaSeParagraph } from '../typography/paragraph/paragraph';
import { getColor, NotificationDialog } from './notification-styled';

enum ExitingMode {
  'forced',
  'auto',
  'action',
}
const iconColorMap: { [k in BaSeDialogNotificationColorType]: string } = {
  atencao: 'exclamation-triangle',
  informacao: 'info-circle',
  erro: 'exclamation-octagon',
  sucesso: 'check-circle',
};

const idSequence = idGenerator();

export const BaSeNotification = ({
  color,
  icon,
  message,
  supportMessage,
  secondsToClose = 5,
  action,
  onClose,
  onAction,
}: Omit<BaSeDialogNotificationInterface, 'id'>) => {
  let timeout: ReturnType<typeof setTimeout>;

  const ramdom = useNextCounterId(idSequence);
  const iconNameDefault = iconColorMap[color];
  const iconName = icon === false ? undefined : icon ?? iconNameDefault;

  const [exiting, setExiting] = React.useState<ExitingMode>();

  React.useEffect(() => {
    if (exiting === ExitingMode.forced) {
      setTimeout(
        () => onClose(true),
        BaSeTheme.transitions.durationFastInMilliseconds,
      );
    }
    if (exiting === ExitingMode.auto) {
      setTimeout(
        () => onClose(false),
        BaSeTheme.transitions.durationFastInMilliseconds,
      );
    }
    if (exiting === ExitingMode.action) {
      setTimeout(
        () => onAction(),
        BaSeTheme.transitions.durationFastInMilliseconds,
      );
    }
  }, [exiting]);

  React.useEffect(() => {
    if (secondsToClose > 0) {
      timeout = setTimeout(
        () => setExiting(ExitingMode.auto),
        secondsToClose * 1000,
      );
    }
    return () => clearTimeout(timeout);
  }, []);

  function forceClose(): void {
    clearTimeout(timeout);
    setExiting(ExitingMode.forced);
  }

  function actionClose(): void {
    clearTimeout(timeout);
    setExiting(ExitingMode.action);
  }

  return (
    <NotificationDialog
      className={`BaSe--notification-dialog ${
        exiting !== undefined ? 'exiting' : ''
      }`}
      color={color}
      role="dialog"
      aria-modal="false"
      aria-labelledby={`BaSe--notification-title-${ramdom}`}
      aria-describedby={`BaSe--notification-description-${ramdom}`}
    >
      {iconName && (
        <span className="icon-wrapper">
          <BaSeIcon
            color={getColor(color)}
            name={iconName}
            size={26}
            description="Ícone sinalizando a notificação"
          />
        </span>
      )}
      <div className="message">
        <span id={`BaSe--notification-title-${ramdom}`}>
          <BaSeHeading6 isBold={true}>{message}</BaSeHeading6>
        </span>
        {supportMessage && (
          <span id={`BaSe--notification-description-${ramdom}`}>
            <BaSeParagraph>{supportMessage}</BaSeParagraph>
          </span>
        )}
      </div>
      {action && (
        <BaSeButton
          buttonType="button"
          onClick={() => actionClose()}
          size="small"
          type="tertiary"
          color={getColor(color)}
          value={action}
        />
      )}
      <BaSeShapeButton
        buttonType="button"
        nameIcon="close"
        shape="circle"
        size="small"
        sizeIcon="small"
        type="tertiary"
        color={getColor(color)}
        onClick={() => forceClose()}
      />
    </NotificationDialog>
  );
};
