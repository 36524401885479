import * as React from 'react';

import { dualButtonMapStyle } from '../map-button-style';
import { BaSeTextButton } from '../text-button/text-button';
import { ContainerStyled, LeftStyledButton, RightStyledButton } from './dual-button-styled';

interface DualButtonOption {
  value: any;
  label: string;
  width: string | number;
}

interface DualButtonProps {
  type: string;
  selected: 'right' | 'left';
  left: DualButtonOption;
  right: DualButtonOption;
  hasError: boolean;
  onChange: (selectedValue: any) => void;
  buttonType: 'submit' | 'reset' | 'button';
}

export const BaSeDualButton: React.FC<DualButtonProps> = ({
  buttonType = 'button',
  right = {
    value: true,
    label: 'Valor da direita',
    width: 'auto',
  },
  left = {
    value: false,
    label: 'Valor da esquerda',
    width: 'auto',

  },
  selected = 'left',
  onChange = () => { },
  type = 'primary-default',
  hasError = false,
}) => {

  const [isLeftMarked, setLeftMarked] = React.useState(selected === 'left');
  const typeSplit = type.split('-');
  const typeMapped = hasError
    ? dualButtonMapStyle[typeSplit[0]]?.error ?? dualButtonMapStyle.primary.error
    : dualButtonMapStyle[typeSplit[0]]?.[typeSplit[1]] ?? dualButtonMapStyle.primary.default;

  const typeMappedSelected = hasError
    ? dualButtonMapStyle[typeSplit[0]]?.error ?? dualButtonMapStyle.primary.error
    : dualButtonMapStyle.selected?.[typeSplit[1]] ?? dualButtonMapStyle.selected.default;

  const { color, ...typeStyle } = typeMapped;
  const { color: colorSelected, ...typeStyleSelected } = typeMappedSelected;

  const leftButtonProps = isLeftMarked
    ? typeStyleSelected
    : typeStyle;
  const StyledButtonRight = !isLeftMarked
    ? typeStyleSelected
    : typeStyle;

  return (
    <ContainerStyled>
      <LeftStyledButton
        {...leftButtonProps}
        type={buttonType}
        width={left.width}
        isLeftMark={isLeftMarked}
        onClick={() => {
          onChange(left.value);
          setLeftMarked(true);
        }}
      >
        <BaSeTextButton
          color={isLeftMarked ? colorSelected : color}
          fontSize={1}
          lineHeight={1.4}
          hasLeftIcon={false}
          hasRightIcon={false}
          fontWeigt="normal"
        >
          {left.label}
        </BaSeTextButton>
      </LeftStyledButton>
      <RightStyledButton
        {...StyledButtonRight}
        type={buttonType}
        width={right.width}
        onClick={() => {
          onChange(right.value);
          setLeftMarked(false);
        }}>
        <BaSeTextButton
          color={!isLeftMarked ? colorSelected : color}
          fontSize={1}
          lineHeight={1.4}
          hasLeftIcon={false}
          hasRightIcon={false}
          fontWeigt="normal"
        >
          {right.label}
        </BaSeTextButton>
      </RightStyledButton>
    </ContainerStyled >
  );
};
