export const Coordinates: any = (width: number, height: (number | null) = null, size: number = 13) => {

  const half = (size / 2) + 4; // Add de 4 pixels pelo padding do ShapeButton
  const spaceToIcon = 5;
  const boundaries = 7;

  return (
    {
      top: {
        bottom: `calc(100% + ${spaceToIcon}px)`,
        left: `calc(-${width / 2}px + ${half}px)`,
      },
      right: {
        top: height ? `calc(-${height / 2}px + ${half}px)` : '',
        left: `calc(100% + ${boundaries}px)`,
      },
      left: {
        top: height ? `calc(-${height / 2}px + ${half}px)` : '',
        right: `calc(100% + ${boundaries}px)`,
      },
      bottom: {
        top: `calc(100% + ${spaceToIcon}px)`,
        left: `calc(-${width / 2}px + ${half}px)`,
      },
      neutral: {
        bottom: `calc(100% + ${spaceToIcon}px)`,
        left: `calc(-${width / 2}px + ${half}px)`,
      },
    }
  );
};
