import styled from 'styled-components';
import { BaSeTheme } from '../../public-api/others';
import {
  mapTogglerType,
  mapTogglerTypeSpan,
} from './map-toggler-style';

interface StyledTogglerLabelProps {
  isDisabled: boolean;
  type: string;
  value: boolean;
}

interface StyledTogglerSpanProps {
  value: boolean;
  isDisabled: boolean;
  type: string;
}

const checkStatus = (props: StyledTogglerSpanProps | StyledTogglerLabelProps): string => {
  return props.isDisabled ? 'disabled' : props.value ? 'checked' : 'default';
};

export const StyledTogglerLabel = styled.label<StyledTogglerLabelProps>`
  display: flex;
  width: 58px;
  height: 24px;
  border: ${props => mapTogglerType[props.type][checkStatus(props)].border};
  background: ${props => mapTogglerType[props.type][checkStatus(props)].background};
  position: relative;
  border-radius: 26px;
  ${props => !props.isDisabled && 'cursor: pointer;'}
  transition: 250ms ease-in-out;
`;

export const StyledTogglerInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const StyledTogglerSpan = styled.span<StyledTogglerSpanProps>`
  position: absolute;
  width: ${props => mapTogglerTypeSpan[props.type].size};
  height: ${props => mapTogglerTypeSpan[props.type].size};
  background: ${props => mapTogglerTypeSpan[props.type][checkStatus(props)].background};
  border : ${props => mapTogglerTypeSpan[props.type][checkStatus(props)].border};
  transition: 250ms ease-in-out;
  border-radius: 40px;
  top: ${props => mapTogglerTypeSpan[props.type].position.top};
  left: ${props => mapTogglerTypeSpan[props.type].position.left};
  z-index: ${BaSeTheme.zIndexs.tens};
  ${props => props.value && !props.isDisabled && 'transform:' + mapTogglerTypeSpan[props.type].transform}
`;

export const StyledTogglerIconContainer = styled.div`
position: absolute;
top: 2px;
left: 2px;
`;
