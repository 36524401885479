import * as React from 'react';

import { BaSeIcon } from '../../image/icon';
import { BaSeTextButton } from '../text-button/text-button';
import { BaSeLoadingSpinner } from '../../loading/spinner';
import { StyledButton, SpinnerContainer } from './button-styled';
import {
  mapButtonValues,
  styleMapSize,
  mapCustomColorsButtonValues,
} from '../map-button-style';
import { ButtonProps } from '../button-props';
import { BaSeTheme } from '../../../theme';

export const BaSeButton: React.FC<ButtonProps> = ({
  type = 'primary',
  value = 'Valor default',
  size = 'medium',
  width = '',
  rightIcon = '',
  leftIcon = '',
  isBold = false,
  spacedIcons = false,
  isDisabled = false,
  onClick = () => { },
  buttonType = 'button',
  color = 'default',
  autoFocus = false,
  isLoading = false,
  loadingText = 'Carregando…',
}) => {
  const hasLeftIcon = leftIcon !== '';
  const hasRightIcon = rightIcon !== '';
  const fontWeigt = isBold ? 'bold' : 'normal';
  const justifyContent =
    spacedIcons && (hasLeftIcon || hasRightIcon) ? 'space-between' : 'center';
  const minWidth = (Number(hasLeftIcon) + Number(hasRightIcon)) * 30 + 50;
  const sizeValues = styleMapSize[size] ?? styleMapSize.medium;
  const colorIsMapped =
    color === BaSeTheme.components.button.colors.default ||
    color === BaSeTheme.components.button.colors.destructive ||
    color === BaSeTheme.components.button.colors.confirmation ||
    color === BaSeTheme.components.button.colors.negative;
  const getColorsAtributes = () => {
    if (color) {
      if (!colorIsMapped) {
        return (
          mapCustomColorsButtonValues(color)?.[type] ??
          mapCustomColorsButtonValues(color).primary
        );
      } else {
        return (
          mapButtonValues[type]?.[color] ?? mapButtonValues.primary?.[color]
        );
      }
    } else {
      return mapButtonValues[type]?.default ?? mapButtonValues.primary.default;
    }
  };
  const buttonValues = isDisabled
    ? color === 'negative'
      ? mapButtonValues[type].negative?.disabled
      : mapButtonValues[type]?.disabled ?? mapButtonValues.primary.disabled
    : getColorsAtributes();

  const {
    style: { bgC, bcCHover, boxShadowFocus, border, colorHover },
    iconColor,
    color: buttonColor,
  } = buttonValues;

  const { fontSize, lineHeight, padding, loadingSize } = sizeValues;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!isLoading) {
      buttonRef?.current?.focus();
      onClick(e);
    }
  };

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [hasHover, setHasHover] = React.useState<boolean>(false);
  const checkHover = React.useCallback(
    (defaultColor: string) => {
      return (type !== 'primary' ||
        (type === 'primary' && color === 'negative')) &&
        hasHover &&
        !!colorHover
        ? colorHover
        : defaultColor;
    },
    [type, hasHover, colorHover],
  );

  return (
    <StyledButton
      ref={buttonRef}
      className="BaSe--button"
      autoFocus={autoFocus}
      onClick={handleClick}
      disabled={isDisabled}
      type={buttonType}
      backgroundColor={bgC}
      backgroundOnHover={bcCHover}
      border={border}
      hideBorder={type === 'secondary' && color === 'negative'}
      padding={padding}
      width={width}
      minWidth={minWidth}
      justifyContent={justifyContent}
      boxShadowFocus={boxShadowFocus}
      onMouseEnter={() => setHasHover(true)}
      onMouseLeave={() => setHasHover(false)}
      onFocus={() => setHasHover(true)}
      onBlur={() => setHasHover(false)}
      aria-label={isLoading ? loadingText : ''}
      boxShadow={type !== 'tertiary' && color !== 'negative'}
    >
      {isLoading ? (
        <>
          <SpinnerContainer size={size}>
            <BaSeLoadingSpinner
              description="Carregando… Aguarde."
              diameter={loadingSize}
              color={color}
              type={type}
              disabled={isDisabled}
            />
          </SpinnerContainer>
          <BaSeTextButton
            fontSize={fontSize}
            lineHeight={lineHeight}
            color={checkHover(buttonColor)}
            hasLeftIcon={hasLeftIcon}
            hasRightIcon={hasRightIcon}
            fontWeigt={fontWeigt}
          >
            {loadingText}
          </BaSeTextButton>
        </>
      ) : (
        <>
          {hasLeftIcon && (
            <BaSeIcon
              description="left-button-icon"
              name={leftIcon}
              size={Math.floor(fontSize * 16)}
              color={checkHover(iconColor)}
            />
          )}
          <BaSeTextButton
            fontSize={fontSize}
            lineHeight={lineHeight}
            color={checkHover(buttonColor)}
            hasLeftIcon={hasLeftIcon}
            hasRightIcon={hasRightIcon}
            fontWeigt={fontWeigt}
          >
            {value}
          </BaSeTextButton>
          {hasRightIcon && (
            <BaSeIcon
              description="right-button-icon"
              name={rightIcon}
              size={Math.floor(fontSize * 16)}
              color={checkHover(iconColor)}
            />
          )}
        </>
      )}
    </StyledButton>
  );
};
