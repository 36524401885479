import * as React from 'react';
import { useNextHashId } from '../../../hooks/next-id';
import { BaSeTheme } from '../../../theme';
import { idGenerator } from '../../../utils/id-generator';
import { BaSeIcon } from '../../image/icon';
import {
  CheckboxContainer,
  HiddenCheckbox,
  IndeterminateLine,
  WrapperCheckbox,
  StyledCheckbox,
  StyledLabel,
  StyledImageWrapper,
} from './checkbox-styled';

interface BaSeCheckboxProps {
  value: any | null;
  label?: string;
  disabled?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
  size?: 'medium' | 'small';
  // color?: string;
  onChange: (checked?: boolean, value?: any, event?: any) => void;
}

const idSequence = idGenerator();

export const BaSeCheckbox: React.FC<BaSeCheckboxProps> = ({
  label = '',
  value = null,
  disabled = false,
  checked = false,
  indeterminate = false,
  size = 'medium',
  onChange = () => {},
}) => {
  const idCheckbox = useNextHashId(idSequence);

  const [focus, setFocus] = React.useState(false);
  const [hover, setHover] = React.useState(false);

  const onCheckOrUncheck = (event?: any) => {
    if (!disabled) {
      onChange(!checked, value, event);
    }
  };

  value = value ?? label;

  return (
    <WrapperCheckbox>
      <CheckboxContainer disabled={disabled}>
        <HiddenCheckbox
          defaultValue={value}
          id={idCheckbox}
          checked={checked}
          disabled={disabled}
          onChange={onCheckOrUncheck}
          onPointerEnter={() => setHover(true)}
          onFocus={() => setFocus(true)}
          onPointerLeave={() => setHover(false)}
          onBlur={() => setFocus(false)}
          onKeyPress={(event) =>
            event.key === 'Enter' && onCheckOrUncheck(event)
          }
        />
        <StyledCheckbox
          hover={hover}
          focus={focus}
          disabled={disabled}
          checked={checked}
        >
          <StyledImageWrapper checked={checked} indeterminate={indeterminate}>
            <BaSeIcon
              description="checkbox-icon"
              name="check"
              color={BaSeTheme.colors.defaultColors.white}
            />
          </StyledImageWrapper>
          {indeterminate && <IndeterminateLine />}
        </StyledCheckbox>
      </CheckboxContainer>

      <StyledLabel htmlFor={idCheckbox} size={size} disabled={disabled}>
        {label}
      </StyledLabel>
    </WrapperCheckbox>
  );
};
