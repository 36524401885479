import React from 'react';
import ReactDOM from 'react-dom';
import reactToWebComponent from 'react-to-webcomponent';
import PropTypes from 'prop-types';
import { BaSeAccountProfile } from '../build';
import '../styles/theme.css';

function WebComponentBaSeAccountProfile({
  // estáticas
  ['client-name']: clientName,
  ['client-id']: clientId,
  ['client-uri']: clientUri,
  ['auth-server-url']: authServerUrl,
  ['auth-realm']: authRealm,
  ['avatar-url']: avatarUrl,
  ['cpe-url']: cpeUrl,

  // runtime
  keycloak = null,
  authenticated = false,
  userName = '',
  userEmail = '',
  userId = '',
  contextLinks = [],
}) {
  const [, forceUpdateState] = React.useReducer((state) => state + 1, 0);

  React.useEffect(() => {
    if (keycloak) {
      keycloak.onAuthSuccess = () => {
        console.log('Auth', '😃 autenticado com sucesso');
        forceUpdateState();
      };

      keycloak.onAuthLogout = () => {
        console.log('Auth', '👋 fez o logout');
        forceUpdateState();
      };

      keycloak.onTokenExpired = () => {
        console.log('Auth', '🔄 token expirou');
        keycloak
          .updateToken(5)
          .then((refreshed) => {
            if (refreshed) {
              console.log('Auth', '😃 token foi atualizado');
            } else {
              console.log('Auth', '😃 token continua válido');
            }
            forceUpdateState();
          })
          .catch((error) =>
            console.log(
              'Auth',
              '😖 deu ruim ao atualizar o token ou a sessão expirou',
              error,
            ),
          )
          .finally(() => console.log('Auth', '⚠️ finally updateToken'));
      };
    }
  }, [keycloak]);

  return (
    <BaSeAccountProfile
      keycloak={keycloak}
      // or
      authServerUrl={authServerUrl}
      authRealm={authRealm}
      avatarUrl={avatarUrl}
      cpeUrl={cpeUrl}
      clientName={clientName}
      clientId={clientId}
      clientUri={clientUri}
      authenticated={authenticated}
      userName={userName}
      userEmail={userEmail}
      userId={userId}
      contextLinks={contextLinks}
    />
  );
}

WebComponentBaSeAccountProfile.propTypes = {
  // estáticas
  'client-name': PropTypes.string,
  'client-uri': PropTypes.string,
  'client-id': PropTypes.string,
  'auth-server-url': PropTypes.string,
  'auth-realm': PropTypes.string,
  'avatar-url': PropTypes.string,
  'cpe-url': PropTypes.string,
  // runtime
  keycloak: PropTypes.any,
  authenticated: PropTypes.bool,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  userId: PropTypes.string,
  contextLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};

customElements.define(
  'base-account-profile',
  reactToWebComponent(WebComponentBaSeAccountProfile, React, ReactDOM),
);
