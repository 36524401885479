import styled from 'styled-components';
import { BaSeTheme } from '../../theme';
import { TypographyProps } from './typography-props';

export const makeDefaultTypographytStyled = (
  element: string,
  tagName: string,
  fontSize: number,
  lineHeight: number,
) => styled[tagName].attrs({
  className: `BaSe--${element.toLocaleLowerCase()}`,
})`
  font-size: ${fontSize}em;
  font-style: ${(props: TypographyProps) =>
    props?.isItalic ? 'italic' : 'normal'};
  font-family: '${BaSeTheme.typography.fontFamily}';
  font-weight: ${(props: TypographyProps) =>
    props?.isThin ? '300' : props?.isBold ? '700' : '400'};
  color: ${(props: TypographyProps) =>
    props?.color ? props?.color : BaSeTheme.colors.institucionais.cinzaSebrae};
  line-height: ${lineHeight}em;
`;
