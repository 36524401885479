import { ThemeBreakpointInterface, ThemeBreakpointsType } from './theme-interface';

const desktopLarge: ThemeBreakpointInterface = {
  type: 'desktop',
  maxWidth: Number.MAX_SAFE_INTEGER,
  minWidth: 1440,
  gap: 16,
  mediaQuery: '',
  mediaQuerySelector: '',
  mediaQueryCss: () => '',
};
const desktop: ThemeBreakpointInterface = {
  type: 'desktop',
  maxWidth: 1439,
  minWidth: 1280,
  gap: 16,
  mediaQuery: '',
  mediaQuerySelector: '',
  mediaQueryCss: () => '',
};
const tablet: ThemeBreakpointInterface = {
  type: 'tablet',
  maxWidth: 1279,
  minWidth: 768,
  gap: 16,
  mediaQuery: '',
  mediaQuerySelector: '',
  mediaQueryCss: () => '',
};
const mobileLarge: ThemeBreakpointInterface = {
  type: 'mobile',
  maxWidth: 767,
  minWidth: 375,
  gap: 16,
  mediaQuery: '',
  mediaQuerySelector: '',
  mediaQueryCss: () => '',
};
const mobile: ThemeBreakpointInterface = {
  type: 'mobile',
  maxWidth: 374,
  minWidth: 360,
  gap: 32,
  mediaQuery: '',
  mediaQuerySelector: '',
  mediaQueryCss: () => '',
};
const mobileSmall: ThemeBreakpointInterface = {
  type: 'mobile',
  maxWidth: 359,
  minWidth: 320,
  gap: 32,
  mediaQuery: '',
  mediaQuerySelector: '',
  mediaQueryCss: () => '',
};

desktopLarge.mediaQuery = `(min-width: ${desktopLarge.minWidth}px)`;
desktop.mediaQuery = `(min-width: ${desktop.minWidth}px) and (max-width: ${desktop.maxWidth}px)`;
tablet.mediaQuery = `(min-width: ${tablet.minWidth}px) and (max-width: ${tablet.maxWidth}px)`;
mobileLarge.mediaQuery = `(min-width: ${mobileLarge.minWidth}px) and (max-width: ${mobileLarge.maxWidth}px)`;
mobile.mediaQuery = `(min-width: ${mobile.minWidth}px) and (max-width: ${mobile.maxWidth}px)`;
mobileSmall.mediaQuery = `(max-width: ${mobileSmall.maxWidth}px)`;

desktopLarge.mediaQuerySelector = `@media screen and ${desktopLarge.mediaQuery}`;
desktop.mediaQuerySelector = `@media screen and ${desktop.mediaQuery}`;
tablet.mediaQuerySelector = `@media screen and ${tablet.mediaQuery}`;
mobileLarge.mediaQuerySelector = `@media screen and ${mobileLarge.mediaQuery}`;
mobile.mediaQuerySelector = `@media screen and ${mobile.mediaQuery}`;
mobileSmall.mediaQuerySelector = `@media screen and ${mobileSmall.mediaQuery}`;

desktopLarge.mediaQueryCss = (content: string) => `${desktopLarge.mediaQuerySelector} { ${content} }`;
desktop.mediaQueryCss = (content: string) => `${desktop.mediaQuerySelector} { ${content} }`;
tablet.mediaQueryCss = (content: string) => `${tablet.mediaQuerySelector} { ${content} }`;
mobileLarge.mediaQueryCss = (content: string) => `${mobileLarge.mediaQuerySelector} { ${content} }`;
mobile.mediaQueryCss = (content: string) => `${mobile.mediaQuerySelector} { ${content} }`;
mobileSmall.mediaQueryCss = (content: string) => `${mobileSmall.mediaQuerySelector} { ${content} }`;

export const BaSeBreakpoints: ThemeBreakpointsType = {
  mobileSmall,
  mobile,
  mobileLarge,
  tablet,
  desktop,
  desktopLarge,
};
