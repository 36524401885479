import * as React from 'react';
import { BaSeLoadingSpinner } from './spinner';
import { BaSeHeading5 } from '../typography/heading/heading5';
import { BaSeTheme } from '../../theme';
import { LoadingContainer, LoadingItems } from './styled';

const diameterBaseRadius = 24;

export interface LoadingProps {
  text?: string;
  textColor?: string;
  spinnerColor?: string;
  progress?: number;
  lightMode?: 'default' | 'negative';
  shadowLevel?: number;
  backgroundColor?: string;
  spinnerSize?: number;
  roundedCorner?: boolean;
}

const checkNumber = (num: number | undefined) => {
  return typeof num === 'number';
};

const handleFloor = (num: number) => {
  num = num < 0 ? 0 : num > 100 ? 100 : num;
  return Math.floor(num);
};

export const BaSeLoading: React.FC<LoadingProps> = ({
  text = 'Carregando…',
  textColor,
  spinnerColor,
  progress,
  lightMode = 'default',
  shadowLevel = 0,
  backgroundColor,
  spinnerSize,
  roundedCorner,
}) => {
  const [accessibility, setAcessibility] = React.useState({});

  const handleColor = () => {
    if (textColor) {
      return textColor;
    }
    return lightMode === 'default'
      ? BaSeTheme.colors.institucionais.azulSebrae
      : BaSeTheme.colors.defaultColors.white;
  };

  const handleShadow = () => {
    if (!shadowLevel || shadowLevel < 0) {
      return 'none;';
    }
    const options = [2, 4, 8, 16, 32, 64];
    const filtered = options.find((opt) => opt === shadowLevel);
    if (filtered) {
      return BaSeTheme.shadows['profundidade' + filtered];
    } else {
      let index = -1;
      let space = 99;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < options.length; i++) {
        if (space >= Math.abs(shadowLevel - options[i])) {
          index = i;
          space = Math.abs(shadowLevel - options[i]);
        }
      }
      return BaSeTheme.shadows['profundidade' + options[index]];
    }
  };

  React.useEffect(() => {
    if (checkNumber(progress)) {
      setAcessibility({
        'aria-valuenow': handleFloor(progress ?? 0),
        'aria-valuemin': 0,
        'aria-valuemax': 100,
      });
    }
  }, [progress]);
  return (
    <LoadingContainer
      className="BaSe--loading"
      roundedCorner={roundedCorner}
      shadow={handleShadow()}
      backgroundColor={backgroundColor}
    >
      <LoadingItems
        role={checkNumber(progress) ? 'slider' : 'presentation'}
        {...accessibility}
      >
        <BaSeLoadingSpinner
          color={spinnerColor}
          type={lightMode === 'default' ? 'tertiary' : 'primary'}
          description={false}
          diameter={spinnerSize ?? diameterBaseRadius}
        />
        <BaSeHeading5 color={handleColor()}>
          {text} {checkNumber(progress) && `(${handleFloor(progress ?? 0)}%)`}
        </BaSeHeading5>
      </LoadingItems>
    </LoadingContainer>
  );
};
