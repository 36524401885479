import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../../theme';
import { mapBaSeInputColor } from './map-input-style';

interface StyledInputProps {
  inputType: 'default' | 'validated' | 'error' | 'disabled';
  typeButton: string;
  validateOnChange: boolean;
  value: string;
}
interface StyledContainerInput {
  width: number | string;
}

interface StyledLabelProps {
  fontSize: string;
  lineHeight: string;
}

interface StyledApoioProps {
  inputType: string;
}

interface ContainerIconProps {
  padding: number;
  rotation: boolean;
}

export const StyledInput = styled.input.attrs({
  className: 'BaSe--input-input',
})<StyledInputProps>`
  padding-left: 12px;
  width: 100%;
  color: ${(props) =>
    mapBaSeInputColor[props.inputType]?.color ??
    mapBaSeInputColor.default.color};
  background: ${(props) =>
    mapBaSeInputColor[props.inputType]?.backgroundColor ??
    mapBaSeInputColor.default.backgroundColor};
  border: 1px solid
    ${(props) =>
      mapBaSeInputColor[props.inputType]?.color ??
      mapBaSeInputColor.default.color};
  border-radius: ${(props) =>
    props.typeButton === 'base-icon' || !props.typeButton
      ? '4px'
      : '4px 0px 0px 4px'};
  height: ${(props) => props.size}px;
  &:focus {
    outline: none !important;
    box-shadow: ${({ inputType }) =>
      inputType === 'validated'
        ? BaSeTheme.shadows.focusValidated
        : inputType === 'error'
        ? BaSeTheme.shadows.focusError
        : BaSeTheme.shadows.focusPrimary};
    border: 1px solid
      ${(props) =>
        props.validateOnChange && props.value !== ''
          ? mapBaSeInputColor[props.inputType]?.color ??
            mapBaSeInputColor.default.color
          : BaSeTheme.colors.institucionais.azulSebrae36};
    color: ${(props) =>
      props.validateOnChange && props.value !== ''
        ? mapBaSeInputColor[props.inputType]?.color ??
          mapBaSeInputColor.default.color
        : BaSeTheme.colors.institucionais.azulSebrae36};
  }
  &:invalid {
    outline: none !important;
    box-shadow: ${BaSeTheme.shadows.focusError};
    color: ${mapBaSeInputColor['error']?.color};
    border: 1px solid ${mapBaSeInputColor['error']?.color};
  }
`;

export const StyledLabel = styled.label.attrs({
  className: 'BaSe--input-label',
})<StyledLabelProps>`
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae45};
  font-family: ${BaSeTheme.typography.fontFamily};
  font-style: normal;
  font-weight: normal;
`;

export const StyledApoio = styled.span<StyledApoioProps>`
  font-size: 0.83em;
  color: ${(props) =>
    mapBaSeInputColor[props.inputType]?.color ??
    mapBaSeInputColor.default.color};
  margin-top: 3px;
  line-height: 1.2em;
  font-family: ${BaSeTheme.typography.fontFamily};
  font-style: italic;
  font-weight: normal;
`;

export const WrapperInputlabel = styled.div.attrs({
  className: 'BaSe--input-wrapper-label',
})`
  display: flex;
  flex-direction: row;
  z-index: ${BaSeTheme.zIndexs.tens};
  background-color: ${BaSeTheme.colors.defaultColors.transparent};
  position: relative;
  margin: 0;
  padding: 0;
  margin-bottom: 2px;
`;

export const ContainerInput = styled.div.attrs({
  className: 'BaSe--input',
})<StyledContainerInput>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ width }) => (typeof width === 'string' ? width : `${width}px`)};
`;

export const ButtonInfoContainer = styled.div`
  margin-left: 5.33px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WrapperInput = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: stretch;
`;

export const ContainerIcon = styled.div<ContainerIconProps>`
  ${({ padding }) => css`
    right: ${padding}px;
    top: ${padding}px;
  `}
  position: absolute;
  justify-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
  button {
    border-radius: 0 4px 4px 0;
    height: 100%;
    &:focus {
      border-radius: 0 4px 4px 0;
    }
  }
  & > img {
    transition: ${BaSeTheme.transitions.allPropertiesContinuousFast};
  }
  ${({ rotation }) =>
    rotation &&
    css`
      & > img {
        transform: rotateX(180deg);
      }
    `}
`;

export const StyledSubLabel = styled.label.attrs({
  className: 'BaSe--input-sub-label',
})<StyledLabelProps>`
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};
  font-family: ${BaSeTheme.typography.fontFamily};
  font-weight: normal;
  color: ${BaSeTheme.colors.institucionais.cinzaSebrae60};
  font-style: italic;
  margin-left: 4px;
`;

export const MoreInfoContainer = styled.div`
  padding-top: 4px;
`;

export const WrapperHelper = styled.div`
  padding-top: 2px;
`;
