import * as React from 'react';
import { GridProps, GridWrapper } from './grid-styled';

export const BaSeGrid: React.FC<GridProps> = ({
  gap,
  delimitedOnBreakpoint,
  children,
}) => (
  <GridWrapper
    className="BaSe--grid"
    gap={gap}
    delimitedOnBreakpoint={delimitedOnBreakpoint}
  >
    {children}
  </GridWrapper>
);
