function _forceString(text: string = '', trimmed: boolean = true): string {
  if (typeof text !== 'string') {
    text = '';
  }
  return trimmed ? text?.trim() : text;
}

/**
 * Limpa a(s) barra(s) no final da URL informada
 *
 * @param url
 */
export function urlSanitizer(url: string): string {
  return _forceString(url).replace(/\/$/, '');
}

/**
 * Atalho para `encodeURIComponent()`
 * @param url Algo que precisa de ser codificado
 */
export function urlEncode(url: string): string {
  return encodeURIComponent(url);
}

/**
 * Atalho para `decodeURIComponent()`
 * @param url Algo que precisa de ser decodificado
 */
export function urlDecode(url: string): string {
  return decodeURIComponent(url);
}

/**
 * Atalho para codificar em base64 usando UTF-8
 * @param str Texto a ser codificado
 */
export function base64Encode(str: string): string {
  return btoa(
    urlEncode(str).replace(/%([0-9A-F]{2})/g, (_: string, char: string) =>
      String.fromCharCode(parseInt('0x' + char, 16)),
    ),
  );
}

/**
 * Atalho para decodificar um texto em UTF-8 na codificação base64
 * @param base64 Texto a ser decodificado
 */
export function base64Decode(base64: string): string {
  return urlDecode(
    atob(base64)
      .split('')
      .map((char) => '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );
}

/**
 * Atalho para decodificar um texto em UTF-8 na codificação base64
 * Retornando um TypedArray: Uint8Array
 * @param base64 Texto a ser decodificado
 */
export function base64DecodeToTypedArray(base64: string): Uint8Array {
  const binaryString = base64Decode(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

/**
 * Atalho para decodificar um texto em UTF-8 na codificação base64
 * Retornando um Blob
 * @param base64 Texto a ser decodificado
 * @param contentType Mime type do Blob
 */
export function base64DecodeToBlob(base64: string, contentType: string): Blob {
  contentType = contentType || 'application/octet-stream';
  return new Blob([base64DecodeToTypedArray(base64)], { type: contentType });
}

/**
 * Atalho para para converter uma string em objeto Blob
 * Retornando um Blob
 * @param str Texto a ser decodificado
 * @param contentType Mime type do Blob
 */
export function stringToBlob(str: string, contentType: string): Blob {
  contentType = contentType || 'application/octet-stream';
  return new Blob([stringToTypedArray(str)], { type: contentType });
}

/**
 * Atalho para decodificar um texto em TypedArray
 * Retornando um TypedArray: Uint8Array
 * @param str Texto a ser decodificado
 */
export function stringToTypedArray(str: string): Uint8Array {
  const binaryLen = str.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = str.charCodeAt(i);
  }
  return bytes;
}

/**
 * Verifica se o valor passado contém um número válido
 * Retornando um boolean
 * @param num Valor a ser validado como númerico
 */
export function isNumeric(num: string): boolean {
  return !isNaN(num as any);
}

/*
 * Remove todas as tags de um HTML informado
 * Retornando o texto sem as tags
 * @param html
 */
export function stripHtmlTags(html: string = ''): string {
  return _forceString(html, false).replace(/<[^>]*>/g, '');
}

/**
 * Normaliza o texto informado para minúsculo e sem acentuações
 * @param text
 */
export function normalize(text: string = ''): string {
  text = _forceString(text);
  if (!text) {
    return '';
  }
  let i: string;
  let normalized = String(text).toLowerCase().trim();
  const map = {
    a: '[àáâãäå]',
    e: '[èéêë]',
    i: '[ìíîï]',
    o: '[òóôõö]',
    u: '[ùúûűü]',
    y: '[ýÿ]',
    c: 'ç',
    n: 'ñ',
    '-': '–',
    ' ': '\\s+',
  };
  for (i in map) {
    if (map.hasOwnProperty(i)) {
      normalized = normalized.replace(new RegExp(map[i], 'img'), i);
    }
  }
  normalized = normalized.replace(/\s+/g, ' ');
  return normalized;
}

/**
 * Aplica a abreviação retornando a sigla do nome de acordo com o 1º e o ultimo nome
 * @param name Nome a ser abreviado
 */
export function nameAbbreviation(name: string = ''): string {
  name = _forceString(name);
  const filterRegex = /^D[AEO]$/g;
  const nameSplitted = (name?.toUpperCase()?.split(' ') ?? [])
    .map((term) => term.trim())
    .filter((term) => !filterRegex.test(term));
  const firstIndex = 0;
  const lastIndex = nameSplitted.length - 1;
  const first = nameSplitted?.[firstIndex]?.charAt?.(0) ?? '';
  let last = '';
  if (firstIndex < lastIndex) {
    last = nameSplitted?.[lastIndex]?.charAt?.(0) ?? '';
  }
  return `${first}${last}`;
}

/**
 * Retorna a primeira letra maiuscula do texto informado
 * @param text
 */
export function firstLetterUpperCase(text: string = ''): string {
  text = _forceString(text);
  const firstLetter = text?.charAt(0)?.toUpperCase();
  const othersLetters = text?.substr(1);
  return firstLetter + othersLetters;
}
