import { BaSeTheme } from '../../theme';

export const mapBaseTextAreaFontSize = {
  medium: {
    size: 16,
    lineHeight: 23,
  },
  small: {
    size: 13,
    lineHeight: 16,
  },
  default: {
    size: 16,
    lineHeight: 23,
  },
};

export const mapBaseTextAreaColor = {
  default: {
    borderColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
    color: BaSeTheme.colors.institucionais.cinzaSebrae30,
    boxShadow: 'none',
    backgroundColor: BaSeTheme.colors.defaultColors.white,
  },
  error: {
    borderColor: BaSeTheme.colors.feedbackInterface.erro35,
    color: BaSeTheme.colors.feedbackInterface.erro35,
    boxShadow: 'none',
    backgroundColor: BaSeTheme.colors.defaultColors.white,
  },
  focus: {
    borderColor: BaSeTheme.colors.institucionais.azulSebrae36,
    color: BaSeTheme.colors.institucionais.azulSebrae36,
    boxShadow: BaSeTheme.colors.institucionais.azulSebrae75,
    backgroundColor: BaSeTheme.colors.defaultColors.white,
  },
  disabled: {
    color: BaSeTheme.colors.institucionais.cinzaSebrae75,
    borderColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
    backgroundColor: BaSeTheme.colors.institucionais.cinzaSebrae97,
    boxShadow: 'none',
  },
};
