import styled from 'styled-components';

export interface PopupButtonContainerProps {
  size?: (number | string);
}

const handleProps = (prop: string | number | undefined) => {
  if (!prop) {
    return undefined;
  }
  if (typeof (prop) === 'string') {
    return prop;
  }
  return prop + 'px';
};

export const HelperButtonContainer = styled.div.attrs({ className: 'BaSe--helper-button-container' })`
  position: relative;
`;

export const HelperButtonIconContainer = styled.div<PopupButtonContainerProps>`
  cursor: pointer;
  button {
    padding: 4px
  };

  img {
    width: ${props => handleProps(props.size) ?? '13px'};
    height: ${props => handleProps(props.size) ?? '13px'};
  }
`;
