interface ExtractWidthHeightReturnType {
  width: string;
  height: string;
}

type ExtractWidthHeightType = number | string | undefined;

export function extractWidthHeight(
  size?: ExtractWidthHeightType,
  width?: ExtractWidthHeightType,
  height?: ExtractWidthHeightType,
): ExtractWidthHeightReturnType {

  width = toString(size ?? width);
  height = toString(size ?? height);

  return {
    width,
    height,
  };
}

function toString(n: ExtractWidthHeightType): string {
  if (typeof n === 'number') {
    return `${n}px`;
  }

  return `${n}`;
}
