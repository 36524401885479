import { AlertTriangleTooltipMapperInterface } from '../alerts-props';
import { BaSeTheme } from '../../../public-api/others';

const none = 'none';

const borderSize = 7;
const filledBorder = `${borderSize}px solid ${BaSeTheme.colors.institucionais.azulSebrae20}`;
const transparenteBorder = `${borderSize}px solid ${BaSeTheme.colors.defaultColors.transparent}`;
const basePosition = `${-1 * borderSize}px`;
const calcPosition = `calc(50% - ${borderSize}px)`;

const borderSizeSmaller = 6.5;
const filledBorderSmaller = `${borderSizeSmaller}px solid ${BaSeTheme.colors.institucionais.azulSebrae20}`;
const transparenteBorderSmaller = `${borderSizeSmaller}px solid ${BaSeTheme.colors.defaultColors.transparent}`;
const basePositionSmaller = `${(-1 * borderSizeSmaller) + 1}px`;
const calcPositionSmaller = `calc(50% - ${(borderSizeSmaller)}px)`;

const filledBorderOutlined = `${borderSize}px solid ${BaSeTheme.colors.institucionais.azulSebrae36}`;
const filledBorderSmallerOutlined = `${borderSizeSmaller}px solid ${BaSeTheme.colors.defaultColors.white}`;

export const TriangleMapper: AlertTriangleTooltipMapperInterface = {
  filled: {
    neutral: {
      widthTop: none,
      widthRight: none,
      widthBottom: none,
      widthLeft: none,
    },
    top: {
      positionBottom: basePosition,
      positionRight: calcPosition,
      widthTop: filledBorder,
      widthRight: transparenteBorder,
      widthBottom: none,
      widthLeft: transparenteBorder,
    },
    right: {
      positionLeft: basePosition,
      positionBottom: calcPosition,
      widthTop: transparenteBorder,
      widthRight: filledBorder,
      widthBottom: transparenteBorder,
      widthLeft: none,
    },
    bottom: {
      positionTop: basePosition,
      positionRight: calcPosition,
      widthTop: none,
      widthRight: transparenteBorder,
      widthBottom: filledBorder,
      widthLeft: transparenteBorder,
    },
    left: {
      positionRight: basePosition,
      positionBottom: calcPosition,
      widthTop: transparenteBorder,
      widthRight: none,
      widthBottom: transparenteBorder,
      widthLeft: filledBorder,
    },
  },
  outlined: {
    neutral: {
      widthTop: none,
      widthRight: none,
      widthBottom: none,
      widthLeft: none,
    },
    top: {
      positionBottom: basePosition,
      positionRight: calcPosition,
      widthTop: filledBorderOutlined,
      widthRight: transparenteBorder,
      widthBottom: none,
      widthLeft: transparenteBorder,
    },
    right: {
      positionLeft: basePosition,
      positionBottom: calcPosition,
      widthTop: transparenteBorder,
      widthRight: filledBorderOutlined,
      widthBottom: transparenteBorder,
      widthLeft: none,
    },
    bottom: {
      positionTop: basePosition,
      positionRight: calcPosition,
      widthTop: none,
      widthRight: transparenteBorder,
      widthBottom: filledBorderOutlined,
      widthLeft: transparenteBorder,
    },
    left: {
      positionRight: basePosition,
      positionBottom: calcPosition,
      widthTop: transparenteBorder,
      widthRight: none,
      widthBottom: transparenteBorder,
      widthLeft: filledBorderOutlined,
    },
  },
};

export const TriangleMapperSmaller: AlertTriangleTooltipMapperInterface = {
  filled: {
    neutral: {
      widthTop: none,
      widthRight: none,
      widthBottom: none,
      widthLeft: none,
    },
    top: {
      positionBottom: basePositionSmaller,
      positionRight: calcPositionSmaller,
      widthTop: filledBorderSmaller,
      widthRight: transparenteBorderSmaller,
      widthBottom: none,
      widthLeft: transparenteBorderSmaller,
    },
    right: {
      positionLeft: basePositionSmaller,
      positionBottom: calcPositionSmaller,
      widthTop: transparenteBorderSmaller,
      widthRight: filledBorderSmaller,
      widthBottom: transparenteBorderSmaller,
      widthLeft: none,
    },
    bottom: {
      positionTop: basePositionSmaller,
      positionRight: calcPositionSmaller,
      widthTop: none,
      widthRight: transparenteBorderSmaller,
      widthBottom: filledBorderSmaller,
      widthLeft: transparenteBorderSmaller,
    },
    left: {
      positionRight: basePositionSmaller,
      positionBottom: calcPositionSmaller,
      widthTop: transparenteBorderSmaller,
      widthRight: none,
      widthBottom: transparenteBorderSmaller,
      widthLeft: filledBorderSmaller,
    },
  },
  outlined: {
    neutral: {
      widthTop: none,
      widthRight: none,
      widthBottom: none,
      widthLeft: none,
    },
    top: {
      positionBottom: basePositionSmaller,
      positionRight: calcPositionSmaller,
      widthTop: filledBorderSmallerOutlined,
      widthRight: transparenteBorderSmaller,
      widthBottom: none,
      widthLeft: transparenteBorderSmaller,
    },
    right: {
      positionLeft: basePositionSmaller,
      positionBottom: calcPositionSmaller,
      widthTop: transparenteBorderSmaller,
      widthRight: filledBorderSmallerOutlined,
      widthBottom: transparenteBorderSmaller,
      widthLeft: none,
    },
    bottom: {
      positionTop: basePositionSmaller,
      positionRight: calcPositionSmaller,
      widthTop: none,
      widthRight: transparenteBorderSmaller,
      widthBottom: filledBorderSmallerOutlined,
      widthLeft: transparenteBorderSmaller,
    },
    left: {
      positionRight: basePositionSmaller,
      positionBottom: calcPositionSmaller,
      widthTop: transparenteBorderSmaller,
      widthRight: none,
      widthBottom: transparenteBorderSmaller,
      widthLeft: filledBorderSmallerOutlined,
    },
  },
};
