import * as React from 'react';
import { BaSeTheme } from '../../theme';
import {
  HelperButtonInterface,
  BaSePopupButton,
} from '../helpers/popup-button/popup-button';
import { BaSeHelperText } from '../helpers/helper-text/helper-text';
import {
  StyledTextArea,
  StyledOuterTextAreaBox,
  StyledTextAreaContainer,
  StyledTextAreaLabel,
  StyledTextAreaErrorText,
  StyledTextAreaFooter,
  StyledTextAreaFooterLimit,
  StyledTextAreaFooterNumber,
  StyledTextAreaFooterNumbers,
  StyledTextAreaSubLabel,
  StyledTextAreaLabelContainer,
  ButtonInfoContainer,
  MoreInfoContainer,
  WrapperHelper,
} from './text-area-styled';
import { getNextHash, idGenerator } from '../../utils/id-generator';

const idSequence = idGenerator();

interface TextAreaType {
  isDisabled: boolean;
  placeholder: string;
  label: string;
  validateOnChange: any;
  textSize: 'medium' | 'small';
  value: string;
  onChange: any;
  textError: string;
  name: string;
  rows: number;
  maxValue: number;
  subLabel: string;
  showHelpButton?: boolean;
  helpButtonProps?: HelperButtonInterface;
  complement?: string;
  moreInfoLabel?: string;
  moreInfoDetails?: string;
}

export const BaSeTextArea: React.FC<TextAreaType> = ({
  isDisabled = false,
  placeholder = '',
  label = '',
  validateOnChange = () => { },
  textSize = 'medium',
  value = '',
  onChange = () => { },
  textError = 'Informar erro de preenchimentos',
  name = '',
  rows = 0,
  maxValue = 0,
  subLabel = '',
  helpButtonProps = {},
  showHelpButton = false,
  complement = '',
  moreInfoLabel = '',
  moreInfoDetails = '',
}) => {
  const id = getNextHash(idSequence);
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const [hasFocus, setHasFocus] = React.useState(false);

  const updateTextAreaValue = (event: any) => {
    onChange(event.target.value);
  };

  const giveFocus = () => {
    textAreaRef.current?.focus();
  };

  return (
    <StyledTextAreaContainer>
      <StyledTextAreaLabelContainer>
        <StyledTextAreaLabel size={textSize} htmlFor={id}>{label}</StyledTextAreaLabel>
        <StyledTextAreaSubLabel size={textSize} htmlFor={id}>
          {subLabel}
        </StyledTextAreaSubLabel>
        {showHelpButton && (
          <ButtonInfoContainer>
            <BaSePopupButton {...helpButtonProps} />
          </ButtonInfoContainer>
        )}
      </StyledTextAreaLabelContainer>
      <StyledOuterTextAreaBox
        disabled={isDisabled}
        hasFocus={hasFocus}
        onClick={() => giveFocus()}
        hasError={validateOnChange()}
      >
        <StyledTextArea
          id={id}
          disabled={isDisabled}
          hasError={validateOnChange()}
          rows={rows}
          name={name}
          ref={textAreaRef}
          value={value}
          onChange={updateTextAreaValue}
          placeholder={placeholder}
          size={textSize}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
        />
      </StyledOuterTextAreaBox>
      <StyledTextAreaFooter>
        {validateOnChange() ? (
          <StyledTextAreaErrorText>{textError}</StyledTextAreaErrorText>
        ) : (
          !!complement && (
            <WrapperHelper>
              <BaSeHelperText
                color={BaSeTheme.colors.institucionais.cinzaSebrae45}
                isItalic={true}
                size="medium"
                label={complement}
              />
            </WrapperHelper>
          )
        )}
        {!!maxValue && (
          <StyledTextAreaFooterLimit>
            Máximo de {maxValue} caracteres com espaço
            <StyledTextAreaFooterNumbers>
              {value.length}
              <StyledTextAreaFooterNumber limit={value.length > maxValue}>
                /{maxValue}
              </StyledTextAreaFooterNumber>
            </StyledTextAreaFooterNumbers>
          </StyledTextAreaFooterLimit>
        )}
      </StyledTextAreaFooter>
      {!!moreInfoLabel && (
        <MoreInfoContainer>
          <BaSeHelperText
            size="small"
            label={moreInfoLabel}
            details={moreInfoDetails}
          />
        </MoreInfoContainer>
      )}
    </StyledTextAreaContainer>
  );
};
