import * as React from 'react';
import { AlertProps } from '../alerts-props';
import { BaSeSmall1 } from '../../typography/small/small1';
import { BaSeShapeButton } from '../../button/shape-button/shape-button';
import { TriangleMapper, TriangleMapperSmaller } from './popover-map';
import {
  PopoverContainer,
  PopoverCard,
  PopoverCardTitleContainer,
  PopoverCardBodyContainer,
  PopoverButtonContainer,
  PopoverTriangle,
  PopoverTriangleSmaller,
} from './popover-styled';

export const BaSePopover: React.FC<Omit<AlertProps, 'color'>> = ({
  direction = 'neutral',
  message = 'lorem ipsum',
  title = 'title',
  open = false,
  width = 180,
  handleClose = () => { },
  coordinates = {},
  refOutput = () => { },
}) => {
  const TriangleProps = TriangleMapper[direction] ?? TriangleMapper.neutral;
  const TrianglePropsSmaller =
    TriangleMapperSmaller[direction] ?? TriangleMapperSmaller.neutral;

  const { top, left, bottom, right } = coordinates;

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref?.current) {
      refOutput(ref?.current);
    }
  }, [ref, message]);

  return (
    <PopoverContainer
      aria-hidden={!open}
      open={open}
      ref={ref}
      top={top}
      left={left}
      bottom={bottom}
      right={right}
      width={width}
    >
      <PopoverTriangle {...TriangleProps} />
      <PopoverTriangleSmaller {...TrianglePropsSmaller} />
      <PopoverCard>
        <PopoverCardTitleContainer>
          <BaSeSmall1>{title}</BaSeSmall1>
          <PopoverButtonContainer>
            <BaSeShapeButton
              buttonType="button"
              onClick={handleClose}
              nameIcon="multiply"
              shape="circle"
              size="small"
              sizeIcon="medium"
              color="default"
              type="tertiary"
            />
          </PopoverButtonContainer>
        </PopoverCardTitleContainer>
        <PopoverCardBodyContainer>
          <BaSeSmall1>{message}</BaSeSmall1>
        </PopoverCardBodyContainer>
      </PopoverCard>
    </PopoverContainer>
  );
};
