import * as React from 'react';

import {
  arrowButtonMapStyle,
  mapCustomColorsArrowButtonValues,
} from '../map-button-style';
import { BaSeTextButton } from '../text-button/text-button';
import { ArrowButton, ArrowStyledContainer } from './arrow-button-styled';
import { ButtonProps } from '../button-props';
import { BaSeTheme } from '../../../theme';

export const BaSeArrowButton: React.FC<ButtonProps> = ({
  type = 'primary',
  value = 'Valor',
  color = 'default',
  isDisabled = false,
  direction = 'right',
  onClick = () => { },
  buttonType = 'button',
  autoFocus = false,
}) => {
  const colorIsMapped =
    color === BaSeTheme.components.button.colors.default ||
    color === BaSeTheme.components.button.colors.negative;

  const getColorsAtributes = () => {
    if (color) {
      if (!colorIsMapped) {
        return (
          mapCustomColorsArrowButtonValues(color)?.[type] ??
          mapCustomColorsArrowButtonValues(color).primary
        );
      } else {
        return (
          arrowButtonMapStyle[type]?.[color] ??
          arrowButtonMapStyle.primary?.[color]
        );
      }
    } else {
      return (
        arrowButtonMapStyle[type]?.default ??
        arrowButtonMapStyle.primary.default
      );
    }
  };

  const typeMapped = isDisabled
    ? color === 'negative'
      ? arrowButtonMapStyle[type]?.disabled?.negative ?? arrowButtonMapStyle[type]?.disabled
      : arrowButtonMapStyle[type]?.disabled ??
      arrowButtonMapStyle.primary.default
    : getColorsAtributes();

  const { color: iconColor, arrows, ...typeStyle } = typeMapped;
  const isLeft = direction === 'left';

  return (
    <ArrowButton
      aria-disabled={isDisabled ? true : undefined}
      className="BaSe--button-arrow"
      autoFocus={autoFocus}
      disabled={isDisabled}
      isDisabled={isDisabled}
      onClick={onClick}
      type={buttonType}
      isLeft={isLeft}
      isSecondaryNegative={color === 'negative' && type === 'secondary'}
      hasBoxShadow={color !== 'negative' || !isDisabled}
      {...typeStyle}
    >
      <ArrowStyledContainer
        isSecondaryNegative={color === 'negative' && type === 'secondary'}
        boxShadow={color !== 'negative' || !isDisabled}
        isLeft={isLeft}
        {...arrows}
      />
      <BaSeTextButton
        fontSize={1}
        lineHeight={1.4}
        hasLeftIcon={false}
        hasRightIcon={false}
        fontWeigt="normal"
        color={iconColor}
      >
        {value}
      </BaSeTextButton>
    </ArrowButton>
  );
};
