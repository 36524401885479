import styled from 'styled-components';
import { BaSeTheme } from '../../../public-api/others';
import {
  AlertStyledProps,
  AlertTriangleProps,
  AlertTooltipColorProps,
} from '../alerts-props';

export const TooltipContainer = styled.div.attrs({
  className: 'BaSe--Tooltip',
})<AlertStyledProps>`
  ${(props) => (props.open ? 'visibility: visible;' : 'visibility: hidden;')}
  ${(props) => !props.open && 'pointer-events: none;'}
  position: absolute;
  opacity: ${(props) => (props.open ? 1 : 0)};
  ${(props) => props.top && 'top:' + props.top + ';'}
  ${(props) => props.right && 'right:' + props.right + ';'}
  ${(props) => props.bottom && 'bottom:' + props.bottom + ';'}
  ${(props) => props.left && 'left:' + props.left + ';'}
  ${(props) => `min-width: ${props.width}px;`}
  z-index: ${BaSeTheme.zIndexs.tens};
  transition: ${BaSeTheme.transitions.allPropertiesContinuousNormal};
  will-change: opacity;
`;

export const TooltipCard = styled.div.attrs({
  className: 'BaSe--Tooltip-card',
})<AlertTooltipColorProps>`
  background-color: ${(props) =>
    props.color === 'outlined'
      ? BaSeTheme.colors.defaultColors.white
      : BaSeTheme.colors.institucionais.azulSebrae20};
  ${(props) =>
    props.color === 'outlined' &&
    `border: 1px solid ${BaSeTheme.colors.institucionais.azulSebrae36};`}
  border-radius: 4px;
  padding: 8px 12px;
  position: relative;
  > small {
    color: ${(props) =>
      props.color === 'outlined'
        ? BaSeTheme.colors.institucionais.azulSebrae36
        : BaSeTheme.colors.defaultColors.white};
  }
`;

export const TooltipTriangle = styled.div<AlertTriangleProps>`
  width: 0;
  height: 0;
  position: absolute;
  ${(props) => props.positionTop && `top: ${props.positionTop};`}
  ${(props) => props.positionRight && `right: ${props.positionRight};`}
  ${(props) => props.positionLeft && `left: ${props.positionLeft};`}
  ${(props) => props.positionBottom && `bottom: ${props.positionBottom};`}
  border-top: ${(props) => props.widthTop};
  border-right: ${(props) => props.widthRight};
  border-left: ${(props) => props.widthLeft};
  border-bottom: ${(props) => props.widthBottom};
  z-index: ${BaSeTheme.zIndexs.tens + 1};
`;

export const TooltipTriangleSmaller = styled.div<AlertTriangleProps>`
  width: 0;
  height: 0;
  position: absolute;
  ${(props) => props.positionTop && `top: ${props.positionTop};`}
  ${(props) => props.positionRight && `right: ${props.positionRight};`}
  ${(props) => props.positionLeft && `left: ${props.positionLeft};`}
  ${(props) => props.positionBottom && `bottom: ${props.positionBottom};`}
  border-top: ${(props) => props.widthTop};
  border-right: ${(props) => props.widthRight};
  border-left: ${(props) => props.widthLeft};
  border-bottom: ${(props) => props.widthBottom};
  z-index: ${BaSeTheme.zIndexs.tens + 2};
`;
