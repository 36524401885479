import styled, { css } from 'styled-components';
import { BaSeTheme } from '../../../public-api/others';

// const paddingIconValue = -19;

interface ArrowButtonTypeProps {
  background: string;
  borderColor: string;
  borderRadius: string;
  boxShadowFocus: string;
  hoverColor: string;
  border: string;
  isLeft: boolean;
  hasBoxShadow: boolean;
  textHoverColor?: string;
  borderHoverColor?: string;
  isSecondaryNegative: boolean;
  isDisabled?: boolean;
}

interface ArrowStyledProps {
  isLeft: boolean;
  top?: string;
  transform?: string;
  isSecondaryNegative?: boolean;
  clip?: string;
  svgWidth?: string;
  boxShadow?: boolean;
  isDisabled?: boolean;
}

interface GhostContainerProps {
  width: string | number;
  isLeft: boolean;
}

export const ArrowButton = styled.button<ArrowButtonTypeProps>`
  ${({
  borderRadius,
  background,
  border,
  borderColor,
  isLeft,
  hasBoxShadow,
  hoverColor,
  isSecondaryNegative,
  boxShadowFocus,
  textHoverColor,
  borderHoverColor,
  isDisabled,
}) => css`
  display : flex;
  height: 40px;
  padding: 7px;
  box-sizing: border-box;
  border-radius : ${borderRadius}px;
  background-color : ${background};
  z-index: ${BaSeTheme.zIndexs.units + 2};
  border-top: ${border} solid ${borderColor};
  border-right: ${isLeft ? `${border} solid ${borderColor}` : '0px'};
  border-left: ${!isLeft ? `${border} solid ${borderColor}` : '0px'};
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  border-bottom : ${border} solid ${borderColor};
  ${isLeft
    ? `
    border-top-left-radius:${isSecondaryNegative ? 0 : 1}px;
    border-bottom-left-radius:${isSecondaryNegative ? 0 : 1}px;
    margin-left: 20px;
    padding-left: 36px;
  `
    : `
    border-top-right-radius:${isSecondaryNegative ? 0 : 1}px;
    border-bottom-right-radius:${isSecondaryNegative ? 0 : 1}px;
    margin-right: 20px;
    padding-right: 36px;
  `
  };
  ${hasBoxShadow &&
  `box-shadow: 0px 2px 4px ${BaSeTheme.colors.defaultColors.shadowColor};`
  }

  span {
    padding-${isLeft ? 'right' : 'left'} 5px;
    z-index: ${BaSeTheme.zIndexs.tens};
  }

  .arrow-shape {
    border: ${border} solid ${borderColor};
    ${(!isSecondaryNegative || isDisabled) && `background-color: ${background};`}
  }

  .arrow-body {
    background-color: ${background};
  }

  &:hover {
    ${`background-color: ${hoverColor};`}
    ${borderHoverColor && `border-color: ${borderHoverColor};`}
    .arrow-shape {
      ${`background-color: ${hoverColor};`}
      ${borderHoverColor && `border-color: ${borderHoverColor};`}
    }
    .arrow-body {
      background-color: ${hoverColor};
    }

    span {
      ${textHoverColor && `color: ${textHoverColor};`}
    }
  }

  ${isSecondaryNegative
    ? `
    &:focus {
      outline: none;
      border: 2px solid ${BaSeTheme.colors.defaultColors.transparent};
      border-${isLeft ? 'left' : 'right'}:none;
      .arrow-shape {
        border: 2px solid ${BaSeTheme.colors.defaultColors.transparent};
      }
      .arrow-shape {
        box-shadow: 0px 0px 0px 3px ${boxShadowFocus};
      }
      .arrow-body {
        box-shadow: 0px 0px 0px 3px ${boxShadowFocus};
      }

      ${isLeft
      ? `box-shadow: 3px 3px 0px 0px ${boxShadowFocus} , 3px -3px 0px 0px ${boxShadowFocus}, 0px 3px 0px 0px ${boxShadowFocus} , 0px -3px 0px 0px ${boxShadowFocus}`
      : `box-shadow: -3px 3px 0px 0px ${boxShadowFocus} , -3px -3px 0px 0px ${boxShadowFocus}, 0px 3px 0px 0px ${boxShadowFocus} , 0px -3px 0px 0px ${boxShadowFocus}`
    }
  `
    : `
    &:focus {
      outline: none;
      ${isLeft
      ? `box-shadow: 1px 3px 0px 0px ${boxShadowFocus} , 1px -3px 0px 0px ${boxShadowFocus} , 0px 0px 0px 3px ${boxShadowFocus}`
      : `box-shadow: -1px 3px 0px 0px ${boxShadowFocus} , -1px -3px 0px 0px ${boxShadowFocus} , 0px 0px 0px 3px ${boxShadowFocus}`
    };

      .arrow-shape {
        box-shadow: 0px 0px 0px 3px ${boxShadowFocus};
      }
    }
  `
  }

  `}
`;

export const ArrowStyledContainer = styled.div.attrs({
  className: 'arrow-shape',
}) <ArrowStyledProps>`
  ${({ isLeft, clip, isSecondaryNegative, boxShadow }) => css`
    position: absolute;
    box-sizing: border-box;
    ${isSecondaryNegative
      ? isLeft
        ? `right: calc(100% - calc(30.5px / 2));`
        : `left: calc(100% - calc(30.5px / 2));`
      : isLeft
        ? `right: calc(100% - 16px);`
        : `left: calc(100% - 16px);`}
    width: 30.5px;
    height: 30.5px;
    border-radius: 4px;
    clip-path: ${clip ?? `polygon(300% -300%, -300% -300%, 300% 300%)`};
    transform: rotate(${isLeft ? `225deg` : `45deg`});
    ${boxShadow && (
      isLeft ?
        `box-shadow: 0px -4px 4px -2px ${BaSeTheme.colors.defaultColors.shadowColor}`
        :
        `box-shadow: 4px 0px 4px -2px ${BaSeTheme.colors.defaultColors.shadowColor}`
    )};
  `}
`;

export const ClippedBack = styled.div.attrs({
  className: 'arrow-body',
}) <ArrowStyledProps>`
  ${({ isLeft, svgWidth = '141.55' }) => css`
    position: absolute;
    ${isLeft
      ? `left: -19.5px; right: -0.5px;`
      : `right: -19.5px; left: -0.5px;`}
    top:-0.5px;
    bottom: -0.5px;
    border-radius: 5px;
    ${isLeft
      ? `clip-path: polygon(22% 0, 100% 0, 100% 100%, 22% 100%, 2% 50%);`
      : `clip-path: path('M4,40 a4,4,0,0,1-4-4V4 A4,4,0,0,1,4,0 H${svgWidth} a4,4,0,0,1,3.15,1.54l12.46,16 a4,4,0,0,1,0,4.92 l-12.46,16 A4,4,0,0,1,${svgWidth},40 Z');`}
  `}
`;

export const ButtonGhostContainer = styled.button<GhostContainerProps>`
  ${({ width, isLeft }) => css`
    border: none;
    background-color: ${BaSeTheme.colors.defaultColors.transparent};
    width: ${width}px;
    cursor: pointer;
    &:focus {
      outline: none;
      ${isLeft
      ? `box-shadow: 3px 3px 0px 0px ${BaSeTheme.colors.institucionais.azulSebrae60} , 3px -3px 0px 0px ${BaSeTheme.colors.institucionais.azulSebrae60} , 0px 0px 0px 3px ${BaSeTheme.colors.institucionais.azulSebrae60}`
      : `box-shadow: -3px 3px 0px 0px ${BaSeTheme.colors.institucionais.azulSebrae60} , -3px -3px 0px 0px ${BaSeTheme.colors.institucionais.azulSebrae60} , 0px 0px 0px 3px ${BaSeTheme.colors.institucionais.azulSebrae60}`
    };
  `}
`;
