import { ThemeInterface } from './theme-interface';
import { BaSeColors } from './colors';
import { BaSeShadows } from './shadows';
import { BaSeTransitions } from './transitions';
import { BaSeTypography } from './typography';
import { BaSeBreakpoints } from './breakpoints';
import { BaSeComponents } from './components';
import { BaSeZindexs } from './zIndexs';

export const BaSeTheme: ThemeInterface = {
  colors: BaSeColors,
  shadows: BaSeShadows,
  typography: BaSeTypography,
  transitions: BaSeTransitions,
  breakpoints: BaSeBreakpoints,
  components: BaSeComponents,
  zIndexs: BaSeZindexs,
};
