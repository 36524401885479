export const mapCounterSize = {
  small: {
    padding: 30,
  },
  big: {
    padding: 46,
  },
  medium: {
    padding: 38,
  },
};

export const mapCounterTypes = {
  primary: {
    default: {
      type: 'primary',
      color: 'default',
    },
    error: {
      type: 'counter',
      color: 'fillerror',
    },
    disabled: {
      type: 'secondary',
      color: 'default',
    },
  },
  secondary: {
    default: {
      type: 'counter',
      color: 'default',
    },
    error: {
      type: 'counter',
      color: 'blankerror',
    },
    disabled: {
      type: 'secondary',
      color: 'default',
    },
  },
};
