import * as React from 'react';
import { StyledAvatar, StyledSpan } from './avatar-styled';
import { getAvatar } from './avatar-controller';
import { BaSeAvatarProps } from './avatar-props';
import { nameAbbreviation } from '../../../utils/string-utils';
import { BaSeTheme } from '../../../theme';
import { BaSeConfig } from '../../../config/config';

export const BaSeAvatar: React.FC<BaSeAvatarProps> = ({
  imageSrc,
  keycloak,
  userId,
  userName,
  avatarUrl,
  iconColor = BaSeTheme.colors.institucionais.azulSebrae36,
  iconSize = 16,
  isBoldIcon = false,
  onErrorLoading = () => {},
  onLoadSuccess = () => {},
}) => {
  const [hasAvatar, setHasAvatar] = React.useState(true);

  if (keycloak) {
    avatarUrl =
      BaSeConfig.ameiConfig?.[keycloak.authServerUrl as string]?.avatarUrl;
    userId = keycloak.tokenParsed?.sub;
    userName = keycloak.tokenParsed?.['name'] ?? '';
  }

  if (!userName) {
    throw Error('Nome é obrigatório');
  }

  if (hasAvatar) {
    return (
      <StyledAvatar
        loading="lazy"
        alt={`Imagem da pessoa com o nome ${userName}`}
        key={userId}
        className="BaSe--avatar"
        src={
          imageSrc
            ? imageSrc
            : `${getAvatar(avatarUrl as string, keycloak, userId)}`
        }
        onLoad={() => onLoadSuccess()}
        onError={() => {
          setHasAvatar(false);
          onErrorLoading();
        }}
      />
    );
  }

  return (
    <StyledSpan
      isBoldIcon={isBoldIcon}
      aria-hidden={true}
      iconColor={iconColor}
      iconSize={iconSize}
    >
      {nameAbbreviation(userName)}
    </StyledSpan>
  );
};
