import { BaSeTheme } from '../../theme';

export const mapTogglerType = {
  outlined: {
    default: {
      border: `2px solid ${BaSeTheme.colors.institucionais.azulSebrae60}`,
      background: BaSeTheme.colors.defaultColors.white,
    },
    disabled: {
      border: `2px solid ${BaSeTheme.colors.institucionais.azulSebrae75}`,
      background: BaSeTheme.colors.defaultColors.white,
    },
    checked: {
      border: `2px solid ${BaSeTheme.colors.institucionais.azulSebrae36}`,
      background: BaSeTheme.colors.defaultColors.white,
    },
  },
  contained: {
    default: {
      border: 'none',
      background: BaSeTheme.colors.institucionais.azulSebrae60,
    },
    disabled: {
      border: 'none',
      background: BaSeTheme.colors.institucionais.azulSebrae75,
    },
    checked: {
      border: 'none',
      background: BaSeTheme.colors.institucionais.azulSebrae36,
    },
  },
};

export const mapTogglerTypeSpan = {
  outlined: {
    default: {
      border: 'none',
      background: BaSeTheme.colors.institucionais.azulSebrae60,
    },
    disabled: {
      border: 'none',
      background: BaSeTheme.colors.institucionais.azulSebrae75,
    },
    checked: {
      border: 'none',
      background: BaSeTheme.colors.institucionais.azulSebrae36,
    },
    position: {
      top: '1px',
      left: '2px',
    },
    size: '22px',
    transform: 'translateX(32px);',
  },
  contained: {
    default: {
      border: `2px solid ${BaSeTheme.colors.defaultColors.white}`,
      background: BaSeTheme.colors.institucionais.azulSebrae60,
    },
    disabled: {
      border: `2px solid ${BaSeTheme.colors.defaultColors.white}`,
      background: BaSeTheme.colors.institucionais.azulSebrae75,
    },
    checked: {
      border: `2px solid ${BaSeTheme.colors.defaultColors.white}`,
      background: BaSeTheme.colors.institucionais.azulSebrae36,
    },
    position: {
      top: '-2.5px',
      left: '-2px',
    },
    size: '26px',
    transform: ' translateX(35px);',
  },
};
