import styled from 'styled-components';

interface StyledSpanProps {
  iconColor: string | null;
  iconSize: number | null;
  isBoldIcon: boolean;
}

export const StyledAvatar = styled.img`
  object-fit: cover;
`;

export const StyledSpan = styled.span<StyledSpanProps>`
  color: ${(props) => props.iconColor};
  font-size: ${(props) => props.iconSize}px;
  font-weight: ${(props) => (props.isBoldIcon ? 'bold' : 'normal')};
`;
