import { Color } from './color';

const lightLevelHover = 0.65;
const lightLevelFocus = 0.7;
const opacityLevelHover = 0.14;
const opacityLevelFocus = 0.83;

interface HSLModeProps {
  h?: number;
  s?: number;
  l?: number;
  opacity?: number;
}

export const hueMapperHover = (color: Color | null) => {
  if (!color) {
    return;
  }
  let { h, s } = color.hsl();
  h /= 100;
  s /= 100;
  const { r, g, b } = color.HSVtoRGB(h, s, lightLevelHover);
  return new Color(r, g, b).toStringWithOpacity(opacityLevelHover);
};

export const hueMapperFocus = (color: Color | null) => {
  if (!color) {
    return;
  }
  let { h, s } = color.hsl();
  h /= 100;
  s /= 100;
  const { r, g, b } = color.HSVtoRGB(h, s, lightLevelFocus);
  return new Color(r, g, b).toStringWithOpacity(opacityLevelFocus);
};

export const createButtonHoverColor = (color: string) => {
  const baseColorObj = Color.generate(color);
  if (baseColorObj?.isGrayScale()) {
    return baseColorObj.toStringWithOpacity(0.14);
  }
  return hueMapperHover(baseColorObj);
};

export const createButtonFocusColor = (color: string) => {
  const baseColorObj = Color.generate(color);
  if (baseColorObj?.isGrayScale()) {
    return baseColorObj.toStringWithOpacity(0.83);
  }
  return hueMapperFocus(baseColorObj);
};

export const createButtonDarkTextColor = (color: string) => {
  const baseColorObj = Color.generate(color);
  if (!baseColorObj) {
    return;
  }
  const { h, l, s } = baseColorObj.hsl();
  let newL = l / 100;
  const reduceValue = 0.1;
  newL = Math.max(0, newL - reduceValue);
  const { r, g, b } = baseColorObj.HSVtoRGB(h / 100, s / 100, newL);
  return new Color(r, g, b).toString();
};

export const createColor = (color: string, colorConfig: HSLModeProps) => {
  if (!color || !colorConfig) {
    return '#000';
  }
  try {
    const colorClass = color.includes('#')
      ? Color.generateFromHex(color)
      : Color.generateFromCSSrgb(color);
    if (!colorClass) {
      throw new Error('Wrong color format!!');
    }
    const { h: ccH, s: ccS, l: ccL } = colorClass.hsl();
    const { h, s, l, opacity } = colorConfig;
    const { r, g, b } = colorClass.HSVtoRGB(
      h ?? ccH / 100,
      s ?? ccS / 100,
      l ?? ccL / 100,
    );
    const newColorClass = new Color(r, g, b);
    if (opacity) {
      return newColorClass.toStringWithOpacity(opacity);
    } else {
      return newColorClass.toString();
    }
  } catch (e) {
    return '#000';
  }
};
