import { BaSeTheme } from '../../../theme';

export const translationValueToName = {
  0: 'error',
  1: 'validated',
  2: 'default',
  3: 'disabled',
};

export const mapBaSeInputSize = {
  small: 28,
  medium: 36,
  big: 44,
};

export const mapBaSeInputColor = {
  default: {
    color: BaSeTheme.colors.institucionais.cinzaSebrae60,
    borderColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
    backgroundColor: BaSeTheme.colors.defaultColors.white,
  },
  validated: {
    color: BaSeTheme.colors.feedbackInterface.sucesso20,
    borderColor: BaSeTheme.colors.feedbackInterface.sucesso20,
    backgroundColor: BaSeTheme.colors.defaultColors.white,
  },
  error: {
    color: BaSeTheme.colors.feedbackInterface.erro35,
    borderColor: BaSeTheme.colors.feedbackInterface.erro35,
    backgroundColor: BaSeTheme.colors.defaultColors.white,
  },
  disabled: {
    color: BaSeTheme.colors.institucionais.cinzaSebrae75,
    borderColor: BaSeTheme.colors.institucionais.cinzaSebrae75,
    backgroundColor: BaSeTheme.colors.institucionais.cinzaSebrae97,
  },
};
