import { ThemeShadowsInterface } from './theme-interface';
import { BaSeColors } from './colors';

export const BaSeShadows: ThemeShadowsInterface = {
  profundidade2: ` 0  2px  4px 0 ${BaSeColors.defaultColors.shadowColor}`,
  profundidade4: ` 0  4px  8px 0 ${BaSeColors.defaultColors.shadowColor}`,
  profundidade8: ` 0  8px 12px 0 ${BaSeColors.defaultColors.shadowColor}`,
  profundidade16: `0 16px 32px 0 ${BaSeColors.defaultColors.shadowColor}`,
  profundidade32: `0 32px 30px 0 ${BaSeColors.defaultColors.shadowColor}`,
  profundidade64: `0 64px 40px 0 ${BaSeColors.defaultColors.shadowColor}`,
  focusPrimary: `0 0 4px ${BaSeColors.institucionais.azulSebrae75}`,
  focusSecundary: `0 0 4px ${BaSeColors.institucionais.cinzaSebrae75}`,
  focusError: `0 0 4px ${BaSeColors.feedbackInterface.erro70}`,
  focusValidated: `0 0 4px ${BaSeColors.feedbackInterface.sucesso70}`,
  focusNegative: `0 0 4px ${BaSeColors.defaultColors.white}`,
};
